import React, { useEffect, useRef, useState } from 'react'
import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'
// Material Dashboard 2 React example components
import { Button, Card, Paper } from '@mui/material'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import 'assets/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomizeDetail } from 'App/store/actions'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import reactS3 from 'react-aws-s3'
import { config } from 'utils/s3config'
import { editCustomize } from 'App/store/actions'
import { addCustomize } from 'App/store/actions'
import MDSpinner from 'components/MDSpinner'
import { toast } from 'react-toastify'
import { Box } from '@material-ui/core'
import ImgSpinner from 'components/ImgLoader'

export default function Customize() {
  window.Buffer = window.Buffer || require('buffer').Buffer
  const ReactS3 = new reactS3(config)
  const dispatch = useDispatch()

  const params = useParams()

  const [spinner, setSpinner] = useState(false)

  const { customizeDetails, customizeDetailsLoader } = useSelector(
    (state) => state.teamReducer
  )

  useEffect(() => {
    dispatch(getCustomizeDetail(params.team_id))
  }, [])

  let initialValues = {
    customize_id: customizeDetails?.id || '',
    team_id: params?.team_id || '',
    background: customizeDetails?.background || '',
    score_text: customizeDetails?.score_text || '',
    chat_text: customizeDetails?.chat_text || '',
    main: customizeDetails?.main || '',
    trim: customizeDetails?.trim || '',
    image: customizeDetails?.image || '',
    number: customizeDetails?.number || '',
  }

  let formikRef = useRef(null)

  const validationSchema = yup.object({
    background: yup.string().required('Background color is required'),
    // .test("len", "Please enter valid color", (val) => val?.length === 7),
    score_text: yup.string().required('Score text color is required'),
    // .test("len", "Please enter valid color", (val) => val?.length === 7),
    chat_text: yup.string().required('Chat text color is required'),
    //.test("len", "Please enter a valid color", (val) => val?.length === 7),
    main: yup.string().required('Main color is required'),
    // .test("len", "Please enter a valid color", val => val?.length === 7),
    trim: yup.string().required('Trim color is required'),
    // .test("len", "Please enter a valid color", (val) => val?.length === 7),
    image: yup.string(),
    // .required('Logo is required'),
    number: yup.string().required('Number color is required'),
    // .test("len", "Please enter a valid color", (val) => val?.length == 7),
  })

  const handleChange = (e) => {
   
    let { name, value } = e.target
    formikRef.current.setFieldValue(
      name,
      value.replace(/[^a-z0-9\s\#]/gi, '').slice(0, 7)
    )
  }

  const handleImg = async (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error('Please select image only.')
      document.getElementById('input-image').value = null
    } else {
      setSpinner(true)
      let img = await ReactS3.uploadFile(file, file.name)
      formikRef.current.setFieldValue('image', img.key)
      setSpinner(false)
    }
  }

  const logoClick = (e) => {
    document.getElementById('team-logo').click()
  }

  const submitRequest = (values) => {
    if (!customizeDetails) {
      dispatch(addCustomize(values))
    } else {
      dispatch(editCustomize(values))
    }
  }

  if (customizeDetailsLoader) {
    return <MDSpinner />
  }

  return (
    <>
      <MDBox mt={1}>
        <MDBox mb={3}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submitRequest(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Grid spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <MDBox>
                      <Paper variant="outlined">
                        <MDBox p={3}>
                          <MDTypography mb={3} variant="h6">
                            Customize
                          </MDTypography>
                          <MDBox pb={3} mt={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Background
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Background"
                                    fullWidth
                                    type="text"
                                    name="background"
                                    value={values.background}
                                    onChange={(e) => handleChange(e)}
                                  />

                                  <input
                                    type="color"
                                    id="pickColor"
                                    style={{ height: '45px' }}
                                    name="background"
                                    value={values.background}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="background"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Score Text
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Score"
                                    fullWidth
                                    text="input"
                                    name="score_text"
                                    value={values.score_text}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <input
                                    type="color"
                                    style={{ height: '45px' }}
                                    id="pickColor"
                                    name="score_text"
                                    value={values.score_text}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="score_text"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Chat Text
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Chat"
                                    fullWidth
                                    name="chat_text"
                                    type="text"
                                    value={values.chat_text}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <input
                                    type="color"
                                    id="pickColor"
                                    style={{ height: '45px' }}
                                    name="chat_text"
                                    value={values.chat_text}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="chat_text"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="h6"
                                  verticalAlign="middle"
                                >
                                  Captain Page
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Main
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Main"
                                    type="text"
                                    name="main"
                                    value={values.main}
                                    onChange={(e) => handleChange(e)}
                                    fullWidth
                                  />
                                  <input
                                    type="color"
                                    id="pickColor"
                                    style={{ height: '45px' }}
                                    name="main"
                                    value={values.main}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="main"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Trim
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Trim"
                                    fullWidth
                                    name="trim"
                                    type="text"
                                    value={values.trim}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <input
                                    type="color"
                                    id="pickColor"
                                    style={{ height: '45px' }}
                                    name="trim"
                                    value={values.trim}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="trim"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox pb={3}>
                            <Grid container>
                              <Grid lg={8}>
                                <MDTypography
                                  variant="subtile1"
                                  verticalAlign="middle"
                                >
                                  Number
                                </MDTypography>
                              </Grid>
                              <Grid lg={4}>
                                <Box
                                  component="span"
                                  m={1}
                                  className="codepicker"
                                >
                                  <MDInput
                                    for="pickColor"
                                    label="Number"
                                    fullWidth
                                    name="number"
                                    value={values.number}
                                    onChange={(e) => handleChange(e)}
                                  />

                                  <input
                                    type="color"
                                    id="pickColor"
                                    name="number"
                                    style={{ height: '45px' }}
                                    value={values.number}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                  />
                                </Box>
                                <ErrorMessage
                                  component="span"
                                  name="number"
                                  className="error-message"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </Paper>
                      <MDBox mt={3}>
                        <Paper variant="outlined" p={3}>
                          <MDBox p={3}>
                            <MDBox pb={3}>
                              <MDTypography variant="h6" verticalAlign="middle">
                                Logo
                              </MDTypography>
                            </MDBox>

                            <MDBox pb={3}>
                              <div
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '1%',
                                  borderRadius: '6px',
                                  height: '49px',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  // className={classes.input}
                                  style={{ display: 'none' }}
                                  id="raised-button-file team-logo"
                                  multiple
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleImg(e.target.files[0])}
                                />
                                <label htmlFor="raised-button-file team-logo">
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Button
                                      type="button"
                                      variant="raised"
                                      component="span"
                                      onClick={() => logoClick()}
                                    >
                                      Upload Logo
                                    </Button>
                                    <span style={{ fontSize: '14px' }}>
                                      {values.image}
                                    </span>
                                  </div>
                                </label>
                                {spinner && <ImgSpinner />}
                              </div>
                              <ErrorMessage
                                component="span"
                                name="image"
                                className="error-message"
                              />
                            </MDBox>
                          </MDBox>
                        </Paper>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={3}
                      >
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="large"
                          type="submit"
                          disabled={customizeDetails?.is_game_start==true?true:false
                          }
                        >
                          Update
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </MDBox>
      </MDBox>
    </>
  )
}
