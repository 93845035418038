export const GET_ERRORS = "GET_ERRORS";
export const GET_GALLERY_DATA = "GET_GALLERY_DATA";
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_MANAGE_BUCKETS_DATA = "GET_MANAGE_BUCKETS_DATA";
export const CREATE_BUCKETS = "CREATE_BUCKETS";
export const GET_ALL_TOKEN = "GET_ALL_TOKEN";
export const GET_USER_LIST = "GET_USER_LIST";
export const VIEW_USER = "VIEW_USER";
export const DELETE_USER = "DELETE_USER";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";

export const UPLOAD_BACKGROUND = "UPLOAD_BACKROUND";
export const GET_BACKGROUND_LIST = "GET_BACKGROUND_LIST";
export const DELETE_BACKGROUND = "DELETE_BACKGROUND";

export const GET_UNIFORM_LIST = "GET_UNIFORM_LIST";
export const GET_SPORT_LIST = "GET_SPORT_LIST";
export const CREATE_SPORTS = "CREATE_SPORTS";
export const EDIT_SPORT = "EDIT_SPORT";
export const DELETE_SPORT = "DELETE_SPORT";
export const SAVE_SPORT = "SAVE_SPORT";
export const SAVE_ACTION = "SAVE_ACTION";
export const GET_COLOR = "GET_COLOR";
export const GET_BONUS = "GET_BONUS";

export const SET_GAMES_LIST = "SET_GAMES_LIST";
export const SET_GAME_DETAILS = "SET_GAME_DETAILS";
export const CREATE_GAME = "CREATE_GAME";

export const SET_CUSTOMIZE_DETAILS = "SET_CUSTOMIZE_DETAILS";

export const SET_PRIZE_TYPES_DATA = "SET_PRIZE_TYPES_DATA";
export const SET_BONUS_LIST = "SET_BONUS_LIST";
export const SET_TEAM_LIST = "SET_TEAM_LIST";
export const SET_TEAM_DETAILS = "SET_TEAM_DETAILS";
export const SET_CAPTAINS_LIST = "SET_CAPTAINS_LIST";
export const SET_SPONSOR_LIST = "SET_SPONSOR_LIST";
export const SET_PRIZE_LIST = "SET_PRIZE_LIST";
export const SET_BONUS_PRIZE_LIST = "SET_BONUS_PRIZE_LIST";

export const SET_TEAM_ADMIN_LIST = "SET_TEAM_ADMIN_LIST";
export const SET_TEAM_ADMIN_LIST_LOADER = "SET_TEAM_ADMIN_LIST_LOADER";
export const SET_TEAM_ADMIN_DELETE = " SET_TEAM_ADMIN_DELETE ";

export const SET_CUSTOMIZE_DETAILS_LOADER = "SET_CUSTOMIZE_DETAILS_LOADER";
export const SET_TEAM_LIST_LOADER = "SET_TEAM_LIST_LOADER";
export const SET_TEAM_DETAILS_LOADER = "SET_TEAM_DETAILS_LOADER";
export const SET_SPONSOR_LIST_LOADER = "SET_SPONSOR_LIST_LOADER";
export const SET_CAPTAIN_LIST_LOADER = "SET_CAPTAIN_LIST_LOADER";
export const SET_GAME_PRIZE_LIST_LOADER = "SET_GAME_PRIZE_LIST_LOADER";
export const SET_GAMES_LIST_LOADER = "SET_GAMES_LIST_LOADER";
export const SET_USER_LIST_LOADER = "SET_USER_LIST_LOADER";
export const SET_USER_DETAILS_LOADER = "SET_USER_DETAILS_LOADER";
export const SET_BONUS_LIST_LOADER = "SET_BONUS_LIST_LOADER";
export const SET_ACTION_LIST_LOADER = "SET_ACTION_LIST_LOADER";
export const SET_GAME_SCORE_LOADER = "SET_GAME_SCORE_LOADER";
export const SET_USER_MITI_GAME_LIST = " SET_USER_MITI_GAME_LIST";
export const SET_USER_MITI_GAME_LIST_LOADER = "SET_USER_MITI_GAME_LIST_LOADER";
export const SET_VISITOR_LIST_LOADER = "SET_VISITOR_LIST_LOADER";

export const SET_NEW_PLAYER = "SET_NEW_PLAYER";

export const SAVE_SOCKET_ID = "SAVE_SOCKET_ID";
export const DELETE_ACTION= "DELETE_ACTION";
export const DELETE_BONUS= "DELETE_BONUS";
export const BONUS_LIST= "BONUS_LIST";
export const GAMEPRIZE_LIST="GAMEPRIZE_LIST"



export const GET_ACTION_IMAGE_LIST = "GET_ACTION_IMAGE_LIST";
export const CREATE_ACTION_IMAGE = "CREATE_ACTION_IMAGE";
export const EDIT_ACTION_IMAGE = "EDIT_ACTION_IMAGE";
export const DELETE_ACTION_IMAGE = "DELETE_ACTION_IMAGE";



export const GET_VISITOR_LIST = "GET_VISITOR_LIST";
export const CREATE_VISITOR_LOGO = "CREATE_VISITOR_LOGO ";
export const EDIT_VISITOR_LOGO = "EDIT_VISITOR_LOGO";
export const DELETE_VISITOR_LOGO = "DELETE_VISITOR_LOGO";
export const VISITOR_LIST = "VISITOR_LIST";

export const CREATE_SPORT = "CREATE_SPORT";