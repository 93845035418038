import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPrizeList } from "App/store/actions";
import DeleteModal from "components/DeleteModal";
import { deletePrize } from "App/store/actions";
import MDSpinner from "components/MDSpinner";
import NoData from "components/NoData";
import { toast } from 'react-toastify'
const columns = [
  {
    Header: "Prize Type",
    accessor: "PrizeType",
    width: "20%",
  },
  {
    Header: "Name",
    accessor: "Name",
    width: "20%",
  },
  { Header: "Action", accessor: "action", width: "20%" },
];

function Prize() {
  const [delete_id, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const { prizeList, gamePrizeLoader } = useSelector(
    (state) => state.teamReducer
  );

  const removePrize = () => {
    dispatch(deletePrize(delete_id));
    setDeleteId("");
  };

  const rows = prizeList.map((i) => {
    return {
      PrizeType: i?.prize_type?.prize_name || "--",
      Name: i?.name || "--",
      action: (
        <div>
          <Link to={`/view-team/${i.team_id}/edit-prize/${i.id}`} state={i}>
            <Icon fontSize="small">edit</Icon>
          </Link>
          {i?.is_game_start == false  ?(
          <Link to="#" onClick={() => setDeleteId(i.id)}>
            <Icon fontSize="small">delete</Icon>
          </Link>  ) : <Icon
        style={{ cursor: "Pointer" }}
        fontSize="small"
        onClick={() =>
          toast.error(`You can't delete prize that has game in progress`)
        
        }
      > delete
      </Icon>}
        </div>
      ),
    };
  });

  useEffect(() => {
    dispatch(getPrizeList(params?.team_id));
  }, []);

  if (gamePrizeLoader) {
    return <MDSpinner />;
  }

  return (
    <>
      <MDBox pt={1} pb={3}>
        <MDTypography mb={3} variant="h6">
          Prizes
        </MDTypography>
        {delete_id && (
          <DeleteModal
            open={!!delete_id}
            text={"Are you sure you want to delete this Prize"}
            handleClose={() => setDeleteId("")}
            handleAction={() => removePrize()}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox p={2}>
              {prizeList.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rows,
                  }}
                />
              ) : (
                <NoData />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon
            link={`/view-team/${params?.team_id}/add-prize`}
            color="inherit"
            font="sm"
          />
        </Grid>
      </MDBox>
    </>
  );
}

export default Prize;
