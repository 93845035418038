import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DataTable from 'examples/Tables/DataTable'
import { Icon } from '@mui/material'
import { Link } from 'react-router-dom'
import MDAddIcon from 'components/MDAddIcon/Index'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getGameList, deleteGame } from 'App/store/actions'
import DeleteModal from 'components/DeleteModal'
import MDSpinner from 'components/MDSpinner'
import NoData from 'components/NoData'
import MDTypography from 'components/MDTypography'
import moment from 'moment'
import socket from 'utils/webSocket'
import { toast } from 'react-toastify'
import { ToastHeader } from 'reactstrap'
function Games() {
  const [deleteId, setDeleteId] = useState('')

  const columns = [
    {
      Header: 'Owner Team',
      accessor: 'OwnerTeam',
      width: '20%',
    },
    {
      Header: 'Opponent Team',
      accessor: 'OpponentTeam',
      width: '20%',
    },
    { Header: 'Date', accessor: 'Date', width: '20%' },
    { Header: 'Sport', accessor: 'Sports', width: '20%' },
    { Header: 'Action', accessor: 'action', width: '20%' },
  ]

  const dispatch = useDispatch()

  const { gameList, gameListLoader } = useSelector((state) => state.gameReducer)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getGameList())
  }, [])

  const getDateString = (v) => {
    let ampm = new Date(v).toLocaleString([], {
      date: 'string',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    let arr = new Date(v).toUTCString().split(' ')
    return arr[0] + ' ' + arr[1] + ' ' + arr[2] + ', ' + arr[3] + ', ' + ampm
  }

  const rows = gameList.map((i) => {

    // var game_time = `${i.date} ${i.hours}`
    // var current_time = moment().format('YYYY-MM-DD HH:mm:ss')



    const handlePlay = (id, is_game_start) => {
      const payload = {
        is_game_start: is_game_start,

      }
      localStorage.setItem('is_end_turn', 'false')
      socket.emit('game-play', id, payload)
      // dispatch(getGameList())
      socket.on(`game-play-state-${id}`, (data) => {
        if (data?.success == true) {
          toast.success(data?.message, {
            toastId: 'success',
          })
          window.location.href = `/score/${id}`
        } else {
          toast.error(data?.message, {
            toastId: 'error',
          })
        }
      })
    }

    return {
      OwnerTeam: (
        <div style={{ color: i?.is_game_start == true ? 'red' : 'text' }}>
          {i?.team?.slug}
        </div>
      ),
      OpponentTeam: (
        <div style={{ color: i?.is_game_start == true ? 'red' : 'text' }}>
          {i?.opponent_slug}
        </div>
      ),
      Date: (
        <div
          style={{ color: i?.is_game_start == true ? 'red' : 'text' }}
        >{`${moment(i?.date).format('MM/DD/YYYY')}, ${moment(
          i?.hours,
          'hh:mm'
        ).format('hh:mm A')}`}</div>
      ),
      Sports: (
        <div style={{ color: i?.is_game_start == true ? 'red' : 'text' }}>
          {i?.team?.sport?.name}
        </div>
      ),
      action: (
        <div style={{ color: i?.is_game_start == true ? 'red' : 'text' }}>
          {/* <Link to={`/score/${i.id}`}> */}
          <Icon
            fontSize="small"
            onClick={(id, is_game_start) => handlePlay(i?.id, i?.is_game_start)}
          >
            play_arrow
          </Icon>
          {/* </Link> */}
          {/* {moment().isAfter(`${i.date} ${i.hours}`) == false?
          <Link to={`/edit-game/${i.id}`}  state={i}>
            <Icon fontSize="small">edit</Icon>
          </Link>:null} */}
          <Link
            to={`/edit-game/${i.id}`}
            state={i}
            style={{ color: i?.is_game_start == true ? 'red' : 'text' }}
          >
            <Icon fontSize="small">edit</Icon>
          </Link>
          {i?.is_game_start == false ? (
            <Icon
              style={{ cursor: 'Pointer' }}
              fontSize="small"
              onClick={() =>
                setDeleteId({
                  id: i.id,
                  status: `${i.status == 'ACTIVE' ? 'DELETED' : 'ACTIVE'}`,
                })
              }
            >
              delete
            </Icon>
          ) : (
            <Icon
              style={{ cursor: 'Pointer' }}
              fontSize="small"
              onClick={() =>
                toast.error(`You can't delete game that has game in progress`)
              }
            >
              delete
            </Icon>
          )}
        </div>
      ),
    }
  })

  const deleteGames = (id, status) => {
    dispatch(deleteGame(deleteId?.id, deleteId?.status))
    setDeleteId('')
  }

  if (gameListLoader) {
    return <MDSpinner />
  }

  return (
    <MDBox mt={1}>
      {deleteId?.id && (
        <DeleteModal
          open={!!deleteId?.id}
          handleClose={() => setDeleteId('')}
          handleAction={() => deleteGames()}
          text={'Are you sure you want to delete this game'}
        />
      )}
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              {gameList.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rows,
                  }}
                />
              ) : (
                <NoData />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon link="/add-games" color="inherit" font="sm"></MDAddIcon>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

export default Games
