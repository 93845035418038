import axios from "axios";
import { base_url } from "./url";
import { logout } from "./token";
import { toast } from "react-toastify";
const axiosInstance = axios.create({ baseURL: `${base_url}` });

axiosInstance.interceptors.request.use(
  async function configuration(config) {
    const token = await localStorage.getItem("ACCESS_TOKEN");
    if (token) config.headers.authorization = `${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async function (result) {
    if (
      result.data.statusCode == 401 ||
      result.data.type == "SESSION_EXPIRED"
    ) {
      logout();
    }
  
    return result;
  },
  function (error) {
    toast.error(error?.message);
    return Promise.reject(error);
  }
);

export const GET = async (url, params) => {
  let result = await axiosInstance.get(url, { params: params });
  return result;
};

export const DELETE = async (url, params, data) => {
  let result = await axiosInstance.delete(url, { params, data });
  return result;
};

export const POST = async (url, body, options) => {
  let result = await axiosInstance.post(url, body, options);
  return result;
};

export const PUT = async (url, body, options) => {
  let result = await axiosInstance.put(url, body, options);
  return result;
};

export const MEDIA_UPLOAD_PUT = async (url, body, options) => {
  let result = await axios.put(url, body, options);
  return result;
};
