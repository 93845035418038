import React,{useState,useEffect} from 'react'
export default function Clocks() {
    const current = new Date();
    // By default US English uses 12hr time with AM/PM
const time = current.toLocaleTimeString("en-US",{
    hour: "2-digit",
    minute: "2-digit",
});
  return (
    <div>
    {time} 1st
    </div>
  )
}
