
export const isloggedIn = () => {
  return !!localStorage.getItem('ACCESS_TOKEN') && !!localStorage.getItem('email')
}

export const getAccToken = () => {
  let token = localStorage.getItem('ACCESS_TOKEN')
  return token
} 

export const logout = () => {
  localStorage.clear()
  setTimeout(() => {
    window.location.reload()
  }, 2000)
}

export const getUserType=()=>{
  return localStorage.getItem("user_type")
}