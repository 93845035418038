import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import { Card, Icon, NativeSelect, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import MultiSelect from 'react-select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
  getTeamList,
  getSponsorList,
  getPrizeList,
  getCaptains,
  getPrizeType,
  emptyData,
  Visit,
} from '../../../App/store/actions/team.actions'
import {
  createGame,
  editGame,
  getBonusPrizeList,
  getBonus,
  getGamePrize,
  getGameList,
} from '../../../App/store/actions/games.action'
import { useTheme } from '@mui/material/styles'
import CustomExpandMore from 'components/Expand'
import moment from 'moment'
import InputBase from '@mui/material/InputBase';
import { makeStyles } from "@material-ui/core/styles";
//import TextField from '@material-ui/core/TextField';

//import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuPaper: {
    maxHeight: 400
  }
}));

export default function AddGames() {

  // let gameprizeids = useRef('');
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  let { state = {} } = useLocation()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(
    moment().isAfter(`${state?.date} ${state?.hours}`)
  )

  const { teamList, sponsorList, prizeList, captains, visit } = useSelector(
    (state) => state.teamReducer
  )

  const [selectedCaptain, setSelectedCaptain] = useState([])

  const { bonusPrizes, bonusList, gamePrizeList } = useSelector(
    (state) => state.gameReducer
  )

  const isEditMode = !!state?.id || false
  let formikref = useRef('')
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

  useEffect(() => {
    dispatch(getTeamList())
    if (isEditMode) {
      dispatch(getSponsorList(state.team_id))
      // dispatch(getBonusPrizeList(state.team_id))
      dispatch(getCaptains(state.team_id))
      dispatch(getPrizeList(state.team_id))
      dispatch(getBonus(state.team_id))
      dispatch(getGamePrize(state.team_id))
      dispatch(Visit(state.team_id))
    }
    dispatch(emptyData())
  }, [])

  const processData = useMemo(() => {
    var savedCaptain = []
    for (let i = 0; i < captains?.length; i++) {
      for (let j = 0; j < state?.games_captians?.length; j++) {
        if (captains[i]?.id == state?.games_captians[j]?.captain?.id) {
          savedCaptain.push(captains[i])
        }
      }
    }

    return savedCaptain;
  }, [captains])
  const gamebonusData = useMemo(() => {
    let savedBonus = []
    for (let i = 0; i < bonusList.length; i++) {
      for (let j = 0; j < state?.games_bonus?.length; j++) {
        if (bonusList[i].id === state?.games_bonus[j].prize.id) {
          savedBonus.push(bonusList[i])
        }
      }
    }
    return savedBonus;
  }, [bonusList])
  const getSelectedTeamSlug = () => {
    let { team_id } = formikref.current.values
    return teamList.find((i) => i?.id === team_id)?.slug.toLowerCase() || ''
  }


  const initialState = {
    team_id: state?.team_id || '',
    game_id: state?.id || '',
    opponent_slug: state?.opponent_slug || '',
    date: state?.date || '',
    hours: state?.hours || '',
    is_home_team: isEditMode ? state?.is_home_team : true,
    sponsor_id: state?.sponsor_id || '',
    game_prize_id: state?.game_prize_id || "",
    is_team_bonus_prize: isEditMode ? state?.is_team_bonus_prize : true,
    captains: processData || [],
    bonus: gamebonusData || [],
    visitor_id: state?.visitor_id || "",
    // prize_id:  state?.games_bonus?.prize?.id || '',
    is_game_start: false,
  }

  const validationSchema = yup.object({
    team_id: yup.string().required('Team is required'),
    opponent_slug: yup.string().required('Opponent slug is required'),
    visitor_id: yup.string().required('Visitor Logo is required'),
    // .test({
    //   message: 'Opponent team slug should be different.',
    //   test: (slug) => slug?.toLowerCase() !== getSelectedTeamSlug(),
    // }),
    date: yup
      .date()
      .required('Date is required')
      .min(
        new Date(new Date().setDate(new Date().getDate() - 1)),
        'Enter a valid date'
      ),
    hours: yup
      .string()
      .required('Hours is required')
      .test('is-greater', 'please select future time', function (value) {
        const { date } = this.parent
        let target = new Date(date)
        target.setHours(value?.split(':')[0])
        target.setMinutes(value?.split(':')[1])
        return new Date() <= new Date(target)
      }),
    is_home_team: yup.boolean(),
    sponsor_id: yup.string().when('is_home_team', {
      is: true,
      then: yup.string().required('Sponsor is required'),
    }),
    game_prize_id: yup.string().when('is_home_team', {
      is: true,
      then: yup.string().required('Game prize is required'),
    }),
    is_team_bonus_prize: yup.boolean(),
    bonus: yup
      .array().when('is_team_bonus_prize', {
        is: true,
        then: yup
          .array().of(yup.object().shape({}))
          .required('Team bonus is required').test({
            message: 'Team bonus is required',
            test: (arr) => arr.length != 0,
          }),
      }),




    captains: yup
      .array()
      .of(yup.object().shape({}))
      .test({
        message: 'Please select 4 captains',
        test: (arr) => arr.length == 4,
      }),
    // .test({
    //   message: 'Please select 4 captains',
    //   // test: (arr) => arr.length == 4,
    // }),
  })

  const fetchData = (e) => {
    formikref.current.setFieldValue('bonus', [])
    formikref.current.setFieldValue('captains', [])
    dispatch(getSponsorList(e.target.value))
    dispatch(getBonus(e.target.value))
    dispatch(getCaptains(e.target.value))
    dispatch(getPrizeList(e.target.value))
    dispatch(getGamePrize(e.target.value))
    dispatch(Visit(e.target.value))
    formikref.current.setFieldValue(e.target.name, e.target.value)
  }

  const onSubmit = (values) => {

    values.is_game_start = false
    if (isEditMode) {
      dispatch(editGame({ ...values, navigate }))
    } else {

      dispatch(createGame({ ...values, navigate }))
    }
  }
  const handleChecked = (e, i) => {
    let { name, checked } = e.target
    formikref.current.values[name] = checked
    formikref.current.setFieldValue(formikref.current.values)
  }

  const handleCaptain = (e) => {
    if (e.length < 5) {
      formikref.current.setFieldValue('captains', e)
    }
  }
  const handleChange = (e) => {
    let { name, value } = e.target
    formikref.current.setFieldValue(
      name,
      value.replace(/[^a-z\s]/gi, '').slice(0, 50)
    )
  }
  const defaultcaptains = useMemo(() => {
    let values = captains?.filter((i) => i.is_Default === true)
    formikref?.current && formikref?.current?.setFieldValue('captains', values)
  }, [captains])
  const defaultprize = useMemo(() => {
    let value = gamePrizeList.find(element => element.is_default === true)
    formikref?.current && formikref?.current?.setFieldValue('game_prize_id', value?.id)
    //  value
  }, [gamePrizeList])

  const defaultsponsor = useMemo(() => {
    // let values = gamePrizeList?.filter((i) => i.is_default === true)
    // formikref?.current && formikref?.current?.setFieldValue('game_prize_id', values[0]?.name)

    let value = sponsorList.find(element => element.is_default === true)
    formikref?.current && formikref?.current?.setFieldValue('sponsor_id', value?.id || '')

  }, [sponsorList])
  const defaultBonus = useMemo(() => {
    let values = bonusList?.filter((i) => i?.is_default === true)
    formikref?.current && formikref?.current?.setFieldValue('bonus', values)
  }, [bonusList])
  const classes = useStyles();
  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      {isEditMode ? 'Edit Game' : 'Add New Game'}
                    </MDTypography>
                  </MDBox>
                  <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                    enableReinitialize={true}
                    innerRef={formikref}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form className="in">
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={3}>
                            <MDBox pb={3}>
                              {' '}
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label-team">
                                  Team
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label-team"
                                  id="demo-simple-select"
                                  value={values.team_id}
                                  label="Team"
                                  name="team_id"
                                  onChange={(event) => fetchData(event)}
                                  IconComponent={CustomExpandMore}
                                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                                >
                                  {teamList?.map((item, index) => (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="team_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDBox pb={3}>
                              <MDInput
                                label=" Enter Opponent Name(slug)"
                                autoComplete="off"
                                value={values.opponent_slug}
                                fullWidth
                                name="opponent_slug"
                                onChange={(e) => handleChange(e)}
                              // onChange={(event) =>
                              //   setFieldValue(
                              //     'opponent_slug',
                              //     event.target.value, name,
                              //   )
                              // }
                              // onChange={(event) => setDate(event.target.value)}
                              />
                              {/* <Select
                                  labelId="demo-simple-select-label-team"
                                  id="demo-simple-select"
                                  value={values.opponent_slug}
                                  label="Enter Opponent Name(slug)"
                                  name="opponent_slug"
                                  onChange={(event) =>
                                    setFieldValue(
                                      'opponent_slug',
                                      event.target.value
                                    )
                                  }
                                  IconComponent={CustomExpandMore}
                                >
                                  {teamList?.map((item, index) => (
                                    <MenuItem value={item.slug}>
                                      {item.slug}
                                    </MenuItem>
                                  ))}
                                </Select> */}

                              {/* <FormControl fullWidth>
                                <MDInput
                                  value={values.opponent_slug}
                                  label=" Enter Opponent Name(slug)"
                                  fullWidth
                                  autoComplete="off"
                                  name="opponent_slug"
                                  onChange={(event) =>
                                    setFieldValue(
                                      'opponent_slug',
                                      event.target.value
                                    )
                                  }
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              </FormControl> */}

                              <ErrorMessage
                                name="opponent_slug"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDBox pb={3}>

                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Visitor Logo
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  name="visitor_id"
                                  label="Visitor Logo"
                                  value={values?.visitor_id}
                                  onChange={(event) =>
                                    setFieldValue(
                                      'visitor_id',
                                      event.target.value
                                    )
                                  }

                                  // defaultValue={"yoo"}
                                  IconComponent={CustomExpandMore}

                                >

                                  {visit?.length === 0 ? (
                                    <p p={2}> No Visitor Logo Available</p>
                                  ) : (


                                    visit?.map((i) => (

                                      <MenuItem value={i?.id}>
                                        {i?.name}</MenuItem>

                                    ))
                                  )}
                                </Select>

                              </FormControl>
                              <ErrorMessage
                                name="visitor_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDBox pb={3}>
                              <MDInput
                                label="Game ID"
                                autoComplete="off"
                                fullWidth

                              // onChange={(event) => setDate(event.target.value)}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                                type="date"
                                id="gamedateId"
                                label="Date"
                                name="date"
                                autoComplete="off"
                                fullWidth
                                value={values.date}
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                onChange={(event) =>
                                  setFieldValue('date', event.target.value)
                                }
                              />

                              <ErrorMessage
                                name="date"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                                type="time"
                                label="Hour"
                                id="gametimeId"
                                name="hours"
                                autoComplete="off"
                                fullWidth
                                inputformat="HH:mm:ss"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={values.hours}
                                onChange={(event) =>
                                  setFieldValue('hours', event.target.value)
                                }
                              />
                              <ErrorMessage
                                name="hours"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography>Home</MDTypography>

                          <MDBox mt={0.5} display="flex" alignItems="center">
                            <Switch
                              checked={values.is_home_team}
                              name="is_home_team"
                              onChange={(e) => handleChecked(e)}
                            />
                          </MDBox>
                        </MDBox>
                        {values?.is_home_team && (
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>

                                  {/* <InputLabel id="demo-simple-select-label">
                                      Sponsor
                                    </InputLabel>
                                    <NativeSelect
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      defaultValue={values.sponsor_id}
                                      input={<BootstrapInput />}
                                      value={values.sponsor_id}
                                      label="Sponsor"
                                      name="sponsor_id"
                                      inputProps={{
                                        name: 'sponsor_id',
                                        id: 'uncontrolled-native',
                                      }}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'sponsor_id',
                                          event.target.value
                                        )
                                      }
                                      IconComponent={CustomExpandMore}
                                    >
                                      {sponsorList.length === 0 ? (
                                        <option value="none"> No Sponsor Available</option>
                                      ) : (
                                        sponsorList?.map((i) => (
                                          <option value={i.id}>
                                            {i.name}
                                          </option>
                                        ))
                                      )} */}
                                  {/* </NativeSelect> */}

                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Sponsor
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      name="sponsor_id"
                                      label="Sponsor"
                                      value={values?.sponsor_id}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'sponsor_id',
                                          event.target.value
                                        )
                                      }

                                      // defaultValue={"yoo"}
                                      IconComponent={CustomExpandMore}

                                    >

                                      {sponsorList?.length === 0 ? (
                                        <p p={2}> No Sponsor Available</p>
                                      ) : (


                                        sponsorList?.map((i) => (

                                          <MenuItem value={i?.id}>
                                            {i?.name}</MenuItem>

                                        ))
                                      )}


                                    </Select>

                                  </FormControl>
                                  <ErrorMessage
                                    name="sponsor_id"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Game Prize
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      name="game_prize_id"
                                      label="Game Prize"
                                      value={values?.game_prize_id}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'game_prize_id',
                                          event.target.value
                                        )
                                      }
                                      // defaultValue={"yoo"}
                                      IconComponent={CustomExpandMore}

                                    >

                                      {gamePrizeList?.length === 0 ? (
                                        <p p={2}> No Game Prize Available</p>
                                      ) : (


                                        gamePrizeList?.map((i) => (

                                          <MenuItem value={i?.id}>
                                            {i?.name}</MenuItem>

                                        ))
                                      )}


                                    </Select>
                                    {/* <NativeSelect

                                      labelId="demo-simple-select-label2"
                                      id="demo-simple-select"
                                      name="game_prize_id"
                                      defaultValue={values?.game_prize_id}
                                      label="Game Prize"
                                      value={values?.game_prize_id}
                                      input={<BootstrapInput />}
                                      inputProps={{
                                        name: 'game_prize_id',
                                        id: 'uncontrolled-native',
                                      }}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'game_prize_id',
                                          event.target.value
                                        )
                                      }
                                      IconComponent={CustomExpandMore}
                                    >
                                      {gamePrizeList?.length === 0 ? (
                                        <p p={2}> No Game Prize Available</p>
                                      ) : (
                                    
                                     
                                        gamePrizeList?.map((i) => (
                                          <option value={i?.id}>
                                            {i?.name}
                                          </option>
                                        ))
                                      )}
                                    </NativeSelect> */}
                                  </FormControl>
                                  <ErrorMessage
                                    name="game_prize_id"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        <MDBox
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography>Team Bonus Prize</MDTypography>

                          <MDBox mt={0.5} display="flex" alignItems="center">
                            <Switch
                              name="is_team_bonus_prize"
                              checked={values.is_team_bonus_prize}
                              onChange={(e) => handleChecked(e)}
                            />
                          </MDBox>
                        </MDBox>

                        {values.is_team_bonus_prize && (
                          <MDBox pb={3}>
                            <Grid lg={6}>
                              <FormControl fullWidth>
                                <MultiSelect
                                  name="bonus"
                                  placeholder="Team bonus prize"
                                  isMulti={true}
                                  className="react-multi-select-placeholder"
                                  isSearchable={false}
                                  options={bonusList}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={values.bonus}
                                  noOptionsMessage={() =>
                                    'No Team Bonus Prize Available'
                                  }
                                  onChange={(e) => {
                                    setFieldValue('bonus', e)
                                  }}
                                />
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                    Team Bonus Prize
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label2"
                                      id="demo-simple-select"
                                      value={values.prize_id}
                                      name="prize_id"
                                      label="Team Bonus Prize"
                                      onChange={(event) =>
                                        setFieldValue(
                                          'prize_id',
                                          event.target.value
                                        )
                                      }
                                      IconComponent={CustomExpandMore}
                                    >
                                      {bonusList?.length === 0 ? (
                                        <p p={2}> No Team Bonus Prize Available</p>
                                      ) : (
                                        bonusList?.map((i) => (
                                          <MenuItem value={i.id}>
                                            {i.name}
                                          </MenuItem>
                                        ))
                                      )}
                                    </Select>
                                  </FormControl> */}

                              </FormControl>
                              <ErrorMessage
                                name="bonus"
                                component="span"
                                className="error-message"
                              />
                            </Grid>
                          </MDBox>
                        )}
                        <Grid lg={6}>
                          {' '}
                          <MDBox pb={3}>
                            <FormControl fullWidth>
                              <MultiSelect
                                name="captains"
                                placeholder="Miti Teams"
                                className="react-multi-select-placeholder"
                                isMulti={true}
                                isSearchable={false}
                                options={captains}
                                getOptionLabel={(option) => option.first_name}
                                getOptionValue={(option) => option.id}
                                value={values.captains}
                                onChange={(e) => handleCaptain(e)}
                                noOptionsMessage={() =>
                                  'No Miti Teams Available'
                                }
                              />
                            </FormControl>
                            <ErrorMessage
                              name="captains"
                              component="span"
                              className="error-message"
                            />
                          </MDBox>
                        </Grid>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {isEditMode ? <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                            disabled={state?.is_game_start == true ? true : false}
                          >
                            Update
                          </MDButton>
                            :

                            <MDButton
                              variant="gradient"
                              color="info"
                              size="large"
                              type="submit"
                            // disabled={disable}
                            >
                              Create Game
                            </MDButton>
                          }
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
