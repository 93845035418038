import * as actionTypes from '../actions/actionTypes'

const initialState = {
  gameList: [],
  gameData: {},
  addGame: {},
  gameListLoader: false,
  scoreLoader: false,
  bonusPrizes: [],
  bonusList:[],
  gamePrizeList:[]
}

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GAMES_LIST: {
      return {
        ...state,
        gameList: action.payload,
      }
    }
    case actionTypes.SET_GAME_DETAILS: {
      const games_captians = action?.payload?.games_captians
      if (games_captians?.length) {
        games_captians[0]['class'] = 'captain-selected'
      }
      return {
        ...state,
        gameData: action.payload,
      }
    }
    case actionTypes.CREATE_GAME: {
      return {
        ...state,
        addGame: action.payload,
      }
    }
    case actionTypes.SET_GAMES_LIST_LOADER: {
      return {
        ...state,
        gameListLoader: action.payload,
      }
    }
    case actionTypes.SET_BONUS_PRIZE_LIST: {
      return {
        ...state,
        bonusPrizes: action.payload,
      }
    }
    case actionTypes.BONUS_LIST: {
      return {
        ...state,
        bonusList: action.payload,
      }
    }
    case actionTypes.GAMEPRIZE_LIST: {
      return {
        ...state,
        gamePrizeList: action.payload,
      }
    }
    case actionTypes.SET_GAME_SCORE_LOADER: {
      return {
        ...state,
        scoreLoader: action.payload,
      };
    }
    default: {
      return state
    }
  }
}

export default gameReducer
