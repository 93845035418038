import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DeleteModal from "components/DeleteModal";
import NoData from "components/NoData";
import MDSpinner from "components/MDSpinner";
import { getVisitorLogoList } from "App/store/actions";
import { deleteVisitor } from "App/store/actions";
import { toast } from 'react-toastify'
const columns = [
  {
    Header: "Name",
    accessor: "Name",
    width: "20%",
  },
  {
    Header: "Logo",
    accessor: "Logo",
    width: "20%",
  },
//   { Header: "Default", accessor: "Default", width: "20%" },
  { Header: "Action", accessor: "action", width: "20%" },
];
function VisitorLogo() {
  const [delete_id, setDeleteId] = useState("");

  const {  visitorList, visitorListLoader } = useSelector(
    (state) => state.teamReducer
  );

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getVisitorLogoList(params?.team_id));
  }, []);

  const removeVisitor = () => {
    dispatch(deleteVisitor(delete_id));
    setDeleteId("");
  };

  const rows = visitorList.map((i) => {
    return {
      Name: i.name,
      Logo: i.image,
    //   Default: i.is_default ? "YES" : "NO",
      action: (
        <div>
          {i?.is_game_start==false?  <Link to={`/view-team/${i.team_id}/edit-visitor/${i.id}`} state={i}>
            {" "}
            <Icon fontSize="small">edit</Icon>
          </Link>:  <Link to="#">
              {" "}
              <Icon fontSize="small" onClick={() =>

                toast.error(`You can't edit visitors logo that has game in progress`)

              }>edit</Icon>
            </Link>}
        
            {i?.is_game_start==false? <Link to="#" onClick={() => setDeleteId(i.id)}>
            <Icon fontSize="small">delete</Icon>
          </Link>: <Link to="#">
              {" "}
              <Icon fontSize="small" onClick={() =>

                toast.error(`You can't delete visitors logo that has game in progress`)

              }>delete</Icon>
            </Link>}
        </div>
      ),
    };
  });

  if (visitorListLoader) {
    return <MDSpinner />;
  }

  return (
    <>
      <MDBox pt={1} pb={3}>
        <MDTypography mb={3} variant="h6">
          Visitor Logos
        </MDTypography>
        {delete_id && (
          <DeleteModal
            text="Are you sure you want to delete this Visitor Logo"
            open={!!delete_id}
            handleClose={() => setDeleteId("")}
            handleAction={() => removeVisitor()}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox p={2}>
              {rows.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rows,
                  }}
                />
              ) : (
                <NoData />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon
            link={`/view-team/${params?.team_id}/add-visitor`}
            color="inherit"
            font="sm"
          />
        </Grid>
      </MDBox>
    </>
  );
}

export default VisitorLogo;
