 

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "App/store/actions/users.actions";
import { useEffect, useState } from "react";
import { deleteUser } from "App/store/actions/users.actions";
import DeleteModal from "components/DeleteModal";
import MDSpinner from "components/MDSpinner";

function User() {
  const { userList,userListLoader } = useSelector((state) => state.userlistReducer);
  const dispatch = useDispatch();
  const [delete_id, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  const removeTeam = () => {
    dispatch(deleteUser(delete_id));
    setDeleteId("");
  };

  const columns = [
    {
      Header: "UserName",
      accessor: "UserName",
      width: "20%",
    },
    { Header: "Date of signup", accessor: "Date", width: "20%" },
    { Header: "No. of miti game played", accessor: "Miti", width: "20%" },
    { Header: "Action", accessor: "action", width: "20%" },
  ];
  const rows = userList.map((item, index) => {
    return {
      UserName: item.user_name,
      Date: new Date(item.createdAt).toLocaleDateString(),
      Miti: item.total_miti_game,
      action: (
        <div>
          <Link to={`/view-detail/${item.id}`}>
            <Icon fontSize="small">visibility_icon</Icon>
          </Link>{" "}
          <Link to="#" onClick={() => setDeleteId(item.id)}>
            <Icon fontSize="small">delete</Icon>
          </Link>
        </div>
      ),
    };
  });


  if(userListLoader){
    return <MDSpinner/>
  }

  return (
    <MDBox pt={1} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {delete_id && (
            <DeleteModal
              open={!!delete_id}
              handleAction={() => removeTeam()}
              handleClose={() => setDeleteId("")}
              text="Are you sure you want to delete this user"
            />
          )}
          <MDBox>
            <MDTypography variant="h6">Users</MDTypography>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              table={{
                columns: columns,
                rows: rows,
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default User;
