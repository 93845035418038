import * as actionTypes from "./actionTypes";
import { toastData } from "../../../utils/toaster";
import { DELETE, GET, POST, PUT } from "../../../utils/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getUserList = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_USER_LIST_LOADER, payload: true });
    dispatch({
      type: actionTypes.GET_USER_LIST,
      payload: [],
    });
    try {
      const response = await GET(`/v1/admin/userList`);
     
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.GET_USER_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_USER_LIST_LOADER, payload: false });
  };
};

export const userDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_USER_DETAILS_LOADER, payload: true });
    dispatch({ type: actionTypes.VIEW_USER, payload: {} });
    try {
      const response = await GET(`/v1/admin/userDetails/?user_id=${id}`);

      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.VIEW_USER,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_USER_DETAILS_LOADER, payload: false });
  };
};



export const deleteUser = (user_id, status) => {
  return async (dispatch, getState) => {
    let response = await DELETE(
      `v1/admin/deleteUser`,
      {},
      { user_id: user_id, status: "DELETED" }
    );
    if (response.data.statusCode === 200) {
      let { userList = [] } = getState().userlistReducer;
      let index = userList.findIndex((i) => i.id === user_id);
      userList.splice(index, 1);
      dispatch({ type: actionTypes.GET_USER_LIST, payload: userList });
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };
};

export const getUserTeamAdminList = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.SET_TEAM_ADMIN_LIST_LOADER,
        payload: true,
      });
      dispatch({
        type: actionTypes.SET_TEAM_ADMIN_LIST,
        payload: [],
      });
      let params = {
        team_id: id || "",
      };
      if (!id) {
        delete params["team_id"];
      }
      const response = await GET(`/v1/admin/teamAdmin`, params);

      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_TEAM_ADMIN_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({
      type: actionTypes.SET_TEAM_ADMIN_LIST_LOADER,
      payload: false,
    });
  };
};

export const deleteTeamAdmin = (user_id, status) => {
  return async (dispatch, getState) => {
    let response = await DELETE(
      `v1/admin/deleteUser`,
      {},
      { user_id: user_id, status: "DELETED" }
    );
    if (response.data.statusCode === 200) {
      let { teamadminList = [] } = getState().userlistReducer;
      let index = teamadminList.findIndex((i) => i.id === user_id);
      teamadminList.splice(index, 1);
      dispatch({
        type: actionTypes.SET_TEAM_ADMIN_LIST,
        payload: teamadminList,
      });
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
   }
  };
};


export const getUserMitiGameList = (userId) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_USER_MITI_GAME_LIST_LOADER, payload: true });
    dispatch({
      type: actionTypes. SET_USER_MITI_GAME_LIST,
      payload: [],
    });
    try {
      const response = await GET(`/v1/admin/userMitiGame?user_id=${userId}`);
     
      if (response.data.statusCode === 200) {
     
        dispatch({
          type: actionTypes. SET_USER_MITI_GAME_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_USER_MITI_GAME_LIST_LOADER, payload: false });
  };
};
