import React, { useEffect, useRef, useState } from 'react'

import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import { Button, Card } from '@mui/material'
import 'assets/styles.css'
import { Formik, Form } from 'formik'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Icon from '@mui/material/Icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomExpandMore from 'components/Expand'
import socket from 'utils/webSocket'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify'
import { addNewPlayers } from 'App/store/actions'
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuPaper: {
    maxHeight: 400
  }
}));

function AddPlayer(props) {
  const dispatch = useDispatch()
  const params = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  // const [counter, setCounter] = useState(localStorage.getItem(`counter${params?.id}`))
  const formikref = useRef(null)
  const [disabled, setdisabled] = useState(false)

  const [modal, setModal] = useState(false);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [playerName, setPlayerName] = useState('');
  //let params = useParams()

  //const history = useHistory();






  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 20,
    p: 4,
  };


  const { teamDetails = {}, teamDetailsLoader, newPlayer = {} } = useSelector(
    (state) => state.teamReducer
  )




  // const {teamDetails = {}, teamDetailsLoader} = useSelector((state) => {
  //   state.teamReducer
  // })






  const {
    games_captians = [],
    current_action = {},
    team: { sport: { actions = [] } = {} } = {},
  } = state
  const initialState = {
    game_id: params?.id || '',
    action_id: params?.action_id || '',
    captain_name: '',
    team_id: state.team_id,
    miti_points: state?.miti_point,
    win_streak_name: state?.win_streak_name,
    late_game_points: state?.late_game_points,
  }


  // const validationSchema = yup.object({
  //   captain_name: yup.string().required('Please select the player'),
  // })
  const onSubmit = (values) => {
    const payload = {
      game_id: params?.id,
      captain_name: values.captain_name,
      team: state?.team?.id,
      action_id: params?.action_id,
      ply_by_ply_description: current_action?.ply_by_ply_description,
      previous_score: state?.home_team_score,
    }

    localStorage.setItem('is_end_turn', current_action?.is_end_turn)

    socket.emit('play-by-play', params?.id, payload)

    if (current_action?.is_win_trigger === true) {
      // let counter=0;
      // if(Number(localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`))==0||null){
      //   counter=0
      //   localStorage.setItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`, counter)
      // }
      // else{
      //   counter=Number(localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`))

      // }
      // current_action.point_value = values.miti_points
      current_action['type'] = 'winstreak'
      // counter=Number(localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`))
      // let count = Number(counter) + 1
      // count = Number(localStorage.setItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`, count))




      // if ( Number(localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`))-1 == current_action?.win_bonus_trigger) {
      //   setdisabled(true)
      // }
      //  else {

      // if (counter < current_action?.win_bonus_trigger) {
      const payload = {
        game_id: params?.id,
        team: state?.team?.id,
        action_id: params?.action_id,
        late_game_points: state?.late_game_points,
        is_win_trigger: current_action.is_win_trigger,
        captain_id: state.captain.id,
        late_game_point: current_action?.late_game_point,
        time: current_action?.time,
        captain_name: values.captain_name,
        type: current_action?.type,
        miti_points: current_action?.miti_point,
        point_value: current_action?.point_value,
        win_description: current_action.win_description,
        description: current_action.description,
        // slug:current_action?.is_home_team == true ? state?.team?.slug :state?.opponent_slug,
        team_slug: current_action?.is_home_team == true ? state?.team?.slug : state?.opponent_slug,
        team_logo: current_action?.is_home_team == true ? state?.team?.image : state?.visitor?.image,
        win_streak_name: current_action?.win_streak_name,
        action_image: current_action?.ActionsImage?.action_image_url
      }
      //  Number( localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`))
      // socket.emit('win-streak-trigger', { ...values, ...current_action })
      socket.emit('win-streak-trigger', payload)
      socket.emit('home-team', payload)

      let pointvalue = `${current_action?.ply_by_ply_description}`
      sessionStorage.setItem(`point${params?.id}`, pointvalue)

      // counter=count

      // } else {
      //   setdisabled(true)
      // }
      // }

    }
    else {
      playpoint(values)
      let pointvalue = `${current_action?.ply_by_ply_description}`
      sessionStorage.setItem(`point${params?.id}`, pointvalue)

    }
    localStorage.setItem('is_end_turn', current_action?.is_end_turn)

    navigate(-1)
  }
  const playpoint = (values) => {
    current_action['type'] = 'winstreakOff'
    let payload = {
      captain_id: state.captain.id,
      point_value: current_action.point_value,
      game_id: params?.id,
      team_id: state?.team?.id,
      action_id: params?.action_id,
      late_game_points: state?.late_game_points,
      is_win_trigger: current_action.is_win_trigger,
      late_game_point: !!state?.late_game_points === true ? current_action?.late_game_point : 0,
      description: current_action.description,
      time: current_action?.time,
      captain_name: values.captain_name,
      type: current_action?.type,
      team_slug: current_action?.is_home_team == true ? state?.team?.slug : state?.opponent_slug,
      team_logo: current_action?.is_home_team == true ? state?.team?.image : state?.visitor?.image,
      action_image: current_action?.ActionsImage?.action_image_url
    }
    socket.emit('bonus-trigger', payload)
  }

  // const endturn = () => {
  //   if (current_action?.is_end_turn == true) {
  //  
  //   } else {
  //    
  //   }
  // }
  useEffect(() => {
    //state?.team_id
    if (state?.team_id) {
      //dispatch(getNewPlayers(state?.game_id))
      const gameId = {
        gameId: state?.game_id
      }
      dispatch(addNewPlayers(gameId));
    }


    // if (
    //   localStorage.getItem(`counter${params?.action_id}-${params?.id}-${state?.captain?.id}`) ==
    //   current_action?.win_bonus_trigger
    // ) {
    //   setdisabled(true)
    // } else {
    //   setdisabled(false)
    // }
  }, [])


  const toggle = () => {
    setOpen(!open);
  }

  const reload = () => window.location.reload();

  const addNewPlayer = (e) => {
    e.preventDefault();
    const payload = {
      gameId: params?.id,
      name: playerName,
      team_id: state?.team?.id,
      team_admin: teamDetails?.users || [{ name: '', email: '', password: '' }],
    }
    dispatch(addNewPlayers(payload));
    handleClose();
    //reload();
    toast.success('New Player Added Successfully!');
    //navigate(-1)
    // setTimeout(() => {
    //   reload();
    // }, 1000);
    localStorage.setItem('is_end_turn', current_action?.is_end_turn)
  }



  const checkAlphabeticName = (e) => {

    const regex = /[A-Za-z]/;


    // if (e.target.value === "" || re.test(e.target.value)) {
    //   setPlayerName(e.target.value);
    // }

    const chars = e.target.value.split('');
    const char = chars.pop();
    if (!regex.test(char)) {
      e.target.value = chars.join('');
    }
  }

  const playernameset = (e) => {
    setPlayerName(e.target.value)
  }





  const navigatePrevPage = () => {

    localStorage.setItem("is_end_turn", "false")
    navigate(-1)
  }

  const classes = useStyles();

  // endturn()
  return (
    <MDBox mt={1} className="in">
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      // className='icon-back'
                      fontSize="medium"
                      sx={{ paddingTop: '4px', marginTop: "-15px" }}
                      onClick={navigatePrevPage}
                    >
                      arrow_back_icon
                    </Icon>

                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      {state?.name}
                    </MDTypography>
                  </MDBox>
                  <Formik
                    initialValues={initialState}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                    innerRef={formikref}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form className="in">
                        <Grid container spacing={2}>
                          <Grid lg="4"></Grid>
                          <Grid lg="4" xs="12">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label-team">
                                Player
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-team"
                                id="demo-simple-select"
                                value={values.captain_name}
                                name="captain_name"
                                label="Player"
                                onChange={(e) =>
                                  setFieldValue(
                                    'captain_name',
                                    e.target.value,

                                  )
                                }
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                IconComponent={CustomExpandMore}
                              >
                                {/* {games_captians.map((i) => (
                                  <MenuItem value={i.captain.last_name}>
                                    {i.captain.last_name}
                                  </MenuItem>
                                ))
                             
                                
                                } */}
                                {
                                  newPlayer.length > 0 ?
                                    newPlayer.map((index) => (
                                      index.name ?
                                        <MenuItem value={index.name}>
                                          {index.name}
                                        </MenuItem> : ''
                                    ))
                                    : ''
                                }
                                <MenuItem
                                  value={'Add New'}
                                  onClick={() => {
                                    toggle();
                                  }}
                                >
                                  Add New Player
                                </MenuItem>
                              </Select>{' '}
                              {/* <ErrorMessage
                                component="span"
                                name="captain_name"
                                className="error-message"
                              /> */}

                            </FormControl>
                            {current_action.is_win_trigger == true ? (
                              <FormControl
                                style={{ marginTop: '10%' }}
                                fullWidth
                              >
                                <InputLabel id="demo-simple-select-label-team">
                                  Win Streak Bonus Trigger
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label-team"
                                  id="demo-simple-select"
                                  value={values.win_streak_name}
                                  label="Win Streak Bonus Trigger"
                                  name="team_id"
                                  IconComponent={CustomExpandMore}

                                  onChange={(e) =>
                                    setFieldValue('win_streak_name', e.target.value)
                                  }
                                >
                                  <MenuItem value={current_action?.win_streak_name}>
                                    {current_action?.win_streak_name}
                                  </MenuItem>
                                </Select>{' '}
                              </FormControl>
                            ) : null}
                            <MDBox
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              pt={5}
                            >
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="large"
                                type="submit"
                                disabled={disabled}
                                style={{ marginTop: '4%' }}
                              >
                                Confirm
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>

                  {
                    open ?

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth
                      >
                        <form onSubmit={addNewPlayer}>
                          <Box sx={style} className="add_new_bx">

                            <Button aria-label="delete" color="primary" style={{
                              position: "absolute",
                              top: "0",
                              right: "0"
                            }} onClick={handleClose} >
                              <ClearIcon />
                            </Button>

                            <Typography id="modal-modal-title" variant="h6" component="h2" >

                              Add New Player
                            </Typography>
                            <TextField
                              fullWidth
                              id="demo-helper-text-misaligned-no-helper"
                              label="Enter Player Name"
                              //variant="filled"
                              required
                              type="text"
                              inputProps={{ maxLength: 10 }}


                              //inputProps={{ pattern: "[a-z]" }}
                              onChange={(e) => {
                                playernameset(e);
                                checkAlphabeticName(e)
                              }
                              }

                              style={{ marginTop: '5%' }}
                            />
                            <MDBox display="flex"
                              justifyContent="center" >
                              <MDButton
                                pt={5}
                                variant="gradient"
                                color="info"

                                size="medium"
                                type="submit"
                                disabled={disabled}
                                style={{ marginTop: '5%', justifyContent: "center" }}
                              >
                                ADD NEW PLAYER
                              </MDButton>
                            </MDBox>
                          </Box>
                        </form>
                      </Modal>


                      : ''
                  }


                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

export default AddPlayer
