import React from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import { Card, Icon } from '@mui/material'
import MDTypography from 'components/MDTypography'
import 'assets/styles.css'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Customize from './Component/Customize'
import Sponsor from './Component/Sponsor'
import Captains from './Component/Captains'
import Prize from './Component/Prize'
import { useNavigate, useParams } from 'react-router-dom'
import TeamAdminList from './Component/TeamAdminList'
import { getUserType } from 'utils/token'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import VisitorLogo from './Component/VisitorLogo/index'

let keys = {
  customize: '0',
  'team-details': '1',
  sponsor: '2',
  captain: '3',
  prize: '4',
  visitor: '5'
}

export default function ViewTeam() {
  let params = useParams()
  const naviagte = useNavigate()
  const defaultKey = getUserType() === 'MASTER_ADMIN' ? '0' : '1'
  const [value, setValue] = React.useState(keys[params?.key] || defaultKey)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    let url = Object.keys(keys).find((key) => keys[key] === newValue)
    naviagte(`/view-team/${params.team_id}/${url}`)
  }

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => naviagte('/teams')}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h6">
                      View Team
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox p={3}>
                  <Box
                    sx={{
                      bgcolor: 'background.paper',
                    }}
                  >
                    <MDBox>
                      <TabContext value={value}>
                        <MDBox aria-label="lab API tabs example">
                          <TabList
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                          >
                            {getUserType() === 'MASTER_ADMIN' ? (
                              <Tab label="Customize" value="0" />
                            ) : (
                              <Tab label="Team Admin" value="1" />
                            )}
                            <Tab label="Sponsors" value="2" />
                            <Tab label="Captains" value="3" />
                            <Tab label="Prizes" value="4" />
                            <Tab label="Visitor Logos" value="5" />
                          </TabList>
                        </MDBox>
                        <TabPanel value="0">
                          <Customize />
                        </TabPanel>
                        <TabPanel value="1">
                          <TeamAdminList />
                        </TabPanel>
                        <TabPanel value="2">
                          <Sponsor />
                        </TabPanel>
                        <TabPanel value="3">
                          <Captains />
                        </TabPanel>
                        <TabPanel value="4">
                          <Prize />
                        </TabPanel>
                        <TabPanel value="5">
                          <VisitorLogo />
                        </TabPanel>
                      </TabContext>
                    </MDBox>
                  </Box>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
