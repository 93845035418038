import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'

import MDBox from 'components/MDBox'
import React, { useState, useEffect, useCallback, useRef } from 'react'
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card, Icon } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import { addCaptain } from 'App/store/actions'
import { editCaptain } from 'App/store/actions'
import { useDispatch, useSelector } from 'react-redux'
//import * as Actions from '../../../App/store/actions'
import { toast } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import S3 from 'react-aws-s3'

import { useNavigate, useParams, useLocation } from 'react-router-dom'
import ImgSpinner from 'components/ImgLoader'
import CustomExpandMore from 'components/Expand'

//ravi
// import { YearRangePicker } from "react-year-range-picker";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import { config } from "utils/s3config";




export default function AddCaptain() {
  const [sponsor, setSponsor] = React.useState('')


  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();




  window.Buffer = window.Buffer || require('buffer').Buffer

  const [url, setUrl] = React.useState('')

  const [yearRange, setYearRange] = useState();

  const [showEndYear, setShowEndYear] = useState(false);

  const ReactS3Client = new S3(config)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const isEditMode = !!state?.id || false

  const validationSchema = yup.object({
    image_url: yup.string().required('Image is required'),
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    jersey_number: yup
      .string()
      .required(' Jersey number is required')
      .matches(/^[0-9]+$/, "Must be only digits")
      .test(
        'len',
        'Max 2 digits are allowed ',
        (val) => val?.toString().length < 3
      ),

    start_Date : yup
    .string()
      .required(' Start Year is required')
      .test(
        'len',
        'Max 4 digits are allowed ',
        (val) => val?.toString().length < 5
      ),
      end_Date : yup
    .string()
      .required(' End Year is required')
      .test(
        'len',
        'Max 4 digits are allowed ',
        (val) => val?.toString().length < 5
      ),
    //career_date: yup
    // .toString().test(val => val !== '' && val.toString().length 
    // === 9 ).max(new Date().getFullYear()).required(),
    // career_date: yup
    //   .date()
    //   //.required('Date is required')
    //   .min(new Date('01-01-1980').getFullYear(), 'Please Enter a valid date')
    //   .max(new Date('01-01-2025').getFullYear(), 'Please Enter a valid date'),

    stats: yup
      .string()
      .required('Stats is required')
      .max(100, 'Max 100 characters are allowed'),

    highlight: yup
      .string()
      .required('Highlight is required'),
      // .max(1500, 'Max 1500 characters are allowed'),

    instagram: yup
      .string()
      // .matches(
      //   /[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //   'Enter valid instagram!'
      // )
      .required('Instagram is required'),

    is_Default: yup.string().required('Default is required'),
  })

  const params = useParams()
  const [spinner, setSpinner] = useState(false)
 
  const initialValues = {
    
    team_id: params?.team_id || '',

    image_url: state?.image_url || '',
    captain_id: params?.captain_id || '',
    
    start_Date: state?.start_Date || '',
    end_Date:state?.end_Date || '',

    // start_Date:'',
    // end_Date:'',

    first_name: state?.first_name || '',
    last_name: state?.last_name || '',
    jersey_number: state?.jersey_number == "00" ? '00' : state?.jersey_number == "0" ? "0" : state?.jersey_number || '',
    stats: state?.stats || '',
    // career_date: state?.career_date || '',
    highlight: state?.highlight || '',
    instagram: state?.instagram || '',
    is_Default: state?.is_Default.toString() || '',
  }
  const formikRef = useRef(null)

  const handleChange = (e, l, regex) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(
      name,
      value
        // .replace(/[^a-z0-9\s]/gi, '').slice(0, l)
        .replace(regex, '')
        .slice(0, l)
    )
  }

  

  const handleNumbers = (e, l) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(name, value.replace(/\D/g, '').slice(0, 2))
  }

  const submitRequest = (values) => {
    
    values.start_Date = `${values.start_Date}`
    // delete values.start_Date;
    // delete values.end_Date;
    if (isEditMode) {
      dispatch(editCaptain({ ...values, navigate }))
    } else {
      dispatch(addCaptain({ ...values, navigate }))
    }
  }

  const handleImg = async (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error('Please select image only.')
      document.getElementById('input-image').value = null
    } else {
      setSpinner(true)
      let img = await ReactS3Client.uploadFile(file, file.name)

      formikRef.current.setFieldValue('image_url', img.key)
      setSpinner(false)
    }
  
  }

  const handleInsta = (e) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue('instagram', value)
  }

  const logoClick = (e) => {
    document.getElementById('team-logo').click()
  }

  const handleYear = () => {
  
    formikRef.current.setFieldValue('career_date', yearRange?.startYear+'-'+yearRange?.endYear);
  }

  const checkDate = (setFieldValue) => {
   
    
    if(startDate.getFullYear() > endDate.getFullYear()){
      alert('change end date');
     
    }
    formikRef.current.setFieldValue('career_date',`${startDate?.getFullYear()}-${endDate?.getFullYear()}`)
  }

  const checkYear = (career_date) => {
    // if(career_date){
    //   let yearArr = [];
    //   yearArr = career_date.split('-');
    //   setStartDate(yearArr[0]);
    //   setEndDate(yearArr[1]);
   
    // }
  }

  var arr=[];

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDTypography mb={3} variant="h4">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    {isEditMode ? 'Edit' : 'Add'} Captain
                  </MDTypography>
                  
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => submitRequest(values)}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      
                      <Form>
                        
                        <MDBox mt={3}>
                          <Paper variant="outlined" p={3}>
                            <MDBox p={3}>
                              <MDBox pb={3}>
                                <MDTypography
                                  variant="h6"
                                  verticalAlign="middle"
                                >
                                  Logo
                                </MDTypography>
                              </MDBox>

                              <div
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '1%',
                                  borderRadius: '6px',
                                  height: '49px',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  // className={classes.input}
                                  style={{ display: 'none' }}
                                  id="raised-button-file team-logo"
                                  multiple
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleImg(e.target.files[0])}
                                />
                                <label htmlFor="raised-button-file team-logo">
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    {' '}
                                    <Button
                                      type="button"
                                      variant="raised"
                                      component="span"
                                      onClick={() => logoClick()}
                                    >
                                      Upload Logo
                                    </Button>
                                    <span style={{ fontSize: '14px' }}>
                                      {values.image_url}
                                    </span>
                                  </div>
                                </label>
                                {spinner && <ImgSpinner />}
                              </div>

                              <ErrorMessage
                                name="image_url"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Paper>
                        </MDBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3} mt={3}>
                              <MDInput
                                label="First Name"
                                fullWidth
                                value={values.first_name}
                                name="first_name"
                                onChange={(e) => handleChange(e, 10,/[^a-z\s]/gi)}
                              />
                              <ErrorMessage
                                name="first_name"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3} mt={3}>
                              <MDInput
                                label="Last Name"
                                fullWidth
                                value={values.last_name}
                                name="last_name"
                                // onChange={(e) => handleChange(e, 32)}
                                onChange={(e) => handleChange(e, 10,/[^a-z\s]/gi)}
                              />
                              <ErrorMessage
                                name="last_name"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        {/* New Grid start */}
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                              <MDBox pb={3} mt={3}>
                                  <MDInput
                                      label="Jersey Number"
                                      fullWidth
                                      value={values.jersey_number}
                                      name="jersey_number"
                                      onChange={(e) => handleNumbers(e)}
                                  />
                                  <ErrorMessage
                                      name="jersey_number"
                                      component="span"
                                      className="error-message"
                                  />
                              </MDBox>
                          </Grid>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                          
                          <Grid item xs={12} lg={3}>
                                  {' '}
                                  <MDBox pb={3} mt={3}>
                                
                                      <DatePicker
                                          views={['year']}
                                          label="Start Year"
                                          // value={startDate}
                                          // value={values?.start_Date}
                                          maxDate={new Date()}
                                          value={startDate ? startDate : values?.start_Date}
                                          onChange={(startValue) => {
                                              setStartDate(startValue);
                                              setShowEndYear(true);
                                              setFieldValue('start_Date', startValue.getFullYear())
                                          }}
                                          renderInput={({ inputProps, ...rest }) => (
                                            <TextField
                                              {...rest}
                                              label="Start Year"
                                              inputProps={{ ...inputProps, readOnly: true }}
                                              sx={{width: '100%'}}
                                            />)}
                                          // renderInput={(params) => <TextField disabled={true} InputProps={{ readOnly: true }}
                                          // {...params} helperText={null} sx={{width: '100%'}} />}
                                    />
                                   <ErrorMessage
                                    name="start_Date"
                                    component="span"
                                    className="error-message"
                                    ></ErrorMessage>
                                    </MDBox>                                          
                              </Grid>
                              <Grid item xs={12} lg={3}>
                                  {' '}
                                  <MDBox pb={3} mt={3}>
                                      <DatePicker
                                          views={['year']}
                                          label="End Year"
                                          variant="static"
                                          value={endDate ? endDate : values?.end_Date }
                                          maxDate={new Date()}
                                          minDate={values?.start_Date}
                                          disabled={showEndYear ? false : true}
                                          
                                          //onChangeRaw={(e) => e.preventDefault()}
                                          onChange={(endValue) => {

                                              setEndDate(endValue);
                                              setFieldValue('end_Date', `${endValue.getFullYear()}`)
                                              //setFieldValue('end_Date', endValue.getFullYear())
                                              //checkDate();
                                          }}
                                          renderInput={({ inputProps, ...rest }) => (
                                            <TextField
                                              {...rest}
                                              label="End Year"
                                              inputProps={{ ...inputProps, readOnly: true }}
                                              sx={{width: '100%'}}
                                            />)}
                                          // renderInput={(params) => <TextField  disabled={true}  {...params} helperText={null} sx={{width: '100%'}} />}
                                      />
                                       <ErrorMessage
                                    name="end_Date"
                                    component="span"
                                    className="error-message"
                                    ></ErrorMessage>
                                  </MDBox>
                              </Grid>
                          </LocalizationProvider>
                        </Grid>
                        {/* New Grid End */}


                        
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3} mt={3}>
                              <MDInput
                                label="Stats"
                                fullWidth
                                value={values.stats}
                                name="stats"
                                onChange={(e) => handleChange(e, 100)}
                              />
                              <ErrorMessage
                                name="stats"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3} mt={3}>
                              <MDInput
                                label="HighLight"
                                fullWidth
                                value={values.highlight}
                                name="highlight"
                                onChange={(e) => handleChange(e,1000)}
                              />
                              <ErrorMessage
                                name="highlight"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                              type="text"
                                label="Instagram"
                                fullWidth
                                value={values.instagram}
                                name="instagram"
                                onChange={(e) => handleInsta(e)}
                              />
                              <ErrorMessage
                                name="instagram"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Default
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={values.is_Default}
                                  label=" Sponsor"
                                  name="is_Default"
                                  onChange={(e) =>
                                    setFieldValue('is_Default', e.target.value)
                                  }
                                  IconComponent={CustomExpandMore}
                                >
                                  <MenuItem value={'true'}>Yes</MenuItem>
                                  <MenuItem value={'false'}>No</MenuItem>
                                </Select>
                                <ErrorMessage
                                  name="is_Default"
                                  component="span"
                                  className="error-message"
                                />
                              </FormControl>
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt={3}
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                            disabled={state?.team?.is_game_start==true?true:false
                            }
                          >
                            {isEditMode ? 'Update' : 'Create captain'}
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
