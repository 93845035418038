import * as actionTypes from '../actions/actionTypes'

const initialState = {
  backgroundList: {},
}

const backgroundlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BACKGROUND_LIST: {
      return {
        ...state,
        backgroundList: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default backgroundlistReducer
