import * as actionTypes from '../actions/actionTypes'

const initialState = {
    mediaUploadData: {},
}

const mediaUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_MEDIA: {
            return {
                ...state,
                mediaUploadData: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

export default mediaUploadReducer
