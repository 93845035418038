import React from 'react'
import MDBox from 'components/MDBox'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { Icon } from '@mui/material'
export default function MDAddIcon(props) {
  return (
    <div>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: 'pointer' }}
      >
        <Link to={props.link}>
          {' '}
          <Icon
            onClick={props.onClick}
            fontSize={props.font}
            color={props.color}
            sx={{ paddingTop: '4px' }}
          >
            <AddIcon />
          </Icon>
        </Link>
      </MDBox>
    </div>
  )
}
