 

// react-router-dom components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function Breadcrumbs({ title }) {
  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h5"
        color={'dark'}
        noWrap
      >
        {title.replace('-', ' ')}
      </MDTypography>
    </MDBox>
  )
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
}

export default Breadcrumbs
