import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDAddIcon from "components/MDAddIcon/Index";
import {  useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import NoData from "components/NoData";
import MDSpinner from "components/MDSpinner";
import { getUserTeamAdminList } from "App/store/actions/users.actions";
import TeamAdminModal from "./TeamAdminModal";

const columns = [
  {
    Header: "Name",
    accessor: "Name",
    width: "40%",
  },
  {
    Header: "Email",
    accessor: "Email",
    width: "40%",
  },
];

function TeamAdminList() {
  const [add_user, setDeleteId] = useState(false);

  const { teamadminList, teamadminLoader } = useSelector(
    (state) => state.userlistReducer
  );

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getUserTeamAdminList(params?.team_id));
  }, []);

  const rows = teamadminList.map((i) => {
    return {
      Name: i.user_name,
      Email: i.email,
      Default: i.is_default ? "YES" : "NO",
    };
  });

  if (teamadminLoader) {
    return <MDSpinner />;
  }

  return (
    <>
      <MDBox pt={1} pb={3}>
        <MDTypography mb={3} variant="h6">
          Team Admin
        </MDTypography>
        {add_user && (
          <TeamAdminModal
            open={add_user}
            handleClose={() => setDeleteId("")}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox p={2}>
              {teamadminList.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rows,
                  }}
                />
              ) : (
                <NoData />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon
            link=""
            color="inherit"
            font="sm"
            onClick={() => setDeleteId(true)}
          />
        </Grid>
      </MDBox>
    </>
  );
}

export default TeamAdminList;
