import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useNavigate ,useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "App/store/actions";
import { connect } from "react-redux";
// import BonusModal from "./BonusDetail/BonusModal";
import MDSpinner from "components/MDSpinner";
import DeleteModal from "components/DeleteModal";
import { toast } from 'react-toastify'
import { Card, ToastHeader } from 'reactstrap'
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import NoData from "components/NoData";
function ActionImage(props) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = React.useState(false);
  const [deleteId, setDeleteId] = useState(false);
const [error,setError]=useState(false)
  let { sportsList: { data: { results } = {} } = {} } = useSelector(
    (state) => state.sportListReducer
  );
   const location = useLocation();
  let { state = {}, pathname } = location;

  const dispatch = useDispatch();
  const [isEditMode, setEditMode] = useState(!!state?.id || false)
  const { sportData } = useSelector((state) => state.sportListReducer);
  const { actionImageList } = useSelector((state) => state.sportListReducer);
const SPORTS_ID= state?.id || localStorage.getItem("sports_id")
  useEffect(() => {
    dispatch(Actions.getSportList((v) => setLoader(v)));
    dispatch(Actions.getActionImageList(SPORTS_ID,(v) => setLoader(v)));
  }, []);

  const removeActionImage = () => {
  
    dispatch(Actions.deleteActionImage(deleteId));
    setDeleteId("");
  };

  // const deleteImage = (id) => {
  //   dispatch(Actions.deleteActionImage(id));

  // };
  const columns = [
    { Header: "Name", accessor: "Name" },
    {Header:"Image",accessor:"Image"},
    { Header: "Action", accessor: "action" },
  ];

  const viewSport = (id) => {
    // viewSport(id)
  
  };

  if (loader) {
    return <MDSpinner />;
  }

 const rowsdata = actionImageList?.data?.map((item, index) => ({
    Name: item?.Name,
    Image:item?.action_image_url,
    action: (
      <MDBox display="flex">
      
        {/* <BonusModal id={item.id} viewSport={viewSport} /> */}
       <Link to={`/edit_action_image/${item?.id}`} state={item}>
          {" "}
          <Icon fontSize="small">edit</Icon>
        </Link>
       

     
        {/* {item?.is_game_start == false|| !item?.is_game_start  ?( */}
           <Icon
           style={{ cursor: "Pointer" }}
           fontSize="small"
           onClick={() => setDeleteId(item?.id)}
         >
           delete
         </Icon>
        {/* ) : <Icon
        style={{ cursor: "Pointer" }}
        fontSize="small"
        onClick={() =>
          toast.error(`You can't delete sport that has game in progress`)
        
        }
      >
        delete
      </Icon>} */}
       
      </MDBox>
    ),
  }));
let values=""
const handleData=()=>{
  let isEditMode=true
  let ss=JSON.parse(localStorage.getItem("sports_state"))
  if(rowsdata?.length !==0){
    let data = { ...sportData }
    dispatch(Actions.SportList(data));
    navigate(isEditMode ? `/edit-action/${state?.id||localStorage.getItem("sports_id")}` : "/add-action", {
      state: { ...state }||{...ss} ,
    });
   
  }
  else{
  
    setError(true)
  }
}

  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
      
        <Grid item xs={12}>
        {deleteId&& (
          <DeleteModal
            text="Are you sure you want to delete this Action Image"
            open={!!deleteId}
            handleClose={() => setDeleteId("")}
            handleAction={() => removeActionImage()}
          />
        )}
       <Box
                    sx={{
                      bgcolor: 'background.paper',
                    }}
                  >
                      <MDBox display="flex" p={3} pb={0}>
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography  variant="h4">
                   Action Image
                    </MDTypography>
                  </MDBox>
          <MDBox p={2}>
               {rowsdata?.length>0 ?(
              <DataTable
                table={{
                  columns: columns,
                  rows: rowsdata,
                }}
                dataButton={true}
                isSorted={false}
              >
                </DataTable>
              
            ):
          <NoData/>
          }
          {error===true?<p className="error-message" style={{textAlign:"center",marginTop:"3%"}}>Please add atleast one Action image</p>:""}
           
        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                      mb={3}
                      mt={3}
                        >
                          
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            size="large"
                            onClick={()=>handleData()}
                            // onClick={()=>navigate("/add-action")}
                //          onClick= {navigate(
                //   isEditMode ? `/edit-action/${state?.id}` : "/add-action",
                //   { state: isEditMode ? 
                //     values : {}
                //    }
                // )}
            
                          >
                            Continue
                          </MDButton>                      
                        </MDBox>
                      
          </MDBox>
          </Box>
        </Grid>
        <MDAddIcon link="/add_action_image" color="inherit" font="sm" />
      </Grid>
    </MDBox>
  );
}
const mapDispatchToProps = {
  getSportList: Actions.getSportList,
  deleteSport: Actions.deleteSport,
};
const mapStateToProps = (state) => ({
  sportsList: state.sportListReducer.sportsList,
  deleteSport: state.sportListReducer.deleteSport,
});
export default connect(mapStateToProps, mapDispatchToProps)(ActionImage);
