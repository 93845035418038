import React, { useState, useEffect, useCallback, useRef } from 'react'
import Grid from '@mui/material/Grid'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card, Icon, TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../App/store/actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import CustomExpandMore from 'components/Expand'
import { ElevatorSharp } from '@mui/icons-material'

const startScore = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20,
]
function AddSport(props) {
  let { state = {}, pathname } = useLocation()
  let navigate = useNavigate()
  const formikRef = useRef(null)

  const [isEditMode, setEditMode] = useState(!!state?.id || false)
  const [loader, setLoader] = React.useState(false)
  let { uniformList = {}, backgroundList = {} } = props

  const dispatch = useDispatch()
  const { createSport_Item } = useSelector(state => state.sportListReducer)

  useEffect(() => {
    dispatch(Actions.getUniformList((v) => setLoader(v)))
    dispatch(Actions.getBackgroundList(() => { }))
  }, [])

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')

      .max(20, 'Max 20 characters are allowed'),
    score_rules: yup
      .string()
      .required('Score rules is required'),
    // .max(200, 'Max 200 characters are allowed'),
    uniform_id: yup.string().required('Uniform is required'),
    starting_score: yup.string().required('Starting score is required'),
    bg_main_gameplay_id: yup.string().required('Background is required'),
    bg_game_prize_id: yup
      .string()
      .required('Background main game prize is required'),
    first_miti_point: yup
      .number()
      .required('Required')
      .test(
        'len',
        'Max 3 digits are allowed',
        // (val) => val?.toString()> 0,
        (val) => val?.toString().length < 4
      )
      .test(
        'digit',
        'Negative Point not allowed ',
        (value) => value?.toString() > -1,
      ),
    second_miti_point: yup
      .string()
      .required('Required')
      .test(
        'len',
        'Max 3 digits are allowed',
        (val) => val?.toString().length < 4
      )
      .test(
        'digit',
        'Negative Point not allowed ',
        (value) => value?.toString() > -1,
      ),
    third_miti_point: yup
      .string()
      .required('Required')
      .test(
        'len',
        'Max 3 digits are allowed',
        (val) => val?.toString().length < 4
      )
      .test(
        'digit',
        'Negative Point not allowed ',
        (value) => value?.toString() > -1,
      ),
    fourth_miti_point: yup
      .number()
      .required('Required')
      .test(
        'len',
        'Max 3 digits are allowed',
        (val) => val?.toString().length < 4
      )
      .test(
        'digit',
        'Negative Point not allowed ',
        (value) => value?.toString() > -1,
      ),
  })

  let user = JSON.parse(localStorage.getItem('sports_value'));
  localStorage.setItem("dd",state?.id)
  localStorage.setItem("sports_state", JSON.stringify(state))
  const initialValues = {
    id: state?.id || null,
    name: state?.name || user?.name || '',
    score_rules: state?.score_rules || user?.score_rules||'',
    uniform_id: state?.uniform_id ||user?.uniform_id|| '',
    starting_score: state?.starting_score || user?.starting_score||'0',
    bg_main_gameplay_id: state?.bg_main_gameplay_id || user?.bg_main_gameplay_id ||'',
    bg_game_prize_id: state?.bg_game_prize_id || user?.bg_game_prize_id ||'',
    first_miti_point: state?.first_miti_point || user?.first_miti_point||'0',
    second_miti_point: state?.second_miti_point || user?.second_miti_point||'0',
    third_miti_point: state?.third_miti_point || user?.third_miti_point||'0',
    fourth_miti_point: state?.fourth_miti_point || user?.fourth_miti_point||'0',
    // bonus: state?.bonuses || [],
    actions: state?.actions || [],
  }
  const handleNumbers = (e, l) => {
    let { name, value } = e.target;
    formikRef.current.setFieldValue(name, value);
  };

  const handleChange = (e) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(name, value)
  }
  const onSubmit = (values) => {
    if (isEditMode) {
      localStorage.setItem("sports_id",state?.id) 
      navigate('/action-image', { state: values })
    }
    else {
      delete values['actions']
      dispatch(Actions.createSports(values))
      navigate(
        '/action-image',
        { state: {} }
      )
      localStorage.setItem("sports_value", JSON.stringify(values))
    }
    dispatch(Actions.SportList(values))



  }

 
  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDTypography mb={3} variant="h4">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '5px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    {isEditMode ? 'Edit Sport' : 'Add New Sport'}
                  </MDTypography>
                  <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      onSubmit(values)
                    }}

                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form >
                       
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3}>
                              <MDInput
                                label="Sport name"
                                fullWidth
                                autoComplete="off"
                                value={values.name}
                                name="name"
                                onChange={(e) =>
                                  handleChange(e)
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                                label="Score rules"
                                autoComplete="off"
                                value={values.score_rules}
                                name="score_rules"
                                fullWidth
                                rows={5}
                                multiline
                                onChange={(e) =>
                                  handleChange(e)
                                }
                              />
                              <ErrorMessage
                                name="score_rules"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Uniform
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={values.uniform_id || ''}
                                  label="Uniform"
                                  name="uniform_id"
                                  onChange={(e) =>
                                    setFieldValue(e.target.name, e.target.value)
                                  }
                                  IconComponent={CustomExpandMore}
                                >
                                  {uniformList?.data?.data?.map(
                                    (item, index) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        id={item.id}
                                      >
                                        {item.image_name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="uniform_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Background main gameplay
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="bg_main_gameplay_id"
                                  value={values.bg_main_gameplay_id || ''}
                                  onChange={(e) =>
                                    setFieldValue(e.target.name, e.target.value)
                                  }
                                  IconComponent={CustomExpandMore}
                                  label="Background main gameplay"
                                >
                                  {backgroundList?.data?.data?.map(
                                    (item, index) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        id={item.id}
                                      >
                                        {item.image_name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="bg_main_gameplay_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Background main game prize
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label=" Background main game prize"
                                  name="bg_game_prize_id"
                                  value={values.bg_game_prize_id || ''}
                                  onChange={(e) =>
                                    setFieldValue(e.target.name, e.target.value)
                                  }
                                  IconComponent={CustomExpandMore}
                                >
                                  {backgroundList?.data?.data?.map(
                                    (item, index) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        id={item.id}
                                      >
                                        {item.image_name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="bg_game_prize_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                         
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Starting Score
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Starting Score"
                                  name="starting_score"
                                  value={values.starting_score}
                                  onChange={(e) =>
                                    setFieldValue(e.target.name, e.target.value)
                                  }
                                  IconComponent={CustomExpandMore}
                                >
                                  {startScore?.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="starting_score"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Paper variant="outlined">
                          <MDBox p={3}>
                            <MDTypography variant="h4">
                              Miti Points
                            </MDTypography>
                          </MDBox>
                       
                          <MDBox pb={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={3}>
                                <MDBox p={3} mt={1}>
                                  {/* 1st{' '} */}

                                  <MDInput
                                    label="1st"
                                    autoComplete="off"
                                    value={values?.first_miti_point}
                                    name="first_miti_point"
                                    onChange={(e) => handleNumbers(e)}
                                    type="number"

                                  // placeholder="0"
                                  ></MDInput>
                                  <ErrorMessage
                                    name="first_miti_point"
                                    component="p"
                                    className="error-message"
                                  // style={{marginLeft:"14%"}}

                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={3}>
                                <MDBox p={3} mt={1}>
                                  {/* 2nd{' '} */}
                                  <MDInput
                                    label="2nd"
                                    type="number"
                                    autoComplete="off"
                                    value={values.second_miti_point}
                                    name="second_miti_point"
                                    // placeholder="0"
                                    onChange={(e) => handleNumbers(e)}
                                  ></MDInput>
                                  <ErrorMessage
                                    name="second_miti_point"
                                    component="p"
                                    className="error-message"
                                  // style={{marginLeft:"14%"}}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={3}>
                                <MDBox p={3} mt={1}>
                                  {/* 3rd{' '} */}
                                  <MDInput
                                    label="3rd"
                                    type="number"
                                    autoComplete="off"
                                    name="third_miti_point"
                                    value={values.third_miti_point}
                                    onChange={(e) => handleNumbers(e)}
                                  // placeholder="0"
                                  ></MDInput>
                                  <ErrorMessage
                                    name="third_miti_point"
                                    component="p"
                                    className="error-message"
                                  // style={{marginLeft:"14%"}}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={3}>
                                <MDBox p={3} mt={1}>
                                  {/* 4th{' '} */}
                                  <MDInput
                                    label="4th"
                                    type="number"
                                    autoComplete="off"
                                    value={values.fourth_miti_point}
                                    name="fourth_miti_point"
                                    onChange={(e) => handleNumbers(e)}
                                  // placeholder="0"
                                  ></MDInput>
                                  <ErrorMessage
                                    name="fourth_miti_point"
                                    component="p"
                                    className="error-message"
                                  //  style={{marginLeft:"14%"}}
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Paper>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt={3}
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            size="large"
                          >
                            Continue
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
                <ToastContainer />
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
const mapDispatchToProps = {
  getUniformList: Actions.getUniformList,
  getBackgroundList: Actions.getBackgroundList,
  SportList: Actions.SportList,
}
const mapStateToProps = (state) => ({
  uniformList: state.uniformlistReducer.uniformList,
  backgroundList: state.backgroundlistReducer.backgroundList,
  sportData: state.sportListReducer.sportData,
})
export default connect(mapStateToProps, mapDispatchToProps)(AddSport)
