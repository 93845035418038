import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import S3 from 'react-aws-s3'
import MDBox from 'components/MDBox'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card, Icon } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as Actions from '../../../App/store/actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { config } from "utils/s3config";
function AddUniform() {
  const date = new Date()
  const [url, setUrl] = React.useState('')
  const [newLaunches, setNewLaunches] = useState(false)
  const [adminList, setAdminList] = useState([{}])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAddAdmin = () => {
    setAdminList([...adminList, {}])
  }
  window.Buffer = window.Buffer || require('buffer').Buffer
 
  const ReactS3Client = new S3(config)
  const uploadImage = (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png|svg)$/)) {
      document.getElementById('input-image').value = null
      toast.error('Please select image only.')
    } else {
      setUrl(file)
    }
  }
  
  const submitImage = async (e) => {
    try {
      // let img = await ReactS3Client.uploadFile(url, config);
      let img = await ReactS3Client.uploadFile(
        url,
        url.name.replace(/\s/g, '-')
        // + '_' + moment().format('YYYYMMDDHHMMSS')
      )

      dispatch(Actions.mediaUpload(img.key))
      setTimeout(() => {
        navigate('/uniforms')
      }, 3000)
    } catch (error) {
      if (url == '') {
        toast.error('Please Select the Uniform')
      }
     
    }
  }

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    <MDTypography mb={3} variant="h4">
                      Add Uniform
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={3}>
                    <Paper variant="outlined" p={3}>
                      <MDBox p={3}>
                        <MDBox pb={3}>
                          <MDTypography variant="h6" verticalAlign="middle">
                            Upload Uniform
                          </MDTypography>
                        </MDBox>

                        <MDBox pb={3}>
                          <input
                            type="file"
                            onChange={(e) => uploadImage(e.target.files[0])}
                            accept="image/*"
                            className="input-image"
                            id="input-image"
                          />
                        </MDBox>
                      </MDBox>
                    </Paper>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={3}
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="large"
                      onClick={submitImage}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
                <ToastContainer />
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
const mapDispatchToProps = {
  mediaUpload: Actions.mediaUpload,
}

const mapStateToProps = (state) => ({
  mediaUploadData: state.mediaUploadReducer.mediaUploadData,
})
export default connect(mapStateToProps, mapDispatchToProps)(AddUniform)
