import * as actionTypes from '../actions/actionTypes'

const initialState = {
  uniformList: {},
}

const uniformlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UNIFORM_LIST: {
      return {
        ...state,
        uniformList: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default uniformlistReducer
