import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'

import MDBox from 'components/MDBox'


// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import Icon from '@mui/material/Icon'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Actions from '../../../App/store/actions'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

function ActionButton(props) {
  const [adminList, setAdminList] = useState([{}])

  const handleAddAdmin = () => {
    setAdminList([...adminList, {}])
  }

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(Actions.getBonusDetail(params.id))
  }, [])
  
  const actionButton = props?.bonusData?.data?.actions

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox p={3} display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      Action Button Details
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2}>
                    {actionButton?.map((item) => (
                      <Grid item xs={12} lg={4}>
                        {' '}
                        <MDBox pb={3} display="flex">
                          <Link
                            to={`/edit-action/${item.sports_id}`}
                            color="white"
                            state={{
                              actions: actionButton.filter(
                                (i) => i.id === item.id
                              ),
                              isaction: true,
                            }}
                            className="action-button"
                          >
                            {' '}
                            <MDButton
                              variant="gradient"
                              style={{
                                background: item?.color?.color_name,
                                color: '#fff',
                              }}
                              size="large"
                              fullWidth
                              onClick={() =>
                                navigate(`/edit-action/${item.sports_id}`, {
                                  state: {
                                    actions: actionButton.filter(
                                      (i) => i.id === item.id
                                    ),
                                    isaction: true,
                                  },
                                })
                              }
                            >
                            {item.name} &nbsp; &nbsp;{' '}
                              <Icon style={{ color: '#fff' }}>edit</Icon>
                            </MDButton>
                          </Link>
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
const mapDispatchToProps = {
  getBonusDetail: Actions.getBonusDetail,
}
const mapStateToProps = (state) => ({
  bonusData: state.sportListReducer.bonusData,
})
export default connect(mapStateToProps, mapDispatchToProps)(ActionButton)
