import * as actionTypes from "../actions/actionTypes";

const initialState = {
  teamList: [],
  captains: [],
  sponsorList: [],
  prizeList: [],
  bonusList: [],
  teamLoader: false,
  teamDetailsLoader: false,
  teamDetails: {},
  prizeTypes: [],
  customizeDetails: {},
  newPlayer:[],
  customizeDetailsLoader: false,
  sponsorListLoader: false,
  gamePrizeLoader: false,
  captainLoader: false,
  visitorList: [],
  visitorListLoader: false,
  visit:[]
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEAM_LIST: {
      return {
        ...state,
        teamList: action.payload,
      };
    }
    case actionTypes.SET_TEAM_DETAILS: {
      let { users = [] } = action?.payload;
      users?.map((i) => i.password = i?.password?.slice(0, 10));
      return {
        ...state,
        teamDetails: action.payload,
      };
    }
    case actionTypes.SET_TEAM_DETAILS_LOADER: {
      return {
        ...state,
        teamDetailsLoader: action.payload,
      };
    }
    case actionTypes.SET_CAPTAINS_LIST: {
      return {
        ...state,
        captains: action.payload,
      };
    }
    case actionTypes.SET_TEAM_LIST_LOADER: {
      return {
        ...state,
        teamLoader: action.payload,
      };
    }
    case actionTypes.SET_SPONSOR_LIST: {
      return {
        ...state,
        sponsorList: action.payload,
      };
    }
    case actionTypes.GET_VISITOR_LIST: {
      return {
        ...state,
        visitorList: action.payload,
      };
    }
    case actionTypes.VISITOR_LIST: {
      return {
        ...state,
        visit: action.payload,
      };
    }
    case actionTypes.SET_PRIZE_LIST: {
      return {
        ...state,
        prizeList: action.payload,
      };
    }
    case actionTypes.SET_BONUS_LIST: {
      return {
        ...state,
        bonusList: action.payload,
      };
    }

    case actionTypes.SET_PRIZE_TYPES_DATA: {
      return {
        ...state,
        prizeTypes: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMIZE_DETAILS: {
      return {
        ...state,
        customizeDetails: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMIZE_DETAILS_LOADER: {
      return {
        ...state,
        customizeDetailsLoader: action.payload,
      };
    }

    case actionTypes.SET_SPONSOR_LIST_LOADER: {
      return {
        ...state,
        sponsorListLoader: action.payload,
      };
    }

    case actionTypes.SET_VISITOR_LIST_LOADER: {
      return {
        ...state,
        visitorListLoader: action.payload,
      };
    }
    case actionTypes.SET_GAME_PRIZE_LIST_LOADER: {
      return {
        ...state,
        gamePrizeLoader: action.payload,
      };
    }
    case actionTypes.SET_CAPTAIN_LIST_LOADER: {
      return {
        ...state,
        captainLoader: action.payload,
      };
    }

    case actionTypes.SET_NEW_PLAYER: {
      return {
        ...state,
        newPlayer: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default teamReducer;
