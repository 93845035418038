import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card, Icon } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../App/store/actions'
import { connect } from 'react-redux'
import store from '../../../../App/store'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { updateAction } from '../../../../App/store/actions'
import CustomExpandMore from 'components/Expand'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { TextareaAutosize } from '@material-ui/core'

function AddAction(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [loader, setLoader] = React.useState(false);
  //  const [arrs, setArr] = useState([]);
  let { colorData = {} } = props

  const location = useLocation()

  const { state } = location
  const isaction = !!state.isaction || false
  const edited=JSON.parse(localStorage.getItem("sports_state"))
  const formikRef = useRef(null)

  const isEditMode = !!state.name ||!!edited?.name || false

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mitiValue = ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const Time = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]

  const Late = [
    -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#fff',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
  }
  const SPORTS_ID=state?.id || localStorage.getItem("sports_id")
  const handleAction = () => {
    formikRef.current.values.push({
      name: '',
      is_home_team: true,
      description: '',
      miti_point: '',
      ply_by_ply_description: '',
      point_value: '',
      is_end_turn: false,
      is_win_trigger: false,
      win_streak_name: '',
      time: '',
      win_description: '',
      late_game_point: '',
      add_more: false,
      is_hold: true,
      color_id: '',
      is_release: false,
      actionImage_id:''
    })
    formikRef.current.setFieldValue(formikRef.current.values)
  }
  const handleMoreAction = () => {
    formikRef.current.values.push({
      name: '',
      is_home_team: true,
      description: '',
      miti_point: '',
      ply_by_ply_description: '',
      point_value: '',
      is_end_turn: false,
      is_win_trigger: false,
      win_streak_name: '',
      time: '',
      win_description: '',
      late_game_point: '',
      add_more: false,
      is_hold: true,
      color_id: '',
      actionImage_id:"",
      is_release: false,
    })
    formikRef.current.setFieldValue(formikRef.current.values)
  }
  const { sportData, actionList,actionImageList,createSport_Item } = useSelector(
    (state) => state.sportListReducer
  )
  useEffect(() => {
    dispatch(Actions.colorList())
   if(isaction){
      dispatch(Actions.getActionImageList( state?.actions[0]?.sports_id,(v) => setLoader(v)));
    }
    else{
      dispatch(Actions.getActionImageList(SPORTS_ID||edited?.id,(v) => setLoader(v)));
    }
  }, [])

  const validationSchema = yup.array().of(
    yup.object({
      name: yup
        .string()
        .required('Name is required')
        .max(20, 'Max 20 characters are allowed'),
      is_home_team: yup.string().required('home team is required'),
      actionImage_id:yup.string().nullable().required('Action Image is required'),
      description: yup
        .string()
        // .required('Description is required')
        .max(150, 'Max 150 characters are allowed'),
      ply_by_ply_description: yup
        .string()
        .required('Play By Play description is required')
        .max(200, 'Max 50 characters are allowed'),
      point_value: yup
        .string()
        .required('Point value is required')
        .test(
          'len',
          'Max 3 digits are allowed',
          (val) => val?.toString().length < 4
        ),
      is_end_turn: yup.boolean(),
      is_win_trigger: yup.boolean(),

      // // win_streak_name: yup.number().when('is_win_trigger', {
      //   // is: true,
      //   // then: yup.number().required('Win bonus trigger is required').moreThan(0,'Win bonus trigger is required'),
      // }),
      miti_point: yup.number().when('is_win_trigger', {
        is: true,
        then: yup
          .number()
          .required('Miti point is required')
          .moreThan(0, 'Miti point is required'),
      }),
      win_description: yup.string().when('is_win_trigger', {
        is: true,
        then: yup.string().required('Win description is required'),
      }),

      time: yup.number().when('is_win_trigger', {
        is: true,
        then: yup
          .number()
          .required('Time is required')
          .moreThan(0, 'Time is required'),
      }),
      win_streak_name: yup.string().when('is_win_trigger', {
        is: true,
        then: yup.string().required('Win streak name is required'),
      }),

      
      color_id: yup.string().required('Color is required'),
      late_game_point: yup.string().required('Late game point is required'),
    })
  )

  const initialValues = state?.actions||edited?.actions || [
    {
      name: '',
      is_home_team: true,
      description: '',
      ply_by_ply_description: '',
      point_value: '',
      is_end_turn: false,
      is_win_trigger: false,
      win_streak_name: '',
      time: '',
      miti_point: '',
      win_description: '',
      late_game_point: '',
      add_more: true,
      is_release: false,
      is_hold: false,
      color_id: '',
      actionImage_id:''
    },
  ]

  const handleNumbers = (e, i) => {
    let { name, value } = e.target
    formikRef.current.values[i][name] = value
      // .replace(/[^0-9]/gi, '')
      .slice(0, 3)
    formikRef.current.setFieldValue(formikRef.current.values)
    // }
  }

  const handleChange = (e, i, l) => {
    let { name, value } = e.target
  
    formikRef.current.values[i][name] = value
    formikRef.current.setFieldValue(formikRef.current.values)
    //  setArr({...name, name});
  }

  const handleSpecial = (e, i, l) => {
    let { name, value } = e.target
    formikRef.current.values[i][name] = value
      // .replace(/[^a-z0-9\s]/gi, '')
      .slice(0, l)
    formikRef.current.setFieldValue(formikRef.current.values)
  }

  const handleChecked = (e, i) => {
    let { name, checked } = e.target
    formikRef.current.values[i][name] = checked
    formikRef.current.setFieldValue(formikRef.current.values)
    //setArr(formikRef.current.values[i][name]);
    // setArr({...name, name});
  }

  const handleCheckedHold = (e, i) => {
    let { name, checked } = e.target
    formikRef.current.values[i][name] = checked
    formikRef.current.setFieldValue(formikRef.current.values)
  }
  const handleCheckedReleaseHold = (e, i) => {
    let { name, checked } = e.target
    formikRef.current.values[i][name] = checked
    formikRef.current.setFieldValue(formikRef.current.values)
  }
  // const handleCheckedAddMore=(e,i)=>{
  //   let { name, checked } = e.target
  //   formikRef.current.values[i][name] = checked
  //   formikRef.current.setFieldValue(formikRef.current.values)
  // }
  const handleAddAction = (e, i) => {
    handleOpen()
  }
  const actionButton = (values) => {
    dispatch(Actions.ActionList())
    
  }
  const onSubmit = (values) => {
   
    values.map((i) => {
      delete i['win_bonus_trigger']
      delete i['sports_id']
      delete i['status']
      delete i['createdAt']
      delete i['updatedAt']
      if (!i['is_win_trigger']) {
        i['win_streak_name'] = ''
        i['miti_point'] = 0
        i['time'] = 0
        i['win_description'] = ''
      }
    })

   
 
    if (isaction) {
      dispatch(updateAction({ ...values[0], navigate }))
    }
    else if(isEditMode){
      let data = {
        ...sportData, 
        actions: values,
        sports_id:state?.id ||localStorage.getItem("sports_id") }
      dispatch(Actions.editSport({ ...data, navigate} ))
    }
     else {
     let data = {
      bonus:[],
      actions: values,
      status:"ACTIVE",
      sport_id:localStorage.getItem("sports_id"), }
         dispatch(Actions.createSport( data, navigate ))
    }
  }
 // const removeAction = (i) => {
  //   formikRef.current.values.splice(i, 1)
  //   formikRef.current.setFieldValue(formikRef.current.values)
  //   toast.success("Action Deleted")
  // }
  const deleted = (index, action_id, sports_id) => {
   if(sports_id,action_id){
    formikRef.current.values.splice(index, 1)
    formikRef.current.setFieldValue(formikRef.current.values)
    dispatch(Actions.deleteAction(sports_id, action_id))
   }
   else{
    formikRef.current.values.splice(index, 1)
   formikRef.current.setFieldValue(formikRef.current.values)
   }
    
  }
  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => (isaction ? navigate(-2) : navigate(-1))}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      {isEditMode
                        ? 'Edit Action Button'
                        : isaction
                        ? 'Update Action Button'
                        : 'Add Action Button'}
                    </MDTypography>
                  </MDBox>
                  {!isaction && (
                    <MDBox p={3} display="flex" justifyContent="flex-end">
                      <MDButton color="info" onClick={handleAction}>
                        Add More Action
                      </MDButton>
                    </MDBox>
                  )}
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form>
                        {values.map((item, index) => (
                          <>
                            <Grid container spacing={2}>
                              {index > 0 && (
                                <Icon
                                  style={{
                                    marginLeft: 'auto',
                                    marginTop: '5%',
                                    cursor: 'pointer',
                                    float: 'right',
                                  }}
                                  onClick={() =>
                                    deleted(index, item?.id, item?.sports_id)
                                  }
                                >
                                  delete
                                </Icon>
                              )}
                              <Grid item xs={12} lg={12} className="data">
                                {' '}
                                <MDBox pb={3}>
                                  <MDInput
                                    label="Name"
                                    fullWidth
                                    autoComplete="off"
                                    value={values[index].name}
                                    name="name"
                                    onChange={(e) =>
                                      handleSpecial(e, index, 20)
                                    }
                                  />
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.name}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <MDBox display="flex" justifyContent="space-between">
                                <MDBox>
                                  <MDTypography>Home Team</MDTypography>
                                </MDBox>
                              
                                <MDBox
                                  mt={0.5}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Switch
                                    checked={values[index].is_home_team}
                                    name="is_home_team"
                                    onChange={(e) => handleChecked(e, index)}
                                  />
                                </MDBox>
                              </MDBox>
                            <>
                              {/* {values[index]?.is_home_team && ( */}
                              <MDBox pb={3} className="in">
                                  <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                Action Images
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={values[index]?.actionImage_id}
                                  label="Action Images"
                                  name="actionImage_id"
                                  onChange={(e) => handleChange(e, index)}
                                     
                                  IconComponent={CustomExpandMore}
                                >
                                
                                {actionImageList?.data?.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            id={item.id}
                                          >
                                            {item.Name}
                                          </MenuItem>
                                        )
                                      )}   
                                </Select>
                                {errors[index] && touched[index] && (
                                  <span className="error-message">
                                    {errors[index]?.actionImage_id}
                                  </span>
                                )} 
                              </FormControl>
                                {/* <MDInput
                                  label="Description"
                                  multiline
                                  rows={5}
                                  autoComplete="off"
                                  fullWidth
                                  value={values[index].description}
                                  name="description"
                                  onChange={(e) => handleSpecial(e, index, 150)}
                                />
                                {errors[index] && touched[index] && (
                                  <span className="error-message">
                                    {errors[index]?.description}
                                  </span>
                                )} */}
                              </MDBox>
                              {/* )} */}
                            </>

                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                {' '}
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    autoComplete="off"
                                    label="Play by play description"
                                    value={values[index].ply_by_ply_description}
                                    name="ply_by_ply_description"
                                    onChange={(e) =>
                                      handleSpecial(e, index, 50)
                                    }
                                  />
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.ply_by_ply_description}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {' '}
                                
                                <MDBox pb={3}>
                                  <MDInput
                                    label="Point Value"
                                    fullWidth
                                    type="number"
                                    autoComplete="off"
                                    value={values[index].point_value}
                                    name="point_value"
                                    onChange={(e) => handleNumbers(e, index)}
                                  />
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.point_value}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>

                            {/* <MDBox display="flex" justifyContent="space-between">
                                <MDBox>
                                  <MDTypography>Hold</MDTypography>
                                </MDBox>
                              
                                <MDBox
                                  mt={0.5}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Switch
                                    checked={values[index].is_hold}
                                    name="is_hold"
                                    onChange={(e) =>
                                      handleCheckedHold(e, index)
                                    }
                                  />
                                </MDBox>
                             </MDBox>
                             <MDBox display="flex" justifyContent="space-between">
                             <MDBox>
                                  <MDTypography>Release Hold</MDTypography>
                                </MDBox>
                              <MDBox
                                item
                                xs={2}
                                lg={1}
                                style={{ paddingLeft: '0px' }}
                              >
                                <MDBox
                                  mt={0.5}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Switch
                                    checked={values[index].is_release}
                                    name="is_release"
                                    disabled={
                                      values[index].is_hold == true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleCheckedReleaseHold(e, index)
                                    }
                                  />
                                </MDBox>
                              </MDBox>
                              </MDBox> */}
                              <MDBox display="flex" justifyContent="space-between">
                              
                                <MDBox>
                                  <MDTypography>End Turn</MDTypography>
                                </MDBox>
                            
                                <MDBox
                                  mt={0.5}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Switch
                                    checked={values[index].is_end_turn}
                                    name="is_end_turn"
                                    onChange={(e) => handleChecked(e, index)}
                                  />
                                </MDBox>
                             </MDBox>
                            {values[index]?.is_home_team ? (
                              <Paper variant="outlined">
                                <MDBox p={3}>
                                <MDBox display="flex" justifyContent="space-between">
                              
                              <MDBox>
                                    
                                        <MDTypography variant="h5">
                                          Win Streak Bonus Trigger
                                        </MDTypography>
                                      </MDBox>
                                    

                                
                                      <MDBox
                                        mt={0.5}
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Switch
                                          checked={values[index].is_win_trigger}
                                          name="is_win_trigger"
                                          onChange={(e) =>
                                            handleChecked(e, index)
                                          }
                                        />
                                      </MDBox>
                                      </MDBox>
                                  <MDBox
                                    mt={0.5}
                                    display="flex"
                                    alignItems="center"
                                  ></MDBox>
                                  {values[index]?.is_win_trigger && (
                                    <>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                          <MDBox pb={3}>
                                            <MDInput
                                              label="Win Streak Bonus trigger"
                                              fullWidth
                                              autoComplete="off"
                                              value={
                                                values[index].win_streak_name
                                              }
                                              name="win_streak_name"
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                            />
                                            {errors[index] &&
                                              touched[index] && (
                                                <span className="error-message">
                                                  {errors[index]?.win_streak_name}
                                                </span>
                                              )}
                                          </MDBox>
                                          <MDBox pb={3}>
                                            {/* <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">
                                                Win Streak Bonus trigger
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Win Streak Bonus trigger"
                                                value={
                                                  values[index]
                                                    .win_streak_name
                                                }
                                                type="number"
                                                name="win_streak_name"
                                                onChange={(e) =>
                                                  handleChange(e, index)
                                                }
                                                IconComponent={CustomExpandMore}
                                              >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                              </Select>
                                            </FormControl> */}
                                            
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                          {' '}
                                          <MDBox pb={3}>
                                            <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">
                                                Miti point value
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label=" Miti point value"
                                                type="number"
                                                value={
                                                  values[index]?.miti_point
                                                }
                                                name="miti_point"
                                                onChange={(e) =>
                                                  handleChange(e, index)
                                                }
                                                // required
                                                IconComponent={CustomExpandMore}
                                              >
                                                {mitiValue?.map((item) => (
                                                  <MenuItem value={item}>
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                            {errors[index] &&
                                              touched[index] && (
                                                <span className="error-message">
                                                  {errors[index]?.miti_point}
                                                </span>
                                              )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                          <MDBox pb={3}>
                                            <MDInput
                                              label="Description"
                                              autoComplete="off"
                                              fullWidth
                                              value={
                                                values[index].win_description
                                              }
                                              name="win_description"
                                              onChange={(e) =>
                                                handleSpecial(e, index, 50)
                                              }
                                            />
                                            {errors[index] &&
                                              touched[index] && (
                                                <span className="error-message">
                                                  {
                                                    errors[index]
                                                      ?.win_description
                                                  }
                                                </span>
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                          {' '}
                                          <MDBox pb={3}>
                                            <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">
                                                Time
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Time"
                                                type="number"
                                                value={values[index].time}
                                                name="time"
                                                onChange={(e) =>
                                                  handleChange(e, index)
                                                }
                                                IconComponent={CustomExpandMore}
                                              >
                                                {Time?.map((item) => (
                                                  <MenuItem value={item}>
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                            {errors[index] &&
                                              touched[index] && (
                                                <span className="error-message">
                                                  {errors[index]?.time}
                                                </span>
                                              )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                </MDBox>
                              </Paper>
                            ) : (
                              ''
                            )}

                            <Grid container spacing={2} mt={2}>
                              <Grid item xs={12} lg={12}>
                                {' '}
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Late Game Point
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Late Game Point"
                                      value={values[index].late_game_point}
                                      name="late_game_point"
                                      onChange={(e) => handleChange(e, index)}
                                      IconComponent={CustomExpandMore}
                                    >
                                      {Late?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.late_game_point}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={0.5}>
                              <Grid item xs={12} lg={12}>
                                {' '}
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Color
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={values[index].color_id}
                                      label="Color"
                                      name="color_id"
                                      onChange={(e) => handleChange(e, index)}
                                      IconComponent={CustomExpandMore}
                                    >
                                      {colorData?.data?.results?.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            id={item.id}
                                          >
                                            {item.color_name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.color_id}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>

                            {/* <MDBox display="flex" justifyContent="flex-end">
                              <MDButton
                                color="info"
                                onClick={() => handleAddAction(item.image_url)}
                              >
                                Add More
                              </MDButton>
                            </MDBox> */}
                          </>
                        ))}

                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                          >
                            {isEditMode ? "Update Sport": isaction?'Update Action':"Create Sport"}
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                  <Modal
                    open={open}
                    onClose={() => handleClose()}
                    // style={{ overflow: "scroll"}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="modal-scroll" sx={style}>
                      {/* <Box sx={{ overflow: "scroll", maxHeight: "90%"}}> */}
                      <MDBox display="flex" justifyContent="space-between">
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Add Action
                        </Typography>

                        <MDBox>
                          <Icon
                            onClick={() => handleClose()}
                            sx={{ cursor: 'pointer' }}
                          >
                            close
                          </Icon>
                        </MDBox>
                      </MDBox>
                      <MDBox p={2}>
                        <MDBox>
                          <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => actionButton(values)}
                          >
                            {({ errors, touched, values, setFieldValue }) => (
                              <Form>
                                {values.map((item, index) => (
                                  <>
                                    <Grid container spacing={2}>
                                      {index > 0 && (
                                        <Icon
                                          style={{
                                            marginLeft: 'auto',
                                            marginTop: '5%',
                                            cursor: 'pointer',
                                            float: 'right',
                                          }}
                                          onClick={() =>
                                            deleted(
                                              item,
                                              item?.id,
                                              item?.sports_id
                                            )
                                          }
                                        >
                                          delete
                                        </Icon>
                                      )}
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        className="data"
                                      >
                                        {' '}
                                        <MDBox pb={3}>
                                          <MDInput
                                            label="Name"
                                            fullWidth
                                            disabled={true}
                                            autoComplete="off"
                                            value={values[index].name}
                                            name="name"
                                            onChange={(e) =>
                                              handleSpecial(e, index, 20)
                                            }
                                          />
                                          {errors[index] && touched[index] && (
                                            <span className="error-message">
                                              {errors[index]?.name}
                                            </span>
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                   <MDBox display="flex" justifyContent="space-between">
                                        <MDBox>
                                          <MDTypography>Home Team</MDTypography>
                                        </MDBox>
                                     

                                        <MDBox
                                          mt={0.5}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Switch
                                            checked={values[index].is_home_team}
                                            name="is_home_team"
                                            disabled={true}
                                            onChange={(e) =>
                                              handleChecked(e, index)
                                            }
                                          />
                                        </MDBox>
                                      </MDBox>
                            
                                    <>
                                      {/* {values[index]?.is_home_team && ( */}
                                      <MDBox pb={3}>
                                        <MDInput
                                          label="Description"
                                          autoComplete="off"
                                          disabled={true}
                                          fullWidth
                                          value={values[index].description}
                                          name="description"
                                          onChange={(e) =>
                                            handleSpecial(e, index, 50)
                                          }
                                        />
                                        {errors[index] && touched[index] && (
                                          <span className="error-message">
                                            {errors[index]?.description}
                                          </span>
                                        )}
                                      </MDBox>
                                      {/* )} */}
                                    </>

                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {' '}
                                        <MDBox pb={3}>
                                          <MDInput
                                            fullWidth
                                            autoComplete="off"
                                            disabled={true}
                                            label="Play by play description"
                                            value={
                                              values[index]
                                                .ply_by_ply_description
                                            }
                                            name="ply_by_ply_description"
                                            onChange={(e) =>
                                              handleSpecial(e, index, 100)
                                            }
                                          />
                                          {errors[index] && touched[index] && (
                                            <span className="error-message">
                                              {
                                                errors[index]
                                                  ?.ply_by_ply_description
                                              }
                                            </span>
                                          )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {' '}
                                        <MDBox pb={3}>
                                          <MDInput
                                            label="Point Value"
                                            fullWidth
                                            autoComplete="off"
                                            value={values[index].point_value}
                                            name="point_value"
                                            onChange={(e) =>
                                              handleNumbers(e, index)
                                            }
                                          />
                                          {errors[index] && touched[index] && (
                                            <span className="error-message">
                                              {errors[index]?.point_value}
                                            </span>
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={9} lg={11}>
                                        <MDBox>
                                          <MDTypography>Hold</MDTypography>
                                        </MDBox>
                                      </Grid>

                                      <Grid item xs={2} lg={1}>
                                        <MDBox
                                          mt={0.5}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Switch
                                            checked={values[index].is_hold}
                                            name="is_hold"
                                            onChange={(e) =>
                                              handleCheckedHold(e, index)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={9} lg={11}>
                                        <MDBox>
                                          <MDTypography>
                                            Release Hold
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>

                                      <Grid item xs={2} lg={1}>
                                        <MDBox
                                          mt={0.5}
                                          display="flex"
                                          alignItems="center"
                                          // style={{paddingLeft:"0px"}}
                                        >
                                          <Switch
                                            checked={values[index].is_release}
                                            name="is_release"
                                            onChange={(e) =>
                                              handleCheckedReleaseHold(e, index)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={9} lg={11}>
                                        <MDBox>
                                          <MDTypography>End Turn</MDTypography>
                                        </MDBox>
                                      </Grid>

                                      <Grid item xs={2} lg={1}>
                                        <MDBox
                                          mt={0.5}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Switch
                                            checked={values[index].is_end_turn}
                                            name="is_end_turn"
                                            onChange={(e) =>
                                              handleChecked(e, index)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    {/* {values[index]?.is_home_team ? ( */}
                                    <Paper variant="outlined">
                                      <MDBox p={3}>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} lg={11}>
                                            <MDBox>
                                              <MDTypography variant="h5">
                                                Win Streak Bonus Trigger
                                              </MDTypography>
                                            </MDBox>
                                          </Grid>

                                          <Grid item xs={12} lg={1}>
                                            <MDBox
                                              mt={0.5}
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Switch
                                                checked={
                                                  values[index].is_win_trigger
                                                }
                                                name="is_win_trigger"
                                                onChange={(e) =>
                                                  handleChecked(e, index)
                                                }
                                              />
                                            </MDBox>
                                          </Grid>
                                        </Grid>
                                        <MDBox
                                          mt={0.5}
                                          display="flex"
                                          alignItems="center"
                                        ></MDBox>
                                        {values[index]?.is_win_trigger && (
                                          <>
                                            <Grid container spacing={2}>
                                              <Grid item xs={12} lg={6}>
                                                <MDBox pb={3}>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      Win Streak Bonus trigger
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label="Win Streak Bonus trigger"
                                                      value={
                                                        values[index]
                                                          .win_streak_name
                                                      }
                                                      name="win_streak_name"
                                                      onChange={(e) =>
                                                        handleChange(e, index)
                                                      }
                                                      IconComponent={
                                                        CustomExpandMore
                                                      }
                                                    >
                                                      <MenuItem value={1}>
                                                        1
                                                      </MenuItem>
                                                      <MenuItem value={2}>
                                                        2
                                                      </MenuItem>
                                                      <MenuItem value={3}>
                                                        3
                                                      </MenuItem>
                                                      <MenuItem value={4}>
                                                        4
                                                      </MenuItem>
                                                      <MenuItem value={5}>
                                                        5
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                  {errors[index] &&
                                                    touched[index] && (
                                                      <span className="error-message">
                                                        {
                                                          errors[index]
                                                            ?.win_streak_name
                                                        }
                                                      </span>
                                                    )}
                                                </MDBox>
                                              </Grid>
                                              <Grid item xs={12} lg={6}>
                                                {' '}
                                                <MDBox pb={3}>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      Miti point value
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label=" Miti point value"
                                                      value={
                                                        values[index].miti_point
                                                      }
                                                      name="miti_point"
                                                      onChange={(e) =>
                                                        handleChange(e, index)
                                                      }
                                                      IconComponent={
                                                        CustomExpandMore
                                                      }
                                                    >
                                                      {mitiValue?.map(
                                                        (item) => (
                                                          <MenuItem
                                                            value={item}
                                                          >
                                                            {item}
                                                          </MenuItem>
                                                        )
                                                      )}
                                                    </Select>
                                                  </FormControl>
                                                  {errors[index] &&
                                                    touched[index] && (
                                                      <span className="error-message">
                                                        {
                                                          errors[index]
                                                            ?.miti_point
                                                        }
                                                      </span>
                                                    )}
                                                </MDBox>
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                              <Grid item xs={12} lg={6}>
                                                <MDBox pb={3}>
                                                  <MDInput
                                                    label="Description"
                                                    autoComplete="off"
                                                    fullWidth
                                                    value={
                                                      values[index]
                                                        .win_description
                                                    }
                                                    name="win_description"
                                                    onChange={(e) =>
                                                      handleSpecial(
                                                        e,
                                                        index,
                                                        100
                                                      )
                                                    }
                                                  />
                                                  {errors[index] &&
                                                    touched[index] && (
                                                      <span className="error-message">
                                                        {
                                                          errors[index]
                                                            ?.win_description
                                                        }
                                                      </span>
                                                    )}
                                                </MDBox>
                                              </Grid>
                                              <Grid item xs={12} lg={6}>
                                                {' '}
                                                <MDBox pb={3}>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      Time
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label="Time"
                                                      value={values[index].time}
                                                      name="time"
                                                      onChange={(e) =>
                                                        handleChange(e, index)
                                                      }
                                                      IconComponent={
                                                        CustomExpandMore
                                                      }
                                                    >
                                                      {Time?.map((item) => (
                                                        <MenuItem value={item}>
                                                          {item}
                                                        </MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  {errors[index] &&
                                                    touched[index] && (
                                                      <span className="error-message">
                                                        {errors[index]?.time}
                                                      </span>
                                                    )}
                                                </MDBox>
                                              </Grid>
                                            </Grid>
                                          </>
                                        )}
                                      </MDBox>
                                    </Paper>
                                    {/* ) : (
                              ''
                            )} */}

                                    <Grid container spacing={2} mt={2}>
                                      <Grid item xs={12} lg={12}>
                                        {' '}
                                        <MDBox pb={3}>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                              Late Game Point
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label="Late Game Point"
                                              disabled={true}
                                              value={
                                                values[index].late_game_point
                                              }
                                              name="late_game_point"
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              IconComponent={CustomExpandMore}
                                            >
                                              {Late?.map((item) => (
                                                <MenuItem value={item}>
                                                  {item}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                          {errors[index] && touched[index] && (
                                            <span className="error-message">
                                              {errors[index]?.late_game_point}
                                            </span>
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0.5}>
                                      <Grid item xs={12} lg={12}>
                                        {' '}
                                        <MDBox pb={3}>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                              Color
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={values[index].color_id}
                                              label="Color"
                                              name="color_id"
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              IconComponent={CustomExpandMore}
                                            >
                                              {colorData?.data?.results?.map(
                                                (item, index) => (
                                                  <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                    id={item.id}
                                                  >
                                                    {item.color_name}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                          {errors[index] && touched[index] && (
                                            <span className="error-message">
                                              {errors[index]?.color_id}
                                            </span>
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <MDBox
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <MDButton
                                        color="info"
                                        onClick={() =>
                                          handleMoreAction(item.image_url)
                                        }
                                      >
                                        Add More
                                      </MDButton>
                                    </MDBox>
                                  </>
                                ))}

                                <MDBox
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="large"
                                    type="submit"
                                  >
                                    Add Action
                                  </MDButton>
                                </MDBox>
                                {/* <MDBox display="flex" justifyContent="flex-end">
                              <MDButton color="info" onClick={handleMoreAction} >
                        Add More 
                      </MDButton>
                      </MDBox> */}
                              </Form>
                            )}
                          </Formik>
                        </MDBox>
                      </MDBox>
                    </Box>
                  </Modal>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
const mapDispatchToProps = {
  createSport: Actions.createSport,
  getBonusDetail: Actions.getBonusDetail,
  colorList: Actions.colorList,
  actionImageList:Actions.getActionImageList
}
const mapStateToProps = (state) => ({
  createSportItem: state.sportListReducer.createSportItem,
  colorData: state.colorlistReducer.colorData,
  bonusData: state.sportListReducer.bonusData,
  actionImageList:state.sportListReducer.actionImageList
})
export default connect(mapStateToProps, mapDispatchToProps)(AddAction)
