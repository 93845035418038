import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid'

import { Button, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import "assets/styles.css";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Paper } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { config } from "utils/s3config";
import S3 from "react-aws-s3";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImgSpinner from "components/ImgLoader";
import { addVisitor } from 'App/store/actions';
import { editVisitor } from 'App/store/actions';

export default function AddVisitor() {
  const formikRef = useRef(null)
  window.Buffer = window.Buffer || require('buffer').Buffer

  const { state } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(false)
  const isEditMode = !!state?.id || false
  const params = useParams()
  const ReactS3Client = new S3(config)

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .max(20, 'Max 20 characters are allowed'),
    image: yup.string().required('Image is required'),
    // is_default: yup.boolean(),
  })

  const initialValues = {
    team_id: params?.team_id || null,
    visitor_id: state?.id ||'',
    name: state?.name || '',
    image: state?.image || '',
    // is_default: state?.is_default || true,
  }

  const handleChange = (e,l,regex) => {
    const { name, value } = e.target
    formikRef.current.setFieldValue(
      name,
      value
      // .replace(/[^a-z0-9\s]/gi, '').slice(0, 50)
      // .replace(regex, "").slice(0, l)
    )
  }

  const handleImg = async (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error('Please select image only.')
      document.getElementById('input-image').value = null
    } else {
      setSpinner(true);
      let img = await ReactS3Client.uploadFile(file, file.name);
      formikRef.current.setFieldValue("image", img.key);
      setSpinner(false);
    }
  }

  const onsubmit = (values) => {
    if (isEditMode) {
      dispatch(editVisitor({ ...values, navigate }))
    } else {
      dispatch(addVisitor({ ...values, navigate }))
    }
  }

  const logoClick = (e) => {
    document.getElementById('team-logo').click()
  }

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onsubmit(values)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <MDBox p={2}>
                    <Card>
                      <MDBox p={3}>
                        <MDTypography mb={3} variant="h4">
                          <Icon
                            fontSize="medium"
                            sx={{ paddingTop: '4px' }}
                            onClick={() => navigate(-1)}
                          >
                            arrow_back_icon
                          </Icon>
                          &nbsp;&nbsp;Visitor Logo
                        </MDTypography>
                        <MDBox pb={3} mt={3}>
                          <MDInput
                            label="Name"
                            fullWidth
                            name="name"
                            value={values.name}
                            inputProps={{
                              maxLength: 20,
                            }}
                            //  onChange={(e) => handleChange(e)}
                            onChange={(e) => handleChange(e, 51)}
                          />
                          <ErrorMessage
                            component="span"
                            name="name"
                            className="error-message"
                          />
                        </MDBox>

                        
                        <MDBox mt={3}>
                          <Paper variant="outlined" p={3}>
                            <MDBox p={3}>
                              <MDBox pb={1}>
                                <MDTypography
                                  variant="h6"
                                  verticalAlign="middle"
                                >
                                  Logo
                                </MDTypography>
                              </MDBox>

                              <MDBox pb={1}>
                                
                                <div
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "1%",
                                    borderRadius: "6px",
                                    height: "49px",
                                    display: "flex",
                                  }}
                                >
                                  <input
                                    // className={classes.input}
                                    style={{ display: 'none' }}
                                    id="raised-button-file team-logo"
                                    multiple
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImg(e.target.files[0])
                                    }
                                  />
                                  <label htmlFor="raised-button-file team-logo">
                                    <div
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        variant="raised"
                                        component="span"
                                        onClick={() => logoClick()}
                                      >
                                        Upload Logo
                                      </Button>
                                      <span style={{ fontSize: '14px' }}>
                                        {values.image}
                                      </span>
                                    </div>
                                  </label>
                                  {spinner && <ImgSpinner />}
                                </div>
                              </MDBox>
                              <ErrorMessage
                                component="span"
                                name="image"
                                className="error-message"
                              />
                            </MDBox>
                          </Paper>
                        </MDBox>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt={3}
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                          >
                            {isEditMode ? 'Update' : 'Save'}
                           
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </MDBox>
    </MDBox>
   
  )
}
