import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import Icon from '@mui/material/Icon'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import socket from 'utils/webSocket'

function BonusPoints(props) {
  const dispatch = useDispatch()
  const params = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const creditBonus = () => {

    let payload = {
      captain_id: state?.captain?.id,
      game_id: params?.id,
      bonus_id: state?.current_action?.id,
      point_value: 0,
      // point_value: state?.current_action?.bonus?.miti_point,
      team_id: state.team_id,
      //  description: "",
      //  time: "",
      msg: state?.current_action?.prize?.first_place_message,
      banner: state?.current_action?.prize?.banner_img_first,
      type: "bonus"
    }

    socket.emit('bonus-trigger', payload)
    // let pointvalue = payload.description
    // sessionStorage.setItem(`point${params?.id}`, pointvalue)
    localStorage.setItem('is_end_turn', false);
    navigate(-1)
  }
  setTimeout(() => {
    sessionStorage.clear()
  }, 1000)
  let g = {}

  const navigatePrevPage = () => {

    localStorage.setItem("is_end_turn", "false")
    navigate(-1)
  }
  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={navigatePrevPage}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      Bonus
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2}>
                    <MDButton
                      style={{
                        backgroundColor:
                          "green",
                        color: '#fff',
                      }}
                      size="medium"
                      onClick={() => creditBonus()}
                      sx={{ width: '60vw', margin: 'auto' }}
                    >
                      {state?.current_action?.prize?.name || '--'}
                    </MDButton>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

export default BonusPoints
