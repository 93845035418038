import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import * as Actions from "../../App/store/actions";
import { connect } from "react-redux";
import MDSpinner from "components/MDSpinner";

function Uniforms(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [img, setImg] = React.useState();
  const [loader, setLoader] = React.useState(false);
  let { uniformList: { data: { data } = {} } = {} } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.getUniformList((v) => setLoader(v)));
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
  };
  const columns = [{ Header: "Uniform", accessor: "Uniform" }];
  const rowsdata = data?.map((item, index) => ({
    Uniform: (
      <div
        onClick={() => handleImage(item.image_url)}
        style={{
          cursor: "pointer",
          color: "blue",
          textDecoration: "underline",
        }}
      >
        {item.image_name}
      </div>
    ),
  }));

  const handleImage = (imgs) => {
    handleOpen();
    setImg(imgs);
  };

  if (loader) {
    return <MDSpinner />;
  }

  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox p={2}>
            {rowsdata && (
              <DataTable
              entriesPerPage={10}
              showTotalEntries={rowsdata.length}
                table={{
                  columns: columns,
                  rows: rowsdata,
                }}
              />
            )}
          </MDBox>
        </Grid>
        <MDAddIcon link="/add-uniform" color="inherit" font="sm" />
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDBox display="flex" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Image Details
            </Typography>

            <MDBox>
              <Icon onClick={handleClose} sx={{ cursor: "pointer" }}>
                close
              </Icon>
            </MDBox>
          </MDBox>
          <MDBox p={2}>
            <MDBox>
              <img src={img} width="100%" className="back-img"></img>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </MDBox>
  );
}
const mapDispatchToProps = {
  getUniformList: Actions.getUniformList,
};
const mapStateToProps = (state) => ({
  uniformList: state.uniformlistReducer.uniformList,
});
export default connect(mapStateToProps, mapDispatchToProps)(Uniforms);
