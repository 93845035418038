import * as actionTypes from '../actions/actionTypes'

const initialState = {
  backgroundata: {},
  deleteData: {},
}

const backgroundUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_BACKGROUND: {
      return {
        ...state,
        backgroundData: action.payload,
      }
    }
    case actionTypes.DELETE_BACKGROUND: {
      return {
        ...state,
        deleteData: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default backgroundUploadReducer
