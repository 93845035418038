 

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Games from 'layouts/Games'
import AddGames from 'layouts/Games/AddGames'
import SignIn from 'layouts/authentication/sign-in'
import Teams from 'layouts/teams'
import Sports from 'layouts/sports'
import Users from 'layouts/Users'
import Uniforms from 'layouts/Uniforms'
import Background from 'layouts/Background'
import AddTeams from 'layouts/teams/AddTeams.js'
import Score from 'layouts/Games/Score'
import ViewTeam from 'layouts/teams/ViewTeam'
// @mui icons
import Icon from '@mui/material/Icon'
import AddSponsor from 'layouts/teams/ViewTeam/Component/Sponsor/AddSponsor'
import AddCaptain from 'layouts/teams/ViewTeam/Component/Captains/AddCaptain'
import AddSport from 'layouts/sports/AddSport'
import BonusDetail from 'layouts/sports/BonusDetail'
import ActionButton from 'layouts/sports/ActionButton'
import AddPrize from 'layouts/teams/ViewTeam/Component/Prize/addPrize'
import ViewDetail from 'layouts/Users/ViewDetail'
import AddUniform from 'layouts/Uniforms/AddUniform'
import AddBackground from 'layouts/Background/AddBackground'
import AddBonus from 'layouts/sports/BonusDetail/AddBonus'
import AddAction from 'layouts/sports/ActionButton/AddAction'
import AddPlayer from 'layouts/Games/Score/AddPlayer'
import BonusPoints from 'layouts/Games/Score/Bonus'
import AddVisitor from 'layouts/teams/ViewTeam/Component/VisitorLogo/AddVisitor'
import ActionImage from 'layouts/sports/ActionImage'
import AddActionImage from 'layouts/sports/ActionImage/AddActionImage'

const routes = [
  {
    type: 'collapse',
    name: 'Games',
    key: 'game',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    icon: <Icon fontSize="small">gamepad_icon</Icon>,
    route: '/game',
    component: <Games />,
  },
  {
    type: 'collapse',
    name: 'Teams',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    key: 'teams',
    icon: <Icon fontSize="small">groups_icon</Icon>,
    route: '/teams',
    component: <Teams />,
  },
  {
    key: 'view-team',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    name: 'Teams',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/view-team/:team_id/:key',
    component: <ViewTeam />,
  },
  {
    key: 'edit-team',
    name: 'Teams',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/edit-team/:id',
    component: <AddTeams />,
  },
  {
    key: 'edit-sport',
    access: ['MASTER_ADMIN'],
    name: 'Sports',
    icon: <Icon fontSize="small">sports_icon</Icon>,
    route: '/edit-sport',
    component: <AddSport />,
  },
  {
    key: 'add-prize',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/view-team/:team_id/add-prize',
    component: <AddPrize />,
  },
  {
    key: '/add-background',
    access: ['MASTER_ADMIN'],
    name: 'Backgrounds',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/add-background',
    component: <AddBackground />,
  },
  {
    key: 'add-uniform',
    access: ['MASTER_ADMIN'],
    name: 'Uniforms',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/add-uniform',
    component: <AddUniform />,
  },
  {
    key: 'edit-prize',
    access: ['TEAM_ADMIN', 'MASTER_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/view-team/:team_id/edit-prize/:prize_id',
    component: <AddPrize />,
  },

  {
    key: 'view-detail',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/view-detail/:user_id',
    component: <ViewDetail />,
  },
  {
    type: 'collapse',
    name: 'Sports',
    key: 'sports',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">sports_icon</Icon>,
    route: '/sports',
    component: <Sports />,
  },
  {
    name: 'Bonus Detail',
    key: 'bonus-detail',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/bonus-details/:id',
    component: <BonusDetail />,
    exact: true,
  },
  {
    name: 'Edit Bonus',
    key: 'edit-bonus',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/edit-bonus/:id',
    component: <AddBonus />,
  },
  {
    name: 'Add Bonus',
    key: 'add-bonus',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/add-bonus',
    component: <AddBonus />,
  },
  {
    name: 'Edit Action Detail',
    key: 'edit-action-detail',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/edit-action/:id',
    component: <AddAction />,
  },
  {
    name: 'Action Button',
    key: 'action-detail',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/action-details/:id',
    component: <ActionButton />,
  },
  {
    name: 'Add Action Button',
    key: 'add-action',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/add-action',
    component: <AddAction />,
  },
  {
    type: 'collapse',
    access: ['MASTER_ADMIN'],
    name: 'Users',
    key: 'users',
    icon: <Icon fontSize="small">person_add_icon</Icon>,
    route: '/users',
    component: <Users />,
  },
  {
    type: 'collapse',
    access: ['MASTER_ADMIN'],
    name: 'Uniforms',
    key: 'uniforms',
    icon: <Icon fontSize="small">person_icon</Icon>,
    route: '/uniforms',
    component: <Uniforms />,
  },
  {
    type: 'collapse',
    name: 'Backgrounds',
    access: ['MASTER_ADMIN'],
    key: 'background',
    icon: <Icon fontSize="small">photo_camera_back_icon</Icon>,
    route: '/background',
    component: <Background />,
  },
  {
    key: 'add-games',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/add-games',
    component: <AddGames />,
  },
  {
    key: 'add-teams',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Teams',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/add-teams',
    component: <AddTeams />,
  },
  {
    key: 'score',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/score/:id',
    component: <Score />,
  },
  {
    key: 'score',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/score/:id/:action_id',
    component: <AddPlayer />,
  },
  {
    key: 'score',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/score/:id/bonus/:bonus_id',
    component: <BonusPoints />,
  },
  {
    key: 'edit-game',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Games',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/edit-game/:id',
    component: <AddGames />,
  },
  {
    key: 'add-sponsor',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/add-sponsor',

    component: <AddSponsor />,
  },
  {
    key: 'add-sponsor',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/edit-sponsor/:sponsor_id',

    component: <AddSponsor />,
  },
  {
    key: 'add-captain',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Teams',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/add-captain',
    component: <AddCaptain />,
  },
  {
    key: 'edit-captain',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    name: 'Teams',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/edit/:captain_id',
    component: <AddCaptain />,
  },
  {
    key: 'add-visitor',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/add-visitor',

    component: <AddVisitor />,
  },
  {
    key: 'add-visitor',
    access: ['MASTER_ADMIN', 'TEAM_ADMIN'],
    icon: <Icon fontSize="small">login</Icon>,
    route: '/view-team/:team_id/edit-visitor/:visitor_id',

    component: <AddVisitor/>,
  },
  {
    key: 'sports',
    access: ['MASTER_ADMIN'],
    name: 'Sports',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/add-sports',
    component: <AddSport />,
  },
  {
    key: 'action-image',
    access: ['MASTER_ADMIN'],
    name: 'Sports',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/action-image',
    component: <ActionImage />,
  },
  {
    key: 'add-action-image',
    access: ['MASTER_ADMIN'],
    name: 'Sports',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/add_action_image',
    component: <AddActionImage />,
  },
  {
    name: 'Sports',
    key: 'edit_action_image',
    access: ['MASTER_ADMIN'],
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/edit_action_image/:id',
    component: <AddActionImage />,
  },
]

export default routes
