import * as actionTypes from './actionTypes'
import { GET } from '../../../utils/apiService'
import { toastData } from '../../../utils/toaster'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const colorList = () => {
  return (dispatch) => {
    GET('/v1/admin/colors')
      .then((response) => {
        dispatch({
          type: actionTypes.GET_COLOR,
          payload: response.data,
        })

        // callback()
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        toastData(err.response)
      })
  }
}
