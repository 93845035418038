 

import React, { useState, useEffect, useRef } from 'react'

// react-router-dom components
import { Link, Navigate, useNavigate } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import validator from 'validator'
import { loginRequest } from '../../../App/store/actions'
import { useDispatch } from 'react-redux'
import { isloggedIn } from 'utils/token'
import { toast, ToastContainer } from 'react-toastify'
import MDSpinner from 'components/MDSpinner'
import { Icon } from '@mui/material'
function Basic() {
  const [rememberMe, setRememberMe] = useState(false)
  const [err, setErr] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleSetRememberMe = () => setRememberMe(!rememberMe)

  const submitRequest = (values) => {
    if (rememberMe) {
      localStorage.setItem('user_email', values.email)
      localStorage.setItem('password', values.password)
    }
    dispatch(
      loginRequest(
        values,
        () => {
          navigate('/game')
          setTimeout(() => {
            toast.success('Logged in successfully')
          }, 1000)
        },
        (msg) => {
          setErr(msg)
        }
      )
    )
    setTimeout(() => {
      setErr('')
    }, 3000)
  }
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }
  const validationSchema = yup.object({
    email: yup.string().required('Email is required').email('Invalid email'),
    password: yup.string().required('Password is required'),
  })

  const initialValues = {
    email: localStorage.getItem('user_email') || '',
    password: localStorage.getItem('password') || '',
  }

  const formikRef = useRef(null)

  return isloggedIn() ? (
    <Navigate to="/game" />
  ) : (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => submitRequest(values)}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value)
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      fullWidth
                    />
                 
                    <ErrorMessage
                      component="span"
                      name="email"
                      className="error-message"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <div className="eye-div">
                      <MDInput
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        label="Password"
                        value={values.password}
                        onChange={(e) => {
                          setFieldValue('password', e.target.value)
                        }}
                        inputProps={{
                          maxLength: 20,
                        }}
                        fullWidth
                      />
                      <span
                        className="eye-icon"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown ? (
                          <Icon>visibilityeye</Icon>
                        ) : (
                          <Icon>visibility_offeye</Icon>
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      component="span"
                      name="password"
                      className="error-message"
                    />
                  </MDBox>
                  {err && <span className="error-message">{err}</span>}
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                    >
                      sign in
                    </MDButton>
                    <ToastContainer />
                  </MDBox>
                </Form>
              )}
            </Formik>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default Basic
