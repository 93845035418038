import React, { useEffect, useMemo, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'

import MDBox from "components/MDBox";
import { Button, Card, Icon, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Select from "@mui/material/Select";
import "assets/styles.css";
import Switch from "@mui/material/Switch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { createTeam } from "App/store/actions";
import { editTeam } from "App/store/actions";
import { getSportList } from "App/store/actions";
import { getTeamDetail } from "App/store/actions";
import { deleteTeamAdmins } from 'App/store/actions';
import MDSpinner from "components/MDSpinner";
import * as actionTypes from "../../../App/store/actions/actionTypes";
import { config } from "utils/s3config";
import S3 from "react-aws-s3";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";
import ImgSpinner from "components/ImgLoader";
import CustomExpandMore from "components/Expand";
import * as Actions from 'App/store/actions'
export default function AddTeams() {
  window.Buffer = window.Buffer || require('buffer').Buffer

  const formikRef = useRef(null)
  // const [delete_id, setDeleteId] = useState({ type: '', payload: '' })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { state = {} } = useLocation()
  const [spinner, setSpinner] = useState(false)
  let params = useParams()

  const isEditMode = !!state?.id || false
  const ReactS3Client = new S3(config)

  const { sportsList } = useSelector((state) => state.sportListReducer)
  const { teamDetails = {}, teamDetailsLoader } = useSelector(
    (state) => state.teamReducer
  )

  const addTeamAdmin = () => {
    formikRef.current.values.team_admin.push({
      name: '',
      email: '',
      password: '',
    })
    formikRef.current.setFieldValue(formikRef.current.values)
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .max(50, 'Max 50 characters are allowed'),
    image: yup.string().required('Image is required'),
    url: yup
      .string()
       .required('Url name is required')
      .test('len', 'Enter a valid playmiti URL', (val) => {
        return val?.split('.')[1] == 'playmiti' || false
      }),
    slug: yup
      .string()
      .required('Slug is required')
      .max(10, 'Max 10 characters are allowed'),
    sports_id: yup.string().required('Sport is required'),

    team_admin: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required('Name is required')
          .max(50, 'Max 50 characters are allowed'),
        email: yup
          .string()
          .email('Invalid Email')
          .required('Email is required')
          .max(50, 'Max 100 characters are allowed'),
        password: yup
          .string()
          .required('Password is required')
          // .max(20, 'Max 20 characters are allowed')
          .min(6, 'Min 6 characters are allowed'),
      })
    ),
  })

  const initialValues = {
    name: teamDetails?.results?.name || '',
    team_id: teamDetails?.results?.id || null,
    image: teamDetails?.results?.image || '',
    url: teamDetails?.results?.url || 'playmiti.com',
    slug: teamDetails?.results?.slug || '',
    sports_id: teamDetails?.results?.sports_id || '',
    team_admin: teamDetails?.results?.users || [{ name: '', email: '', password: '' }],
  }

  useEffect(() => {
  
    if (params?.id) {
      dispatch(getTeamDetail(params?.id))
    }
    dispatch(getSportList(() => {}))
    return () => {
      dispatch({ type: actionTypes.SET_TEAM_DETAILS, payload: {} })
    }
  }, [])

  const onsubmit = (values) => {
    delete values['id']
  
    if (isEditMode) {
      delete values['created_by']
      delete values['creater_id']
      dispatch(editTeam(values, navigate))
    } else {
      values['created_by']=localStorage.getItem("user_type")
      values['creater_id']=localStorage.getItem("user_id")
      dispatch(createTeam(values, navigate))
    }
  }

  const handleImg = async (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      document.getElementById('input-image').value = null
      toast.error('Please select image only.')
    } else {
      setSpinner(true)
      let img = await ReactS3Client.uploadFile(file, file.name)
      formikRef.current.setFieldValue('image', img.key)
      setSpinner(false)
    }
  }

  const handleChange = (e) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(
      name,
      value.replace(/[^a-z\s]/gi, '').slice(0, 50)
    )
  }
  const handleSelect = (e) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(name, value)
  }

  const handleURL = (e) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(name, value.slice(0, 150))
  }

  const handleTeamAdmin = (e, index, regex, l) => {
    let { name, value } = e.target
    formikRef.current.values.team_admin[index][name] = value
      .replace(regex, '')
      .slice(0, l)
    formikRef.current.setFieldValue(formikRef.current.values)
  }

  const removeTeamAdmin = (id) => {
    let obj = formikRef.current.values.team_admin.findIndex((i) => i.id === id)
    formikRef.current.values.team_admin[obj]['isRemove'] = true
    formikRef.current.setFieldValue(formikRef.current.values)
    // setDeleteId({
    //   type: '',
    //   payload: '',
    // })
  }
  const deleted = (index, id) => {
    if(id){
      dispatch(Actions.deleteTeamAdmins(id)) 
    }
    formikRef.current.values.team_admin.splice(index, 1)
       formikRef.current.setFieldValue(formikRef.current.values)
   }
  if (isEditMode && teamDetailsLoader) {
    return <MDSpinner />
  }

  const removeAdmin = (i) => {
    formikRef.current.values.team_admin.splice(i, 1)
    formikRef.current.setFieldValue(formikRef.current.values)
    // setDeleteId({
    //   type: '',
    //   payload: '',
    // })
  }

  const logoClick = (e) => {
    document.getElementById('team-logo').click()
  }

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          {/* <DeleteModal
            open={!!delete_id.payload}
            text={'Are you sure you want to delete this Team admin'}
            handleAction={() =>
              delete_id?.type == 'ID'
                ? removeTeamAdmin(delete_id?.payload)
                : removeAdmin(delete_id?.payload)
            }
            handleClose={() =>
              setDeleteId({
                type: '',
                payload: '',
              })
            }
          /> */}
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      {isEditMode ? 'Edit Team' : 'Add Team'}
                    </MDTypography>
                  </MDBox>
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onsubmit(values)}
                  >
                    {({ errors: { team_admin = [] }, touched, values }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={4}>
                            {' '}
                            <MDBox pb={3}>
                              <MDInput
                                label="Name"
                                fullWidth
                                name="name"
                                value={values.name}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                              />
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="name"
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox pb={3}>
                              <div
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '1%',
                                  borderRadius: '6px',
                                  height: '45px',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  // className={classes.input}
                                  style={{ display: 'none' }}
                                  id="raised-button-file team-logo"
                                  multiple
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleImg(e.target.files[0])}
                                />
                                <label htmlFor="raised-button-file team-logo">
                                  <div
                                    style={{
                                      display: 'flex',
                                      paddingTop: '7px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      {' '}
                                      <Button
                                        type="button"
                                        variant="raised"
                                        component="span"
                                        onClick={() => logoClick()}
                                      >
                                        Upload Logo
                                      </Button>
                                      <span style={{ fontSize: '14px' }}>
                                        {values.image}
                                      </span>
                                    </div>
                                  </div>
                                </label>
                                {spinner && <ImgSpinner />}
                              </div>
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="image"
                                autoComplete="off"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <MDBox pb={3}>
                              <MDInput
                                type="text"
                                label="URL Name"
                                fullWidth
                                name="url"
                                value={values.url}
                                autoComplete="off"
                                onChange={(e) => handleURL(e)}
                              />
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="url"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3}>
                              <MDInput
                                type="text"
                                label="Slug"
                                fullWidth
                                name="slug"
                                autoComplete="off"
                                value={values.slug}
                                inputProps={{
                                  maxLength: 10,
                                }}
                                onChange={(e) => handleChange(e)}
                              />
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="slug"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Sport
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Sport"
                                  name="sports_id"
                                  value={values?.sports_id}
                                  onChange={(e) => handleSelect(e)}
                                  IconComponent={CustomExpandMore}
                                >
                                  {sportsList?.data?.results.map((i) => (
                                    <MenuItem value={i.id}>{i.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="sports_id"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={11}>
                            <MDTypography mb={3} variant="h4">
                              Team Admin
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12} lg={1}>
                            <MDBox mt={0.5} display="flex" alignItems="center">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={addTeamAdmin}
                              >
                                Add More
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>

                        {values.team_admin
                          .filter((i) => i.isRemove != true)
                          .map((item, index) => (
                            <div key={index}>
                            
                              {index > 0 && (
                                <Icon
                                  style={{
                                    marginLeft: 'auto',
                                    cursor: 'pointer',
                                    float: 'right',
                                  }}
                                  onClick={() =>
                                    deleted(index,item?.id)
                                  }
                                      // setDeleteId({
                                      //   type: item?.id ? 'ID' : 'INDEX',
                                      //   payload: item?.id ? item.id : index,
                                      // })
                                    // item?.id
                                    //   ? removeTeamAdmin(item.id)
                                    //   : removeAdmin(index)
                                  
                                >
                                  delete
                                </Icon>
                              )}
                              <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                  {' '}
                                  <MDBox pb={3}>
                                    <TextField
                                      label="Name"
                                      fullWidth
                                      name="name"
                                      value={values?.team_admin[index]?.name}
                                      onChange={(e) =>
                                        handleTeamAdmin(
                                          e,
                                          index,
                                          /[^a-z\s]/gi,
                                          30
                                        )
                                      }
                                      inputProps={{
                                        maxLength: 30,
                                      }}
                                      disabled={!!values?.team_admin[index]?.id}
                                    />
                                    {team_admin[index] &&
                                      touched?.team_admin && (
                                        <span className="error-message">
                                          {team_admin[index]?.name}
                                        </span>
                                      )}
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  {' '}
                                  <MDBox pb={3}>
                                    <TextField
                                      type="email"
                                      label="Email Address"
                                      fullWidth
                                      name="email"
                                      value={values?.team_admin[index].email}
                                      onChange={(e) =>
                                        handleTeamAdmin(e, index, / /, 50)
                                      }
                                      disabled={!!values?.team_admin[index]?.id}
                                    />
                                    {team_admin && touched?.team_admin && (
                                      <span className="error-message">
                                        {team_admin[index]?.email}
                                      </span>
                                    )}
                                  </MDBox>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                  {' '}
                                  <MDBox pb={3}>
                                    <TextField
                                      type="password"
                                      label="Password"
                                      fullWidth
                                      name="password"
                                      inputProps={{
                                        maxLength: 20,
                                        minLength: 6,
                                      }}
                                      value={values.team_admin[index].password}
                                      onChange={(e) =>
                                        handleTeamAdmin(e, index, / /, 20)
                                      }
                                      disabled={!!values?.team_admin[index]?.id}
                                    />
                                    {team_admin && touched?.team_admin && (
                                      <span className="error-message">
                                        {team_admin[index]?.password}
                                      </span>
                                    )}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                          >
                            {isEditMode ? 'Update' : 'Save'}
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
