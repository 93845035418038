import * as actionTypes from './actionTypes'
import { toastData } from '../../../utils/toaster'
import { GET, POST } from '../../../utils/apiService'

export const getUniformList = (callback) => {
  const request = GET(`/v1/admin/uniform`)
  callback(true)
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: actionTypes.GET_UNIFORM_LIST,
          payload: response,
        })
        callback(false)
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        callback(false)
        // callbackError()
        toastData(err.response)
      })
}
