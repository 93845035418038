import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Icon } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Select from "@mui/material/Select";
import "assets/styles.css";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DataTable from "examples/Tables/DataTable";
import { userDetail } from "App/store/actions/users.actions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@mui/base";
import MDSpinner from "components/MDSpinner";
import {getUserMitiGameList} from "App/store/actions/users.actions"
import moment from "moment";
export default function ViewDetail() {
  const [adminList, setAdminList] = useState([{}]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { userDetails,userMitiGameList,userMitiGameListLoader, userDetailsLoader } = useSelector(
    (state) => state.userlistReducer
  );
  // const { userMitiGameList, userMitiGameListLoader } = useSelector(
  //   (state) => state.userlistReducer
  // );
  const columns = [
    { Header: "Date", accessor: "Date" },
    { Header: "Opponent Team", accessor: "Team" },
  ];
  const rows = userDetails?.gameDetail?.map((item, index) => {
    return {
     Date:moment(item?.date).format("MM/DD/YY"),
     Team:item.opponent_slug,
    };
  });
  // [
  //   {
  //     Date: "03/30/2022",
  //     Team: "Lakers",
  //   },
  //   {
  //     Date: "03/30/2022",
  //     Team: "Lakers",
  //   },
  // ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 5,
  };

  const navigate = useNavigate();

  const handleAddAdmin = () => {
    setAdminList([...adminList, {}]);
  };

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userDetail(params?.user_id));
    // dispatch(getUserMitiGameList(params?.user_id))
  }, []);

  // useEffect(() => {
  //   dispatch((getUserMitiGameList));
  // }, []);


  


  if (userDetailsLoader) {
    return <MDSpinner />;
  }

  // if (userMitiGameListLoader) {
  //   return <MDSpinner />;
  // }

  return (
    <>
      <MDBox mt={1}>
        <MDBox mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <MDBox p={2}>
                <Card>
                  <MDBox p={3}>
                    <MDBox display="flex">
                      <Icon
                        fontSize="medium"
                        sx={{ paddingTop: "4px" }}
                        onClick={() => navigate(-1)}
                      >
                        arrow_back_icon
                      </Icon>
                      &nbsp;&nbsp;
                      <MDTypography mb={3} variant="h4">
                        View Details
                      </MDTypography>
                    </MDBox>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Name</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails.user_name}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Email Address</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails.email}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Leaderboard username</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.user_name}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Date of signup</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{new Date(userDetails?.createdAt).toLocaleDateString()}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>MITI points</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.total_miti_point}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>
                            Bonus opportunities claimed
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.claimedBonus}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Bonus opportunities</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.gameBonusOpportunity}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Number of chat sent</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.chatSentCount}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>
                            Number of private games sent
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.shareUrlCount}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>
                            Number of private games set up
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.shareUrlCount}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>
                            Number of all miti games played
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography
                            onClick={handleOpen}
                            color="info"
                            sx={{ cursor: "pointer" }}
                          >
                            {userDetails.total_miti_game}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Number of game won</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>{userDetails?.winning_matches}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {" "}
                        <MDBox pb={3}>
                          <MDTypography>Teams</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox pb={3}>
                          <MDTypography>1</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDBox display="flex" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              View Details
            </Typography>
            <MDBox>
              <Icon onClick={handleClose} sx={{ cursor: "pointer" }}>
                close
              </Icon>
            </MDBox>
          </MDBox>

          <MDBox p={2}>
            <Grid container>
              <Grid item xs={12} lg={7}>
                {" "}
                <MDBox pb={3}>
                  <MDTypography>Name</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={5}>
                <MDBox pb={3}>
                  <MDTypography>{userDetails?.user_name}</MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={7}>
                {" "}
                <MDBox pb={3}>
                  <MDTypography color="dark">
                    Number of all miti games played
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={5}>
                <MDBox pb={3}>
                  <MDTypography>{userDetails.total_miti_game}</MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox pt={3} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <MDBox p={2}>
                    <DataTable
                      table={{
                        columns: columns,
                        rows: rows,
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
      {/* <Footer /> */}
    </>
  );
}
