

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import MDSpinner from "components/MDSpinner";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getUserTeamAdminList } from "App/store/actions/users.actions";
import { deleteTeamAdmin } from "App/store/actions/users.actions"

import DeleteModal from "components/DeleteModal";
function User() {
  const { teamadminList, teamadminLoader } = useSelector(
    (state) => state.userlistReducer
  );
  const dispatch = useDispatch();
  const [delete_id, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(getUserTeamAdminList());
  }, []);

  const removeTeamAdmin = () => {
    dispatch(deleteTeamAdmin(delete_id));
    setDeleteId("");
  };

  const columns = [
    {
      Header: "Name",
      accessor: "Name",
      width: "20%",
    },
    {
      Header: "Real Team",
      accessor: "RealTeam",
      width: "20%",
    },
    { Header: "Email address", accessor: "Email", width: "20%" },

    { Header: "Action", accessor: "action", width: "20%" },
  ];


  const rows = teamadminList?.map((item, index) => {
    return {
      Name: item.user_name,
      RealTeam: item.team?.name,
      Email: item.email,
      action: (
        <div>
          <Link to="#" onClick={() => setDeleteId(item.id)}>
            <Icon fontSize="small">delete</Icon>
          </Link>

        </div>
      ),

    };
  });

  if (teamadminLoader) {
    return <MDSpinner />;
  }
  return (
    <MDBox pt={1} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {delete_id && (
            <DeleteModal
              open={!!delete_id}
              handleAction={() => removeTeamAdmin()}
              handleClose={() => setDeleteId("")}
              text="Are you sure you want to delete this Team admin"
            />
          )}
          <MDBox>
            <MDTypography variant="h6">Team Admin</MDTypography>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              table={{
                columns: columns,
                rows: rows,
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default User;
