import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import 'assets/styles.css'
import Switch from '@mui/material/Switch'
import { Paper } from '@mui/material'
import Icon from '@mui/material/Icon'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Actions from '../../../App/store/actions'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

function BonusDetail(props) {
  const [date, setDate] = React.useState('3/10/2022')
  const [time, setTime] = React.useState('8:00')
  const [adminList, setAdminList] = useState([{}])

  const handleAddAdmin = () => {
    setAdminList([...adminList, {}])
  }

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(Actions.getBonusDetail(params.id))
  }, [])

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      Bonus Details
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2}>
                    {props?.bonusData?.data?.bonuses?.map((item, index) => (
                      <Grid item xs={12} lg={4}>
                        {' '}
                        <MDBox pb={3} display="flex">
                          <MDButton
                            variant="gradient"
                            style={{
                              background: item.color.color_name,
                              color: '#fff',
                            }}
                            size="large"
                            fullWidth
                            onClick={() =>
                              navigate(`/edit-bonus/${item.sports_id}`, {
                                state: {
                                  bonus: props.bonusData.data.bonuses.filter(
                                    (i) => i.id === item.id
                                  ),
                                  isbonus: true,
                                },
                              })
                            }
                            className="bonus-button"
                          >
                            {item.name} &nbsp; &nbsp;
                            <Icon>edit</Icon>
                          </MDButton>
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

const mapDispatchToProps = {
  getBonusDetail: Actions.getBonusDetail,
}

const mapStateToProps = (state) => ({
  bonusData: state.sportListReducer.bonusData,
})

export default connect(mapStateToProps, mapDispatchToProps)(BonusDetail)
