import React, {useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import { Button, Card } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import { Icon } from '@mui/material'
import 'assets/styles.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPrizeType } from 'App/store/actions'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { createPrize } from 'App/store/actions'
import { editPrize } from 'App/store/actions'
import reactS3 from 'react-aws-s3'
import { config } from 'utils/s3config'
import { toast } from 'react-toastify'
import moment from 'moment'
import ImgSpinner from 'components/ImgLoader'
import CustomExpandMore from 'components/Expand'

export default function AddPrize() {
  window.Buffer = window.Buffer || require('buffer').Buffer

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [spinner, setSpinner] = useState({ type: '', payload: false })
  const { state } = useLocation()
  const [monthyEndDate, seMonthlyEndDate] = useState('')

  const { prizeTypes } = useSelector((state) => state.teamReducer)
  const isEditMode = !!state?.id || false

  const getBonusPrizeid = () => {
    return prizeTypes.find((i) => 'Bonus Prize' == i.prize_name)?.id
 }

  const getGamePrizeid = () => {
    return prizeTypes.find((i) => 'Game Prize' == i.prize_name)?.id
  }

  const validationSchema = yup.object({
    prize_type_id: yup.string().required('Prize type is required'),
    name: yup.string().required('Name is required'),

    banner_img_first: yup.string().required('Banner image is required'),
    banner_img_second: yup.string().required('Banner image is required'),

    banner_img_third: yup
      .string()
      .nullable()
      .when('prize_type_id', (prize_type_id) => {
        if (getGamePrizeid() == prize_type_id) {
          return yup.string().required('Banner image is required').nullable()
        }
      }),
    //   object().shape({
    //     banner_img_third: string().required("Category is required.").nullable()
    // })}
    rules: yup.string().required('Rules are required'),
    email_message: yup.string().required('Email message is required'),
    first_place_message: yup
      .string()
      .when('prize_type_id', (prize_type_id) => {
        if (
          // getBonusPrizeid() == prize_type_id ||
          getGamePrizeid() == prize_type_id
        ) {
          return yup.string().required('1st place message is required')
        }
      })
      .max(100, 'Max 100 characters are allowed'),
    second_place_message: yup
      .string()
      .when('prize_type_id', (prize_type_id) => {
        if (
          // getBonusPrizeid() == prize_type_id ||
          getGamePrizeid() == prize_type_id
        ) {
          return yup.string().required('2nd place message is required')
        }
      })
      .max(100, 'Max 100 characters are allowed'),
    third_place_message: yup
      .string()
      .when('prize_type_id', (prize_type_id) => {
        if (
          // getBonusPrizeid() == prize_type_id ||
          getGamePrizeid() == prize_type_id
        ) {
          return yup.string().required('3rd place message is required')
        }
      })
      .max(100, 'Max 100 characters are allowed'),
    fourth_place_message: yup
      .string()
      .when('prize_type_id', (prize_type_id) => {
        if (
          // getBonusPrizeid() == prize_type_id ||
          getGamePrizeid() == prize_type_id
        ) {
          return yup.string().required('4th place message is required')
        }
      })
      .max(100, 'Max 100 characters are allowed'),
    is_default: yup.string().required('Default is required'),

    start_date: yup.date().when('prize_type_id', (prize_type_id) => {
      if (![getBonusPrizeid(), getGamePrizeid()].includes(prize_type_id)) {
        return yup

          .date()
          .required('Start date is required')
          .min(new Date(new Date().setFullYear(new Date().getFullYear() - 1)) , 'Please select a future date')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            'Please select a valid date'
          )
      }
    }),
    end_date: yup.date().when('prize_type_id', (prize_type_id, start_date) => {
      if(prizeTypes.find((i) => i.prize_name === "Monthly Prize")?.prize_name){
        return yup
        .date()
        .min(yup.ref('start_date'), "End date can't be before start date")
   
      }
    //  if   //  (![getBonusPrizeid(), getGamePrizeid()].includes(prize_type_id)) 
     else 
     {
          return yup
          .date()
           .max(
            new Date(new Date().getFullYear() + 28*24*60*60*1000),
            'Please select a valid date'
          )
          .min(yup.ref('start_date'), "End date can't be before start date")
   
  
        }
        
       
    }),
  })

  const initialValues = {
    team_id: params?.team_id || null,
    prize_id: params?.prize_id || null,
    prize_type_id: state?.prize_type_id || '',
    name: state?.name || '',
    banner_img_first: state?.banner_img_first || '',
    banner_img_second: state?.banner_img_second || '',
    banner_img_third: state?.banner_img_third,
    banner_img_fourth: '',
    rules: state?.rules || '',
    email_message: state?.email_message || '',
    first_place_message: state?.first_place_message || '',
    second_place_message: state?.second_place_message || '',
    third_place_message: state?.third_place_message || '',
    fourth_place_message: state?.fourth_place_message || '',
    is_default: state?.is_default || '',
    start_date: state?.start_date || '',
    end_date: state?.end_date || '',
  }

  const formikRef = useRef(null)
  const reactS3Client = new reactS3(config)

  const handleChange = (e) => {
    let { value, name } = e.target
    formikRef.current.setFieldValue(name, value)
  }

  const handleChangee = (e) => {
    let { value, name } = e.target
    formikRef.current.setFieldValue(
      name,
      // value.replace(/[^a-z0-9\s]/gi, '').slice(0, 30)
      value.replace(/[^a-z,0-9.\s]/gi, '')
    )
  }

  const handleChangeEmail = (e, l, regex) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(
      name,
      value
        // .replace(/[^a-z,0-9\.s]/gi, '')
        .replace(regex, '')
        .slice(0, l)
    )
  }
  
  const onsubmit = (values) => {
    if (isEditMode) {
      dispatch(editPrize({ ...values, navigate }))
    } else {
      dispatch(createPrize({ ...values, navigate }))
    }
  }

  const getPrizeTypeName = (values) => {
    let name =
      prizeTypes.find((i) => i.id === values?.prize_type_id)?.prize_name || ''
    return name

  }
  
  const handleImg = async (e) => {
    const { files, name } = e.target
    if (!files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error('Please select image only.')
      document.getElementById('input-image').value = null
    } else {
      setSpinner({ type: name, payload: true })
      let img = await reactS3Client.uploadFile(
        files[0],
        files[0].name.replace(/\s/g, '-')
      )
      formikRef.current.setFieldValue(name.replace(/\s/g, '-'), img.key)
      setSpinner({ type: name, payload: false })
    }
  }

  const logoClick = (id) => {
    let el = document.querySelector(id)
    el.click()
  }
  const addMonth = (value) => {
    var month = moment(value).add(1, 'M').format('DD/MM/YYYY')
    seMonthlyEndDate(month)
    // return month
  }
  useEffect(() => {
    dispatch(getPrizeType())
  }, [])

  // useMemo(()=>{
  //   let newDate=new Date(formikRef?.current?.values?.start_date)
  //   formikRef?.current?.setFieldValue("end_date"))
  // },[formikRef?.current?.values?.start_date])

  return (
    <MDBox mt={1}>
      <MDBox p={2}>
        {' '}
        <MDTypography variant="h5">{localStorage.getItem('slug')}</MDTypography>
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDTypography mb={3} variant="h4">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: '4px' }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    {isEditMode ? 'Edit Prize' : 'Add Prize'}
                  </MDTypography>
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onsubmit(values)}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      
                      <Form>
                    
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              {' '}
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label-team">
                                  Prize Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label-team"
                                  id="demo-simple-select"
                                  value={values.prize_type_id}
                                  label="Prize Type"
                                  name="prize_type_id"
                                  onChange={(event) => handleChange(event)}
                                  IconComponent={CustomExpandMore}
                                  disabled={isEditMode?true:false}
                                >
                                  {prizeTypes
                                    .filter((i) => i.status == 'ACTIVE')
                                    .map((i) => (
                                      <MenuItem value={i?.id}>
                                        {i?.prize_name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name="prize_type_id"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                                label="Name"
                                name="name"
                                value={values.name}
                                fullWidth
                                onChange={(e) => handleChangee(e)}
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={4}>
                            <MDBox pb={3}>
                              <div
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '1%',
                                  borderRadius: '6px',
                                  height: '49px',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  // className={classes.input}
                                  style={{ display: 'none' }}
                                  id="raised-button-file team-logo1"
                                  multiple
                                  type="file"
                                  name="banner_img_first"
                                  accept="image/*"
                                  onChange={(e) => handleImg(e)}
                                />
                                <label htmlFor="raised-button-file team-logo1">
                                  <Button
                                    type="button"
                                    variant="raised"
                                    component="span"
                                    onClick={() => logoClick('team-logo1')}
                                  >
                                    Upload Banner1
                                  </Button>
                                  <span style={{ fontSize: '11px' }}>
                                    {values.banner_img_first}
                                  </span>
                                </label>
                                {spinner.type == 'banner_img_first' &&
                                  spinner.payload && <ImgSpinner />}
                              </div>
                              <ErrorMessage
                                name="banner_img_first"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <MDBox pb={3}>
                              <div
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '1%',
                                  borderRadius: '6px',
                                  height: '49px',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  // className={classes.input}
                                  style={{ display: 'none' }}
                                  id="raised-button-file team-logo2"
                                  multiple
                                  type="file"
                                  name="banner_img_second"
                                  accept="image/*"
                                  onChange={(e) => handleImg(e)}
                                />
                                <label htmlFor="raised-button-file team-logo2">
                                  <Button
                                    type="button"
                                    variant="raised"
                                    component="span"
                                    onClick={() => logoClick('team-logo2')}
                                  >
                                    Upload Banner2
                                  </Button>
                                  <span style={{ fontSize: '10px' }}>
                                    {values.banner_img_second}
                                  </span>
                                </label>
                                {spinner.type == 'banner_img_second' &&
                                  spinner.payload && <ImgSpinner />}
                              </div>
                              <ErrorMessage
                                name="banner_img_second"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          {getPrizeTypeName(values) === 'Game Prize' && (
                            <Grid item xs={12} lg={4}>
                              <MDBox pb={3}>
                                <div
                                  style={{
                                    border: '1px solid #ccc',
                                    padding: '1%',
                                    borderRadius: '6px',
                                    height: '49px',
                                    display: 'flex',
                                  }}
                                >
                                  <input
                                    multiple
                                    style={{ display: 'none' }}
                                    id="raised-button-file team-logo3"
                                    type="file"
                                    name="banner_img_third"
                                    accept="image/*"
                                    onChange={(e) => handleImg(e)}
                                  />
                                  <label htmlFor="raised-button-file team-logo3">
                                    <Button
                                      type="button"
                                      variant="raised"
                                      component="span"
                                      onClick={() => logoClick('team-logo3')}
                                    >
                                      Upload Banner3
                                    </Button>
                                    <span style={{ fontSize: '11px' }}>
                                      {values.banner_img_third}
                                    </span>
                                  </label>
                                  {spinner.type == 'banner_img_third' &&
                                    spinner.payload && <ImgSpinner />}
                                </div>
                            
                                <ErrorMessage
                                  name="banner_img_third"
                                  component="span"
                                  className="error-message"
                                />
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <MDBox pb={3}>
                              <MDInput
                                fullWidth
                                label="Rules"
                                name="rules"
                                rows={5}
                                multiline
                                value={values.rules}
                                onChange={(e) => handleChangee(e)}
                              />
                              <ErrorMessage
                                name="rules"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            {' '}
                            <MDBox pb={3}>
                              {' '}
                              <MDInput
                                fullWidth
                                label="Email message"
                                name="email_message"
                                value={values.email_message}
                                // onChange={(e) => handleChangee(e)}
                                onChange={(e) =>
                                  handleChangeEmail(e, 100, /[^a-z,.\s]/gi)
                                }
                              />
                              <ErrorMessage
                                name="email_message"
                                component="span"
                                className="error-message"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        {['Bonus Prize'].includes(getPrizeTypeName(values)) && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    label="Pop Message"
                                    name="first_place_message"
                                    value={values.first_place_message}
                                    max={100}
                                    onChange={(e) =>
                                      setFieldValue(
                                        'first_place_message',
                                        e.target.value.slice(0, 100)
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="first_place_message"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {['Game Prize'].includes(getPrizeTypeName(values)) && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    label="1st place pop message"
                                    name="first_place_message"
                                    value={values.first_place_message}
                                    max={100}
                                    onChange={(e) =>
                                      setFieldValue(
                                        'first_place_message',
                                        e.target.value.slice(0, 100)
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="first_place_message"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {' '}
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    label="2nd place pop message"
                                    name="second_place_message"
                                    value={values.second_place_message}
                                    onChange={(e) =>
                                      setFieldValue(
                                        'second_place_message',
                                        e.target.value.slice(0, 100)
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="second_place_message"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    label="3rd place pop message"
                                    name="third_place_message"
                                    value={values.third_place_message}
                                    onChange={(e) =>
                                      setFieldValue(
                                        'third_place_message',
                                        e.target.value.slice(0, 100)
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="third_place_message"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <MDInput
                                    fullWidth
                                    label="4th place pop message"
                                    name="fourth_place_message"
                                    value={values.fourth_place_message}
                                    onChange={(e) =>
                                      setFieldValue(
                                        'fourth_place_message',
                                        e.target.value.slice(0, 100)
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="fourth_place_message"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} lg={6}>
                          <MDBox pb={3}>
                            {' '}
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label-team">
                                Default
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-team"
                                id="demo-simple-select"
                                value={values.is_default}
                                name="is_default"
                                label="Default"
                                onChange={(event) => handleChange(event)}
                                IconComponent={CustomExpandMore}
                              >
                                {/* <MenuItem value */}
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </FormControl>
                            <ErrorMessage
                              name="is_default"
                              component="span"
                              className="error-message"
                            />
                          </MDBox>
                        </Grid>
                        {['Monthly Prize', 'Season Prize'].includes(
                          getPrizeTypeName(values)
                        ) && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <MDBox pb={3}>
                                <MDInput
                                  fullWidth
                                  type="date"
                                  label="Start Date"
                                  name="start_date"
                                  InputLabelProps={{
                                    shrink: true,
                                    required: true,
                                  }}
                                  value={values.start_date}
                                  onChange={(e) => {
                                    handleChange(e)
                                    addMonth(e.target.value)

                                    // setFieldValue('end_date',monthyEndDate)
                                  }}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="span"
                                  className="error-message"
                                />
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDBox pb={3}>
                                <MDInput
                                  fullWidth
                                  label="End Date"
                                  name="end_date"
                                  type="date"
                                  maxDate={new Date()}
                                  minDate={values?.start_date}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={values.end_date}
                                  onChange={(e) => handleChange(e)}
                                />
                                <ErrorMessage
                                  type="date"
                                  name="end_date"
                                  component="span"
                                  className="error-message"
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        )}
                     
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                            disabled={state?.is_game_start==true?true:false
                            }
                          >
                            {isEditMode ? 'Update' : 'Save'}
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
    //   {/* <Footer /> */}
    // </DashboardLayout>
  )
}
