import * as actionTypes from "../actions/actionTypes";

const initialState = {
  socket_id: "",
};

const webSocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SOCKET_ID: {
      return {
        ...state,
        socket_id: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default webSocketReducer;
