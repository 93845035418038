import * as actionTypes from "./actionTypes";
import { toastData } from "../../../utils/toaster";
import { POST } from "../../../utils/apiService";
import { toast } from "react-toastify";

export const loginRequest = (data, callback, callBackError) => {
  return async (dispatch) => {
    let response = await POST("v1/admin/login", data);

    if (response.data.statusCode === 200) {
      dispatch({
        type: actionTypes.GET_LOGIN_DATA,
        payload: response,
      });
      localStorage.setItem("ACCESS_TOKEN", response.data.data.accessToken);
      localStorage.setItem("email", response.data.data.email);
      localStorage.setItem("user_type", response.data.data.user_type);
      localStorage.setItem("team_name", response.data.data.team_name);
      localStorage.setItem("user_id",response?.data?.data?.user_id)
      callback();
    } else {
      callBackError(response.data.message);
    }
  };
};
