import { Dashboard } from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "utils/token";
import { getUserType } from "utils/token";
import websocket from "../utils/webSocket" 

export default function CoreLayout(props) {
  useEffect(() => {
    if (
      getUserType() &&
      !["MASTER_ADMIN", "TEAM_ADMIN"].includes(getUserType())
    ) {
      logout();
    }
    websocket.on("response",(data)=>{
      if(data?.success==true){
        toast.success(data?.msg)
      }
     else{
      toast.error(data?.msg)
     }
    })
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Outlet />
    </DashboardLayout>
  );
}
