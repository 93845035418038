import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

export default function DeleteModal({ open, handleClose, handleAction, text }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDBox display="flex" justifyContent="flex-end">
            {/* <MDBox display="flex">
              <Icon fontSize="medium" onClick={() => navigate(-1)}>
                arrow_back_icon
              </Icon>
              &nbsp;&nbsp; */}
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Background
            </Typography> */}
            {/* </MDBox> */}
            <Icon onClick={handleClose} sx={{ cursor: "pointer" }}>
              close
            </Icon>
          </MDBox>

          <MDBox p={2}>
            <MDTypography variant="h5" sx={{ textAlign: "center" }}>
              {" "}
              {text}?{" "}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" pt={3} justifyContent="center">
            <MDButton color="info" onClick={handleAction} sx={{ width: "22%" }}>
              Yes
            </MDButton>
            &nbsp; &nbsp; &nbsp;
            <MDButton color="error" onClick={handleClose} sx={{ width: "22%" }}>
              No
            </MDButton>
          </MDBox>
        </Box>
      </Modal>
    </>
  );
}
