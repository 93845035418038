import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../App/store/actions";
import { connect } from "react-redux";
import BonusModal from "./BonusDetail/BonusModal";
import MDSpinner from "components/MDSpinner";
import DeleteModal from "components/DeleteModal";
import { toast } from 'react-toastify'
import { ToastHeader } from 'reactstrap'
import NoData from "components/NoData";
function Sports(props) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = React.useState(false);
  const [deleteId, setDeleteId] = useState(false);

  let { sportsList: { data: { results } = {} } = {} } = useSelector(
    (state) => state.sportListReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.getSportList((v) => setLoader(v)));
    localStorage.removeItem("sports_id")
    localStorage.removeItem('sports_value');
    localStorage.removeItem("sports_state");
    localStorage.removeItem("dd")
  }, []);

  const deleteSports = (id, status) => {
    dispatch(Actions.deleteSport(deleteId?.id, deleteId?.status));
    setDeleteId({});
  };

  const columns = [
    { Header: "Sports Name", accessor: "Sports" },
    { Header: "Action", accessor: "action" },
  ];

  const viewSport = (id) => {
    // viewSport(id)

  };

  if (loader) {
    return <MDSpinner />;
  }

  const rowsdata = results?.map((item, index) => ({
    Sports: item.name,
    action: (
      <MDBox display="flex">
        <BonusModal id={item.id} viewSport={viewSport} />

        {item?.is_game_start == false ? <Link to="/edit-sport" state={item}>
          {" "}
          <Icon fontSize="small">edit</Icon>
        </Link> :
          <Link to="#" state={item}>
            {" "}
            <Icon fontSize="small" onClick={() =>
              toast.error(`You can't edit sport that has game in progress`)

            }>edit</Icon>
          </Link>
        }

        {/* {item?.is_game_start == false ?( */}
        <Icon
          style={{ cursor: "Pointer" }}
          fontSize="small"
          onClick={() =>
            setDeleteId({
              id: item.id,
              status: `${item.status == "ACTIVE" ? "DELETED" : "ACTIVE"}`,
            })
          }
        >
          delete
        </Icon>
        {/* ) : <Icon
        style={{ cursor: "Pointer" }}
        fontSize="small"
        onClick={() =>
          toast.error(`You can't delete sport that has game in progress`)
        
        }
      >
        delete
      </Icon>} */}

      </MDBox>
    ),
  }));

  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {deleteId?.id && (
            <DeleteModal
              open={!!deleteId?.id}
              handleClose={() => setDeleteId({})}
              handleAction={() => deleteSports()}
              text={"Are you sure you want to delete this Sport"}
            />
          )}
          <MDBox p={2}>{
            rowsdata?.length > 0 ?
              <DataTable
                table={{
                  columns: columns,
                  rows: rowsdata,
                }}
                isSorted={false}
              /> :
              <NoData />
          }
          </MDBox>
        </Grid>
        <MDAddIcon link="/add-sports" color="inherit" font="sm" />
      </Grid>
    </MDBox>
  );
}
const mapDispatchToProps = {
  getSportList: Actions.getSportList,
  deleteSport: Actions.deleteSport,
};
const mapStateToProps = (state) => ({
  sportsList: state.sportListReducer.sportsList,
  deleteSport: state.sportListReducer.deleteSport,
});
export default connect(mapStateToProps, mapDispatchToProps)(Sports);
