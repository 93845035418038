import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Icon } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Select from "@mui/material/Select";
import "assets/styles.css";
import Switch from "@mui/material/Switch";
import { Paper } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../../App/store/actions";
import { connect } from "react-redux";
import store from "../../../../App/store";
import { useParams } from "react-router-dom";
import { updateBonus,deleteBonus } from "../../../../App/store/actions";
import CustomExpandMore from 'components/Expand'

function AddBonus(props) {
  const formikRef = useRef(null);
  const location = useLocation();
  let { state = {}, pathname } = location;

  const isEditMode = !!state?.id || false;
  const isbonus = !!state?.isbonus || false;

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  let { colorData = {} } = props;

  const mitiValue = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20,
  ];

  const handleAction = () => {
    formikRef.current.values.push({
      name: "",
      description: "",
      miti_point: "",
      time: "",
      color_id: "",
      // id: Math.random().toString().slice(0, 7),
    });
    formikRef.current.setFieldValue(formikRef.current.values);
  };

  const { sportData } = useSelector((state) => state.sportListReducer);

  useEffect(() => {
    dispatch(Actions.getBonusDetail(params.id));
    dispatch(Actions.colorList());
  }, []);

  const validationSchema = yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Name is required")
        .max(20, "Max 20 characters are allowed"),
      description: yup
        .string()
        .required("Description is required")
        .max(100, "Max 100 characters are allowed"),
      miti_point: yup.string().required("Miti point is required"),
      color_id: yup.string().required("Color is required"),
      time: yup.string().required("Time is required"),
    })
  );

  const initialValues = state?.bonus || [
    {
      name: "",
      description: "",
      miti_point: "",
      color_id: "",
      time: "",
      // id: Math.random().toString().slice(0, 7),
    },
  ];

  const handleChange = (e, i) => {
    let { name, value } = e.target;
    formikRef.current.values[i][name] = value;
    formikRef.current.setFieldValue(formikRef.current.values);
  };

  const handleSpecial = (e, i, l) => {
    let { name, value } = e.target;
    formikRef.current.values[i][name] = value
      .replace(/[^a-z0-9\s]+@/gi, "")
      .slice(0, l);
    formikRef.current.setFieldValue(formikRef.current.values);
  };

  const onSubmit = (values) => {
    values.map((i) => {
      // delete i['id'];
      delete i["sports_id"];
      delete i["status"];
      delete i["createdAt"];
      delete i["updatedAt"];
    });
    if (isbonus) {
      dispatch(updateBonus({ ...values[0], navigate }));
    } else {
      let data = { ...sportData, bonus: values };
      dispatch(Actions.SportList(data));
      navigate(isEditMode ? `/edit-action/${state?.id}` : "/add-action", {
        state: { ...state, bonus: values },
      });
    }
  };

  const deleted = (i,bonus_id,sports_id) => {
    formikRef.current.values.splice(i, 1)
    formikRef.current.setFieldValue(formikRef.current.values)
    dispatch(Actions.deleteBonus(sports_id,bonus_id))
  } 

  return (
    <MDBox mt={1}>
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex">
                    <Icon
                      fontSize="medium"
                      sx={{ paddingTop: "4px" }}
                      onClick={() => navigate(-1)}
                    >
                      arrow_back_icon
                    </Icon>
                    &nbsp;&nbsp;
                    <MDTypography mb={3} variant="h4">
                      {isEditMode
                        ? "Edit Bonus Button"
                        : isbonus
                        ? "Update Bonus Button"
                        : "Add Bonus Button"}
                    </MDTypography>
                  </MDBox>
                  {!isbonus && (
                    <MDBox p={3} display="flex" justifyContent="flex-end">
                      <MDButton color="info" onClick={handleAction}>
                        Add More
                      </MDButton>
                    </MDBox>
                  )}
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form>
                        {values.map((item, index) => (
                          <>
                            {index > 0 && (
                              <Icon
                                style={{
                                  marginLeft: "auto",
                                  cursor: "pointer",
                                  float: "right",
                                }}
                                onClick={() => deleted(item,item?.id,item?.sports_id)}
                              >
                                delete
                              </Icon>
                            )}
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                {" "}
                                <MDBox pb={3}>
                                  <MDInput
                                    autoComplete="off"
                                    label="Name"
                                    value={values[index].name}
                                    name="name"
                                    onChange={(e) =>
                                      handleSpecial(e, index, 20)
                                    }
                                    fullWidth
                                  />
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.name}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <MDInput
                                    label="Description"
                                    value={values[index].description}
                                    name="description"
                                    onChange={(e) =>
                                      handleSpecial(e, index, 100)
                                    }
                                    autoComplete="off"
                                    fullWidth
                                  />
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.description}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                {" "}
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Miti point value
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={values[index].miti_point}
                                      label="Miti Point Value"
                                      name="miti_point"
                                      onChange={(e) => handleChange(e, index)}
                                      IconComponent={CustomExpandMore}
                                    >
                                      {mitiValue?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.miti_point}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Time
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Time"
                                      value={values[index].time}
                                      name="time"
                                      onChange={(e) => handleChange(e, index)}
                                      IconComponent={CustomExpandMore}
                                    >
                                      {mitiValue?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.time}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={12}>
                                {" "}
                                <MDBox pb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Color
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={values[index].color_id}
                                      label="Color"
                                      name="color_id"
                                      onChange={(e) => handleChange(e, index)}
                                      IconComponent={CustomExpandMore}
                                    >
                                      {colorData?.data?.results?.slice(0,2)?.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            id={item.id}
                                          >
                                            {item.color_name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                  {errors[index] && touched[index] && (
                                    <span className="error-message">
                                      {errors[index]?.color_id}
                                    </span>
                                  )}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            type="submit"
                          >
                            {isbonus ? "Update Bonus" : "Continue"}
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
const mapDispatchToProps = {
  colorList: Actions.colorList,
  getBonusDetail: Actions.getBonusDetail,
};
const mapStateToProps = (state) => ({
  colorData: state.colorlistReducer.colorData,
  bonusData: state.sportListReducer.bonusData,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddBonus);
