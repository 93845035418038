import React, { useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Icon, Paper, TextField } from '@mui/material'
import MDAddIcon from 'components/MDAddIcon/Index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useDispatch } from 'react-redux'
import createTeamAdmin from 'App/store/actions/team.actions'
import { getUserTeamAdminList } from 'App/store/actions/users.actions'

export default function TeamAdminModal({ open, handleClose }) {
  const formikRef = useRef()
  const dispatch = useDispatch()
  const params = useParams()

  const initialValues = {
    team_id: params?.team_id,
    name: '',
    email: '',
    password: '',
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .max(30, 'Max 30 characters are allowed'),
    email: yup
      .string()
      .email('Invalid Email')
      .required('Email is required')
      .max(50, 'Max 50 characters are allowed'),
    password: yup
      .string()
      .required('Password is required')
      .max(20, 'Max 20 characters are allowed')
      .min(6, 'Min 6 characters are allowed'),
  })

  const handleChange = (e, regex, l) => {
    let { name, value } = e.target
    formikRef.current.setFieldValue(name, value.replace(regex, '').slice(0, l))
  }

  const handleSubmit = (values) => {
    // values['created_by']=localStorage.getItem("user_type")
    dispatch(
      createTeamAdmin(values, () => {
        handleClose()
        dispatch(getUserTeamAdminList(params?.team_id))
      })
    )
  }

  return (
    <>
      <Modal
        className="team-admin-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox mt={1} className="data">
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <MDBox p={2}>
                  <Card>
                    <MDBox p={3}>
                      <MDBox display="flex" justifyContent="space-between">
                        <MDTypography my={3} variant="h5">
                          Add Team Admin
                        </MDTypography>
                        <MDBox>
                          <Icon
                            onClick={handleClose}
                            sx={{ cursor: 'pointer' }}
                          >
                            close
                          </Icon>
                        </MDBox>
                      </MDBox>
                      <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleSubmit(values)}
                      >
                        {({ errors, touched, values, setFieldValue }) => (
                          <Form>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                {' '}
                                <MDBox pb={1}>
                                  <TextField
                                    label="Name"
                                    fullWidth
                                    name="name"
                                    inputProps={{
                                      maxLength: 30,
                                    }}
                                    autoComplete="off"
                                    value={values?.name}
                                    onChange={(e) =>
                                      handleChange(e, /[^a-z\s]/gi, 30)
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {' '}
                                <MDBox pb={3}>
                                  <TextField
                                    type="email"
                                    label="Email Address"
                                    fullWidth
                                    name="email"
                                    inputProps={{
                                      maxLength: 50,
                                    }}
                                    autoComplete="off"
                                    value={values?.email}
                                    onChange={(e) => handleChange(e, / /, 50)}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={12}>
                                {' '}
                                <MDBox pb={3}>
                                  <TextField
                                    type="password"
                                    label="Password"
                                    fullWidth
                                    autoComplete="off"
                                    name="password"
                                    inputProps={{
                                      maxLength: 20,
                                      minLength: 6,
                                    }}
                                    value={values?.password}
                                    onChange={(e) => handleChange(e, / /, 20)}
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="span"
                                    className="error-message"
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>

                            <MDBox
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              mt={3}
                            >
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                size="large"
                              >
                                Save
                              </MDButton>
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Modal>
    </>
  )
}
