import * as actionTypes from '../actions/actionTypes'

const initialState = {
  sportsList: {},
 actionList: {},
 createSportItem: {},
  createSport_Item: {},
  deleteSport: {},
  sportData: {},
  bonusData: {},
  editData: {},
  actionLoader:false,
  bonusLoader:false,
  actionImageList:{},
  createActionImageItem: {},
  deleteActionImage: {},
}

const sportListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SPORT_LIST: {
      return {
        ...state,
        sportsList: action.payload,
      }
    }
    case actionTypes.CREATE_SPORTS: {
      return {
        ...state,
        createSportItem: action.payload,
      }
    }
    case actionTypes.CREATE_SPORT: {
      return {
        ...state,
        createSport_Item: action.payload,
      }
    }
    case actionTypes.DELETE_SPORT: {
      return {
        ...state,
        blockStatus: action.payload,
      }
    }
    case actionTypes.SAVE_SPORT: {
      return {
        ...state,
        sportData: action.payload,
      }
    }
    case actionTypes.SAVE_ACTION: {
      return {
        ...state,
        actionList: action.payload,
      }
    }
    case actionTypes.GET_BONUS: {
      return {
        ...state,
        bonusData: action.payload,
      }
    }
    case actionTypes.EDIT_SPORT: {
      return {
        ...state,
        bonusData: action.payload,
      }
    }
    case actionTypes.GET_ACTION_IMAGE_LIST: {
      return {
        ...state,
        actionImageList: action.payload,
      }
    }
    case actionTypes.CREATE_ACTION_IMAGE: {
      return {
        ...state,
        createActionImageItem: action.payload,
      }
    }
    case actionTypes.DELETE_ACTION_IMAGE: {
      return {
        ...state,
        blockStatus: action.payload,
      }
    }
    // case actionTypes.EDIT_ACTION_IMAGE: {
    //   return {
    //     ...state,
    //     bonusData: action.payload,
    //   }
    // }
    default: {
      return state
    }
  }
}

export default sportListReducer
