

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
// Material Dashboard 2 React example components
import DataTable from 'examples/Tables/DataTable'
import { Icon } from '@mui/material'
import MDAddIcon from 'components/MDAddIcon/Index'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTeamList } from 'App/store/actions'
import MDSpinner from 'components/MDSpinner'
import { deleteTeam } from 'App/store/actions'
import DeleteModal from 'components/DeleteModal'
import { getUserType } from 'utils/token'
import { toast } from 'react-toastify'
const columns = [
  {
    Header: 'Name',
    accessor: 'Name',
    width: '20%',
  },
  {
    Header: 'URL Name',
    accessor: 'URLName',
    width: '20%',
  },
  { Header: 'Slug', accessor: 'Slug', width: '20%' },
  { Header: 'Sport', accessor: 'Sports', width: '20%' },
  { Header: 'Action', accessor: 'action', width: '20%' },
]

function Teams() {
  const dispatch = useDispatch()

  const { teamList, teamLoader } = useSelector((state) => state.teamReducer)
  const [delete_id, setDeleteId] = useState('')

  const user = getUserType() || ''

  useEffect(() => {
    dispatch(getTeamList())
  }, [])

  const removeTeam = () => {
    dispatch(deleteTeam(delete_id
      // ,localStorage.getItem("user_id")
    ))
    setDeleteId('')
  }

  const rows = teamList.map((i) => {
    return {
      Name: i.name,
      URLName: i.url,
      Slug: i.slug,
      Sports: i?.sport?.name || '--',
      action: (
        <div>
          <Link
            to={`/view-team/${i.id}/${user === 'MASTER_ADMIN' ? 'customize' : 'team-admin'
              }`}
            onClick={() => localStorage.setItem('slug', i?.slug)}
          >
            <Icon fontSize="small">visibility_icon</Icon>
          </Link>{' '}
          {i?.is_game_start === false || !i?.is_game_start ? <Link to={`/edit-team/${i.id}`} state={i}>
            <Icon fontSize="small">edit</Icon>
          </Link> :
            <Link to="#">
              {" "}
              <Icon fontSize="small" onClick={() =>

                toast.error(`You can't edit team that has game in progress`)

              }>edit</Icon>
            </Link>}

          {i?.is_game_start === false || !i?.is_game_start ? (
            <Link to="#" onClick={() => setDeleteId(i.id)}>
              <Icon fontSize="small">delete</Icon>
            </Link>) : <Icon
              style={{ cursor: "Pointer" }}
              fontSize="small"
              onClick={() =>
                toast.error(`You can't delete team that has game in progress`)

              }
            > delete
          </Icon>}
        </div>
      ),
    }
  })

  if (teamLoader) {
    return <MDSpinner />
  }

  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {delete_id && (
            <DeleteModal
              open={!!delete_id}
              handleAction={() => removeTeam()}
              handleClose={() => setDeleteId('')}
              text="Are you sure you want to delete this Team"
            />
          )}
          <MDBox p={2}>
            <DataTable
              table={{
                columns: columns,
                rows: rows,
              }}
            />
          </MDBox>
        </Grid>
        <MDAddIcon link="/add-teams" color="inherit" font="sm" />
      </Grid>
    </MDBox>
  )
}

export default Teams
