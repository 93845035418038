import * as actionTypes from './actionTypes'
import { toastData } from '../../../utils/toaster'
import { DELETE, GET, POST, PUT } from '../../../utils/apiService'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getSportList = (callback) => {
  callback(true)
  const request = GET(`/v1/admin/sports`)
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SPORT_LIST,
          payload: response.data,
        })
        callback(false)
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        callback(false)
        toastData(err.response)
      })
}

export const createSport = (data,navigate) => {
  if (!data.id) {
    delete data['id']
  }
  return async (dispatch, getState) => {
    let response = await POST('/v2/admin/createsportV2', data, {})
    if (response.data.statusCode === 200) {
      toast.success(response.data.message)
      setTimeout(() => {
        navigate('/sports')
      }, 3000)
    } else {
      toast.error(response.data.message)
    }
  }
}

export const deleteSport = (sports_id, status) => {
  return async (dispatch, getState) => {
    let response = await DELETE(
      `v1/admin/deleteSports`,
      {},
      { sports_id: sports_id, status: status }
    )
    if (response.data.statusCode === 200) {
      let { sportsList = {} } = getState().sportListReducer
      let index = sportsList.data.results.findIndex((i) => i.id === sports_id)
      sportsList.data.results.splice(index, 1)
      dispatch({ type: actionTypes.GET_SPORT_LIST, payload: sportsList })
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }
}

export const SportList = (data) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.SAVE_SPORT,
      payload: data,
    })
}
export const ActionList = (data) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.SAVE_ACTION,
      payload: data,
    })
}
export const editSportList = (data) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.EDIT_SPORT,
      payload: data,
    })
}

export const getBonusDetail = (id) => {
  const request = GET(`/v1/admin/sportsDetails?id=${id}`)
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: actionTypes.GET_BONUS,
          payload: response.data,
        })
        // callback()
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        // callbackError()
        toastData(err.response)
      })
}

export const editSport = (data) => {
  // data.sports_id = data.id
  delete data['id']
  return async (dispatch) => {
    let response = await PUT('/v1/admin/editSports', data, {})
    if (response.data.statusCode === 200) {
      toast.success(response.data.message)
      data.navigate('/sports')
    } else {
      toast(response.data.message)
    }
  }
}

export const updateBonus = (data) => {
  if (data.color) {
    delete data.color
  }
  return async (dispatch) => {
    let response = await PUT('v1/admin/updateBonus', data, {})
    if (response.data.statusCode === 200) {
      // onSuccess();
      toast.success('Bonus updated succesfully')
      data.navigate(-1)
    } else {
      toast.error(response.data.message)
    }
  }
}

export const deleteAction = (sports_id,action_id, status) => {
  return async (dispatch, getState) => {
    let response = await PUT(
      `v1/admin/deleteSportsActionButton`,
      {
        sports_id:sports_id,
        actionButtonId:[{
          id:action_id
        }]
      },    
    )
    if (response.data.statusCode === 200) {
      dispatch({ type: actionTypes.DELETE_ACTION, payload: response })
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }
}
export const deleteBonus = (sports_id,bonus_id, status) => {
  return async (dispatch, getState) => {
    let response = await PUT(
      `v1/admin/deleteBonusButton`,
      {
        sports_id:sports_id,
        bonusButtonId:[{
          id:bonus_id
        }]
      },    
    )
    if (response.data.statusCode === 200) {
      dispatch({ type: actionTypes.DELETE_BONUS, payload: response })
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }
}
export const updateAction = (data) => {
  return async (dispatch) => {
    let response = await PUT('v1/admin/updateAction', data, {})
    if (response.data.statusCode === 200) {
      data.navigate(-2)
      toast.success('Action updated successfully')
    } else {
      toast.error(response.data.message)
    }
  }
}


//ACTION IMAGE ACTION
export const getActionImageList = (sports_id,callback) => {
  callback(true)
  const request = GET(`/v2/admin/getActionImage?sport_id=${sports_id}`)
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ACTION_IMAGE_LIST,
          payload: response.data,
        })
        callback(false)
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        callback(false)
        toastData(err.response)
      })
}
export const createActionImage = (data,navigate) => {
  // if (!data.id) {
  //   delete data['id']
  // }
  return async (dispatch, getState) => {
    let response = await POST('/v2/admin/addImage', data, {})
    if (response.data.statusCode === 200) {
      toast.success(response.data.message)
      setTimeout(() => {
        navigate('/action-image')
      }, 1000)
    } else {
      toast.error(response.data.message)
    }
  }
}
export const editActionImage= (data,navigate) => {
  data.sports_id = data.id
  delete data['id']
  return async (dispatch) => {
    let response = await PUT('/v2/admin/editActionImage', data, {})
    if (response.data.statusCode === 200) {
      toast.success(response.data.message)
     navigate('/action-image')
    } else {
      toast(response.data.message)
    }
  }
}

export const deleteActionImage = (id) => {
  return async (dispatch, getState) => {
    let response = await PUT(
      `v2/admin/deleteActionImage?action_id=${id}   `,
      {},
      { action_id: id }
    )
    if (response.data.statusCode === 200) {
      let { actionImageList = {} } = getState().sportListReducer
      let index = actionImageList.data.findIndex((i) => i.id === id)    
       actionImageList.data.splice(index, 1)
      dispatch({ type: actionTypes.GET_ACTION_IMAGE_LIST, payload: actionImageList })
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }
}



export const createSports = (data) => {
  if (!data.id) {
    delete data['id']
  }
  data['status']='PENDING'
  return async (dispatch, getState) => {
    let response = await POST('/v2/admin/createSports', data, {})
    if (response.data.statusCode === 200) {
      // toast.success(response.data.message)
      localStorage.setItem("sports_id",response?.data?.data?.id)
      // setTimeout(() => {
      //   // data.navigate('/sports')
      // }, 3000)
    } else {
      toast.error(response.data.message)
    }
  }
}
