import React, { useState, useEffect, useLayoutEffect } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Card, Paper, Icon } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import { getGameDetail } from 'App/store/actions'
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MDBox from 'components/MDBox'
import 'assets/styles.css'
import MDSpinner from 'components/MDSpinner'
import socket from 'utils/webSocket'
import Switch from '@mui/material/Switch'
import { toast } from 'react-toastify'
import { DeviceUUID } from 'device-uuid'
import Clocks from "./clock.js"
export default function Score() {
  const params = useParams()
  let [captainIndex, setCaptainIndex] = useState(
    Number(localStorage.getItem(`captain_index_${params?.id}`)) <= 3 &&
      localStorage.getItem('is_end_turn') === 'true'
      ? Number(localStorage.getItem(`captain_index_${params?.id}`))
      : Number(localStorage.getItem(`captain_index_${params?.id}`)) <= 3
        ? Number(localStorage.getItem(`captain_index_${params?.id}`))
        : 0
  )
  const [late_game_points, setLateGamePoints] = useState(false)
  const [acces, setAcces] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { gameData,scoreLoader } = useSelector((state) => state.gameReducer)
  const [last_play, setlast_play] = useState('')
  const [date, setDate] = React.useState(new Date())
  const [check, setCheck] = useState(0)
  const [undoCount, setUndoCount] = useState(0)
 
  let gameNewObj = {}
  // useEffect(() => {
  //   gameNewObj = gameData?.data
  // },[]);


  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // window.location.reload()   
      if (localStorage.getItem(`temp_${params?.id}`) == "true") {
        setAcces(true)
      }
    }
  };


  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);
  let device_id = new DeviceUUID().get()
  useEffect(() => {
    dispatch(getGameDetail(params.id))
    let payload = {
      game_id: params?.id,
      user_id: localStorage.getItem("user_id"),
      device_id
    }
    if (localStorage.getItem(`temp_${params?.id}`) == "true") {
      payload["temp"] = true
    }
    if (!localStorage.getItem(`temp_${params?.id}`)) {
      socket.emit('admin-view-page', payload)
    }

    socket.on(`admin-view-score-${params?.id}`, (data) => {
      if ((data?.device_id === device_id) && (data?.temp === true)) {
        setAcces(true)
        localStorage.setItem(`temp_${params?.id}`, true)

      }
      else {
        if ((localStorage.getItem(`temp_${params?.id}`) === "true") && (data?.device_id === device_id)) {
          setAcces(true)

        }
        else {
          setAcces(false)
          localStorage.setItem(`temp_${params?.id}`, false)

        }


      }
    })
    return () => {
      // if(sessionStorage.getItem("score")!="1"){

      const payload = {
        game_id: params?.id,
      }
      socket.emit('admin-view-page-remove', payload)
      localStorage.removeItem(`temp_${params?.id}`)
      socket.on(`admin-view-page-remove-${params?.id}`, (data) => {
      });
      // }
      localStorage.removeItem(`temp_${params?.id}`)
      localStorage.removeItem('selected-captain')
      // sessionStorage.removeItem(`point${params?.id}`)
    }
  }, [])
  //   let loadMore=()=>{
  //     if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
  //        return <MDSpinner/>

  //     }
  //   }
  //   useEffect(()=>{

  //     window.addEventListener('scroll', loadMore());
  // },[])

  useEffect(() => {
    //rotate();
    if (localStorage.getItem('is_end_turn') === 'true') {
      setTimeout(() => {

        //setCaptainIndex(localStorage.getItem(`captain_index_${params?.id}`) <= 3 ? Number(localStorage.getItem(`captain_index_${params?.id}`))+1 : 0);
        // if(Number(localStorage.getItem(`captain_index_${params?.id}`)) <= 3){

        //   setCaptainIndex(Number(localStorage.getItem(`captain_index_${params?.id}`)))
        // }else{

        //   setCaptainIndex(0)
        // }
        setCheck(1)
        rotate()
      }, 100)
    } else {
      setCheck(0)
    }
    // return () => {
    //   localStorage.setItem('is_end_turn',false)
    // }
  }, [])

  useEffect(() => {
    if (Number(localStorage.getItem(`captain_index_${params?.id}`)) == 0) {
      setTimeout(() => {
        let payload = {
          captain_id: gameData?.games_captians[captainIndex]?.captain?.id,
          game_id: params?.id,
          team_id: gameData?.team?.id,
        }
        localStorage.setItem(
          'select-captain',
          payload.game_id + payload?.captain_id
        )
        // socket.emit('select-captain', payload)
      }, 100)
    }
  }, [gameData])


  let hour = date?.toLocaleTimeString()
  const timeString = hour
  // Prepend any date. Use your birthday.
  const timeString12hr = new Date(
    '1970-01-01T' + timeString + 'Z'
  ).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
  const rotate = () => {

    //gameData.games_captians[captainIndex]['class'] = ''

    if (gameData?.games_captians.length == captainIndex + 1) {
      captainIndex = 0
    } else {
      captainIndex++
    }

    localStorage.setItem(`captain_index_${params?.id}`, captainIndex)
    gameData.games_captians[captainIndex]['class'] = 'captain-selected'
    setCaptainIndex(captainIndex)
    let payload = {
      captain_id: gameData.games_captians[captainIndex]?.captain?.id,
      game_id: params?.id,
      team_id: gameData?.team?.id,
    }


    localStorage.setItem('select-captain', payload.game_id + payload?.captain_id)
    //localStorage.setItem('is_end_turn',false);
    socket.emit('select-captain', payload)
    dispatch(getGameDetail(params?.id))

  }
  const undo = () => {
    if (localStorage.getItem('is_end_turn') === 'true' && undoCount == 0) {
      if (captainIndex <= 3 && captainIndex !== 0) {
        captainIndex--
      } else if (captainIndex == 0) {
        captainIndex = 3
      }

      let payloadForUndoRot = {
        captain_id: gameData?.games_captians[captainIndex]?.captain.id,
        game_id: params?.id,
        team_id: gameData?.team?.id,
      }


      localStorage.setItem(`captain_index_${params?.id}`, captainIndex)
      gameData.games_captians[captainIndex]['class'] = 'captain-selected'
      setCaptainIndex(captainIndex)
      localStorage.setItem(
        'select-captain',
        payloadForUndoRot.game_id + payloadForUndoRot.captain_id
      )
      // localStorage.setItem('is_end_turn','false')
      socket.emit('select-captain', payloadForUndoRot)
      setUndoCount(1)
    }

    let payload = {
      captain_id: gameData.games_captians[captainIndex].captain.id,
      game_id: params?.id,
      team_id: gameData?.team?.id,
      type: 'undo',
    }
    socket.emit('bonus-trigger', payload)

    socket.emit('play-by-play', params?.id, {
      type: 'undo',
    })
    // window.location.reload()

    dispatch(getGameDetail(params?.id))
    // undo captain

    // let payload1 = {
    //   captain_id: gameData.games_captians[captainIndex].captain.id,
    //   game_id: params?.id,
    //   team_id: gameData?.team?.id
    // }

    // if(captainIndex <= 3 && captainIndex !== 0){
    //   captainIndex--;
    // }else if(captainIndex == 0){
    //   captainIndex=3
    // }

    // localStorage.setItem(`captain_index_${params?.id}`, captainIndex)
    // gameData.games_captians[captainIndex]['class'] = 'captain-selected'
    // setCaptainIndex(captainIndex)
    // localStorage.setItem('select-captain', payload1.game_id + payload1.captain_id)
    // socket.emit('select-captain', payload1)

    //undo captain end


  }
  const endGame = () => {
    const payload = {
      game_id: params?.id,
      team: gameData?.team?.id,
      end: true,
    }
    socket.emit('end-game', payload)
    window.location.href = '/game'
    // navigate('/game')
  }
  const away = (id, lategamepoints, index) => {
    const payload = {
      game_id: params?.id,
      team: gameData?.team?.id,
      action_id: id,
      late_game_point: !!late_game_points === true ? lategamepoints : 0,

      // slug: gameData?.opponent_slug,
    }
    socket.emit('away-team', payload)
    toast.success('Point send successfully')
    sessionStorage.setItem(
      `point${params?.id}`,
      gameData?.team?.sport?.actions[index]?.ply_by_ply_description
    )
  }
  const home = (id, index) => {
    const payload = {
      game_id: params?.id,
      team: gameData?.team?.id,
      action_id: id,
      late_game_points: late_game_points,
      is_win_trigger: gameData?.team?.sport?.actions[index]?.is_win_trigger,
    }
    if (gameData?.team?.sport?.actions[index]?.is_end_turn == true) {
      rotate()
    } else {

    }
    socket.emit('home-team', payload)

  }


  if (scoreLoader) {
    return <MDSpinner />
  }

  const aways = (gameid, teamid, awayteamscore) => {
    const payload = {
      game_id: gameid,
      team: teamid,
      awayteamscore: awayteamscore,
    }
    socket.emit('away-team', payload)
    toast.warn('Point1111 send successfully')


  }
  const handleChecked = (gameid, teamid, awayteamscore) => {
    setLateGamePoints(!late_game_points)
    localStorage.setItem(`late_${params?.id}`, !late_game_points)
  }


  return (
    <Box mt={1}  >
      {console.log("render")}
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2}>
              {/* <Card> */}
              <MDBox p={3}>
                <MDBox display="flex">
                  <Icon
                    fontSize="medium"
                    sx={{ paddingTop: '5px' }}
                    onClick={() => navigate('/game')}
                  >
                    arrow_back_icon
                  </Icon>
                  &nbsp;&nbsp;
                  <MDTypography mb={3} variant="h4">
                    Score
                  </MDTypography>
                </MDBox>
              </MDBox>
              {/* {acces==true ? <div></div>:<div style={{color:"red",textAlign:"center"}}> Multiple admins can not access the score page at a time.</div>}  */}

              <Grid container spacing={2}>
                <Grid lg={2} md={12} xs={12}></Grid>
                <Grid lg={8} md={12} xs={12}>
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >

                      <MDBox p={3}>
                        <MDTypography
                          variant="h6"
                          verticalAlign="middle"
                          style={{ textAlign: 'center' }}
                        >
<Clocks/>
                        </MDTypography>
                        <MDBox p={3} display="flex" justifyContent="center">
                          <MDBox p={2}>
                            <MDTypography
                              variant="h3"
                              textTransform="uppercase"
                              pr={4}
                            >
                              {/* {gameData?.home_team_score}  */}
                              {gameData?.team?.slug}{' '}
                            </MDTypography>
                          </MDBox>
                          <MDBox p={2}>
                            {' '}
                            <MDTypography
                              variant="h3"
                              textTransform="uppercase"
                            >
                              {' '}
                              {gameData?.opponent_slug}{' '}
                              {/* {gameData?.away_team_score} */}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid lg={2} md={12} xs={12}></Grid>
                <Grid lg={8} md={12} xs={12}>
                  {' '}
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}>
                      <MDBox p={3}>
                        <Grid container={true} spacing={2}>
                          {/* For Ist Player */}

                          <Grid lg={6} md={6} xs={6}>
                            <MDBox p={2} style={{ textAlign: 'center' }}>
                              {' '}
                              <MDTypography
                                variant="h4"
                                textTransform="uppercase"
                                style={{ color: `${gameData?.games_captians?.[0]?.is_seleted == true ? "blue" : "black"}` }}
                              >{gameData?.games_captians?.[0]?.captain?.last_name}   {gameData?.games_captians?.[0]?.miti_point}{' '}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid lg={6} md={6} xs={6}>
                            <MDBox p={2} style={{ textAlign: 'center' }}>
                              {' '}
                              <MDTypography
                                variant="h4"
                                textTransform="uppercase"
                                style={{ color: `${gameData?.games_captians?.[1]?.is_seleted == true ? "blue" : "black"}` }}
                              >{gameData?.games_captians?.[1]?.captain?.last_name}   {gameData?.games_captians?.[1]?.miti_point}{' '}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid lg={6} md={6} xs={6}>
                            <MDBox p={2} style={{ textAlign: 'center' }}>
                              {' '}
                              <MDTypography
                                variant="h4"
                                textTransform="uppercase"
                                style={{ color: `${gameData?.games_captians?.[3]?.is_seleted == true ? "blue" : "black"}` }}
                              >{gameData?.games_captians?.[3]?.captain?.last_name}   {gameData?.games_captians?.[3]?.miti_point}{' '}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid lg={6} md={6} xs={6}>
                            <MDBox p={2} style={{ textAlign: 'center' }}>
                              {' '}
                              <MDTypography
                                variant="h4"
                                textTransform="uppercase"
                                style={{ color: `${gameData?.games_captians?.[2]?.is_seleted == true ? "blue" : "black"}` }}
                              >{gameData?.games_captians?.[2]?.captain?.last_name}   {gameData?.games_captians?.[2]?.miti_point}{' '}
                              </MDTypography>
                            </MDBox>
                          </Grid>

                        </Grid>
                      </MDBox>
                    </Card>

                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid lg={2} md={12}></Grid>
                <Grid lg={8} md={12} xs={12}>
                  {' '}
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >
                      <MDBox p={3}>
                        <MDTypography variant="h4" align="center">
                          {sessionStorage.getItem(`point${params?.id}`)
                            ? sessionStorage.getItem(`point${params?.id}`)
                            : 'Last Play Section'}
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid lg={2} md={12} xs={12}></Grid>
                <Grid lg={4} md={12} xs={12}>
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >
                      <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                      >
                        <MDTypography variant="h4" align="center">
                          {gameData?.team?.slug}
                        </MDTypography>
                        &nbsp; &nbsp;
                        <MDTypography
                          variant="subtitle1"
                          align="center"
                          verticalAlign="middle"
                        >
                          ( Home)
                        </MDTypography>
                      </MDBox>
                      {gameData?.team?.sport?.actions?.map((i, index) => (
                        <>
                          {/* <div>
                                {(i.is_home_team === true &&
                                  i?.is_win_trigger == false) ||
                                i.is_end_turn == false ? (
                                  <MDBox p={2}>
                                    <MDButton
                                      color="info"
                                      size="medium"
                                      onClick={() => home(i.id, index)}
                                      // disabled={acces == false ? true : false}
                                      // onClick={() =>
                                      //   navigate(`/score/${params.id}/${i.id}`, {
                                      //     state: {
                                      //       ...gameData,
                                      //       current_action: i,
                                      //       late_game_points,
                                      //     },
                                      //   })
                                      // }
                                      fullWidth
                                    >
                                      {i?.name}
                                    </MDButton>
                                  </MDBox>
                                ) : (
                                  ''
                                )}
                              </div> */}
                          <div>
                            {i.is_home_team === true ? (
                              <MDBox p={2}>
                                <MDButton
                                  style={{
                                    backgroundColor: `${i?.color?.color_code}`,
                                    color: '#fff',
                                  }}
                                  size="medium"
                                  // onClick={() => home(i.id, index)}
                                  // disabled={acces == false ? true : false}
                                  onClick={() =>
                                    navigate(`/score/${params.id}/${i.id}`, {
                                      state: {
                                        ...gameData,
                                        current_action: i,
                                        ...gameData?.games_captians[
                                        captainIndex
                                        ],
                                        late_game_points: localStorage.getItem(`late_${params?.id}`) == "true" ? true : late_game_points,
                                      },
                                    })
                                  }
                                  fullWidth
                                >
                                  {i?.name}
                                </MDButton>
                              </MDBox>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      ))}
                    </Card>
                  </MDBox>
                </Grid>

                <Grid lg={4} xs={12}>
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >
                      <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                      >
                        <MDTypography variant="h4" align="center">
                          {gameData?.opponent_slug}
                        </MDTypography>
                        &nbsp; &nbsp;
                        <MDTypography
                          variant="subtitle1"
                          align="center"
                          verticalAlign="middle"
                        >
                          (Away)
                        </MDTypography>
                      </MDBox>

                      <div>
                        {' '}
                        {gameData?.team?.sport?.actions?.map((i, index) => (
                          <div>
                            {i.is_home_team === false ? (
                              <MDBox p={2}>
                                <MDButton
                                  style={{
                                    backgroundColor: `${i?.color?.color_code}`,
                                    color: '#fff',
                                  }}
                                  size="medium"
                                  // disabled={acces == false ? true : false}
                                  onClick={() =>
                                    // away(i.id, i?.late_game_point, index)
                                    navigate(`/score/${params.id}/${i.id}`, {
                                      state: {
                                        ...gameData,
                                        current_action: i,
                                        ...gameData?.games_captians[
                                        captainIndex
                                        ],
                                        late_game_points: localStorage.getItem(`late_${params?.id}`) == "true" ? true : late_game_points,

                                      },
                                    })
                                  }
                                  // onClick={() =>
                                  //   navigate(`/score/${params.id}/${i.id}`, {
                                  //     state: {
                                  //       ...gameData,
                                  //       current_action: i,
                                  //       late_game_points,
                                  //     },
                                  //   })
                                  // }
                                  fullWidth
                                >
                                  {i?.name}
                                </MDButton>
                              </MDBox>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                      </div>
                    </Card>
                  </MDBox>
                </Grid>
                <Grid lg={2} xs={12}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid lg={2} xs={12}></Grid>
                <Grid lg={8} xs={12}>
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >
                      <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                      >
                        <MDTypography variant="h4" align="center">
                          BONUS
                        </MDTypography>
                      </MDBox>
                      <Grid container>
                        <Grid lg={3}></Grid>
                        <Grid lg={6} xs={12}>
                          {gameData?.games_bonus?.map((i) => (
                            <MDBox p={2} key={i.id}>
                              <MDButton
                                style={{
                                  backgroundColor: 'green',
                                  color: '#fff',
                                  width: '100%',
                                }}
                                // disabled={acces == false ? true : false}
                                size="large"
                                onClick={() =>
                                  navigate(
                                    `/score/${params?.id}/bonus/${i.id}`,
                                    {
                                      state: {
                                        ...gameData,
                                        current_action: i,
                                        ...gameData?.games_captians[
                                        captainIndex
                                        ],
                                      },
                                    }
                                  )
                                }
                                fullWidth
                              >
                                {i?.prize?.name}
                              </MDButton>
                            </MDBox>
                          ))}
                        </Grid>
                        <Grid lg={3}></Grid>
                      </Grid>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid lg={2} xs={12}></Grid>
                <Grid lg={8} xs={12}>
                  <MDBox p={3}>
                    <Card
                      variant="outlined"
                      style={{ boxShadow: "none", borderColor: "white" }}
                    >
                      <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                      >
                        <MDBox p={2}>
                          {' '}
                          <MDButton
                            color="info"
                            // disabled={acces == false ? true : false}
                            onClick={() => undo()}
                            size="large"
                            fullWidth
                          >
                            UNDO
                          </MDButton>
                        </MDBox>

                        <MDBox p={2}>
                          <MDButton
                            color="info"
                            size="large"
                            fullWidth
                            // disabled={acces == false ? true : false}
                            onClick={() => rotate()}
                          >
                            Rotate
                          </MDButton>
                        </MDBox>
                      </MDBox>

                      <MDBox p={2}>
                        <MDBox
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="h4" align="center">
                            Late Game Points
                          </MDTypography>
                          <MDBox mt={0.5} display="flex" alignItems="center">
                            <Switch
                              checked={localStorage.getItem(`late_${params?.id}`) == "true" ? true : late_game_points}
                              name="is_home_team"
                              onChange={(e) =>
                                handleChecked(
                                  gameData?.id,
                                  gameData?.team?.id,
                                  gameData?.away_team_score
                                )
                              }
                            />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox p={2}>
                        <MDBox
                          p={2}
                          pt={0}
                          display="flex"
                          justifyContent="center"
                        >
                          <MDButton
                            color="info"
                            size="large"
                            onClick={() => endGame()}
                            fullWidth
                          // disabled={acces == false ? true : false}
                          >
                            END Game
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
              {/* </Card> */}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

    </Box>
    //   {/* <Footer /> */}
    // </DashboardLayout>
  )
}
