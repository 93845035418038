import * as actionTypes from "./actionTypes";
import { toastData } from "../../../utils/toaster";
import { DELETE, GET, POST, PUT } from "../../../utils/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getGameList = (callback) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_GAMES_LIST_LOADER, payload: true });
    dispatch({
      type: actionTypes.SET_GAMES_LIST,
      payload: [],
    });
    try {
      const response = await GET(`/v1/admin/gameList`);
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_GAMES_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_GAMES_LIST_LOADER, payload: false });
  };
};

export const createGame = (data, navigate) => {
  if (!data.id) {
    delete data["id"];
    delete data["game_id"];
  }
  data.captains.map((i, index) => {
    data.captains[index] = { captain_id: i.id };
  });
  // data.bonus.map((i, index) => {
  //   data.bonus[index] = { bonus_prize_id: i.id };
  // });
  if (!data.is_home_team) {
    delete data["sponsor_id"];
    delete data["game_prize_id"];
  }
  return async (dispatch, getState) => {
    let response = await POST("/v2/admin/addGame", data, {});
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      localStorage.setItem('captain_index',0);
      localStorage.setItem('is_end_turn',false);
     window.location.href="/game";
    } else {
      toast.error(response.data.message);
    }
  };
};

export const deleteGame = (game_id) => {
  return async (dispatch, getState) => {
    try {
      let response = await DELETE(
        `v1/admin/deleteGame`,
        {},
        { game_id: game_id, status: "DELETED" }
      );
      if (response.data.statusCode === 200) {
        let { gameList = {} } = getState().gameReducer;
        let index = gameList.findIndex((i) => i.id === game_id);
        gameList.splice(index, 1);
        dispatch({ type: actionTypes.SET_GAMES_LIST, payload: gameList });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
};

export const getGameDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_GAMES_LIST_LOADER, payload: true });
    dispatch({
      type: actionTypes.SET_GAME_DETAILS,
      payload: {},
    });
    dispatch({ type: actionTypes.SET_GAME_SCORE_LOADER, payload: true });
    try {
      const response = await GET(`/v2/admin/gameDetails/?game_id=${id}`);
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_GAME_DETAILS,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_GAME_SCORE_LOADER, payload: false });
  };
};
export const getBonus = (id) => {
  return async (dispatch) => {
   
    try {
      const response = await GET(`/v1/admin/bonusPrizeList?team_id=${id}`);
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.BONUS_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_GAME_SCORE_LOADER, payload: false });
  };
};
export const getGamePrize = (id) => {
  return async (dispatch) => {
   
    try {
      const response = await GET(`/v1/admin/gamePrizeList?team_id=${id}`);
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.GAMEPRIZE_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    dispatch({ type: actionTypes.SET_GAME_SCORE_LOADER, payload: false });
  };
};
export const editGame = (data) => {
  data.captains.map((i, index) => {
    data.captains[index] = { captain_id: i.id };
  });

  // data.bonus.map((i, index) => {
  //   data.bonus[index] = { bonus_prize_id: i.id };
  // });
  
  if (!data.is_home_team) {
    delete data["sponsor_id"];
    delete data["game_prize_id"];
  }

  return async (dispatch) => {
    try {
      let response = await PUT("/v1/admin/editGame", data, {});
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        data.navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
};

export const getBonusPrizeList = (team_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`/v1/admin/bonusList?team_id=${team_id}`);
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_BONUS_PRIZE_LIST,
          payload: response.data.data,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
};

export const captainMitiPointUpdate = (data) => {
  return async (dispatch) => {
    try {
      const response = await POST(`/v1/admin/captainMitiPointUpdate`, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
};
