import * as actionTypes from '../actions/actionTypes'

const initialState = {
  colorData: {},
}

const colorlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COLOR: {
      return {
        ...state,
        colorData: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default colorlistReducer
