import * as actionTypes from "./actionTypes";
import { toastData } from "../../../utils/toaster";
import { GET, POST, DELETE } from "../../../utils/apiService";
import { toast } from "react-toastify";

export const getBackgroundList = (callback) => {
  callback(true);
  const request = GET(`v1/admin/background`);
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: actionTypes.GET_BACKGROUND_LIST,
          payload: response,
        });
        callback(false);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        });
        callback(false);
        toastData(err.response);
      });
};

export const deleteBackground = (background_id, status) => {
  return async (dispatch, getState) => {
    let response = await DELETE(
      `v1/admin/deleteBackground`,
      {},
      { background_id: background_id, status: status }
    );
    if (response.data.statusCode == 200) {
      let { backgroundList = {} } = getState().backgroundlistReducer;
      let index = backgroundList?.data?.data?.findIndex(
        (i) => i.id === background_id
      );
      backgroundList?.data?.data?.splice(index, 1);
      dispatch({
        type: actionTypes.GET_BACKGROUND_LIST,
        payload: backgroundList,
      });
      toast.success(response.data.message);
    } else {
    }
  };
};
