import { combineReducers } from "redux";
import galleryReducer from "./gallery.reducers";
import loginReducer from "./login.reducer";
import mediaUploadReducer from "./media.reducers";
import backgroundUploadReducer from "./backgound.reducers";
import backgroundlistReducer from "./backgroundlist.reducer";
import uniformlistReducer from "./uniformlist.reducer";
import sportListReducer from "./sport.reducers";
import colorlistReducer from "./color.reducers";
import gameReducer from "./game.reducer";
import teamReducer from "./team.reducer";
import userlistReducer from "./userlist.reducer";
import webSocketReducer from "./websocket.reducers";

const rootReducer = combineReducers({
  galleryReducer,
  loginReducer,
  mediaUploadReducer,
  backgroundUploadReducer,
  backgroundlistReducer,
  uniformlistReducer,
  sportListReducer,
  colorlistReducer,
  gameReducer,
  teamReducer,
  userlistReducer,
  webSocketReducer,
});

export default rootReducer;
