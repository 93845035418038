import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userList: [],
  teamadminList:[],
  userMitiGameList:[],
  userMitiGameLoader:false,
  teamadminLoader: false,
  teamadminList: [],
  userDetails: {},
  userListLoader: false,
  userDetailsLoader: false,
  // userDelete:{},
};

const userlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_LIST: {
      return {
        ...state,
        userList: action.payload,
      };
    }
    case actionTypes.VIEW_USER: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case actionTypes.SET_USER_DETAILS_LOADER: {
      return {
        ...state,
        userDetailsLoader: action.payload,
      };
    }
    case actionTypes.SET_USER_LIST_LOADER: {
      return {
        ...state,
        userListLoader: action.payload,
      };
    }

    
    case actionTypes.SET_TEAM_ADMIN_LIST: {
      return {
        ...state,
        teamadminList: action.payload,
      }
    }
    case actionTypes.SET_TEAM_ADMIN_LIST_LOADER: {
      return {
        ...state,
        teamadminLoader: action.payload,
      };
    }

    case actionTypes.SET_USER_MITI_GAME_LIST: {
      return {
        ...state,
        userMitiGameList: action.payload,
      };
    }
    case actionTypes.SET_USER_MITI_GAME_LIST_LOADER: {
      return {
        ...state,
        userMitiGameLoader: action.payload,
      };
    }
    
    default: {
      return state;
    }
  }
};

export default userlistReducer;
