import * as actionTypes from './actionTypes'
import { POST } from '../../../utils/apiService'
import { toastData } from '../../../utils/toaster'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const mediaUpload = (url, callback) => {
  return (dispatch) => {
    POST(
      '/v1/admin/addUniform',
      {
        url: url,
      },
      {}
    )
      .then((response) => {
        dispatch({
          type: actionTypes.UPLOAD_MEDIA,
          payload: response.data,
        })
        toast.success('Image Uploaded Successfully')
        callback()
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ERRORS,
          payload: err,
        })
        toastData(err.response)
      })
  }
}
