import React from "react";
import "./index.css";

export default function ImgSpinner(params) {
  return (
    <>
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </>
  );
}
