import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import MDAddIcon from "components/MDAddIcon/Index";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCaptains } from "App/store/actions";
import { deleteCaptain } from "App/store/actions";
import DeleteModal from "components/DeleteModal";
import MDSpinner from "components/MDSpinner";
import NoData from "components/NoData";
import { toast } from 'react-toastify'
function Captain() {
  const dispatch = useDispatch();
  const params = useParams();

  const [delete_id, setDeleteId] = useState("");

  const { captains, captainLoader } = useSelector((state) => state.teamReducer);

  useEffect(() => {
    dispatch(getCaptains(params?.team_id));
  }, []);

  const removeCaptain = () => {
    dispatch(deleteCaptain(delete_id));
    setDeleteId("");
  };

  const columns = [
    {
      Header: " First name",
      accessor: "Name",
      width: "20%",
    },

    {
      Header: "Last name",
      accessor: "LastName",
      width: "20%",
    },
    {
      Header: "Jersey Number",
      accessor: "JerseyNumber",
      width: "20%",
    },
    { Header: "Default", accessor: "Default", width: "20%" },
    { Header: "Action", accessor: "action", width: "20%" },
  ];
  const rows = captains.map((i) => {
    return {
      
      Name: i?.first_name || "--",
      LastName:i?.last_name || "--",
      JerseyNumber: i?.jersey_number,
      Default: i?.is_Default ? "Yes" : "No",
      action: (
        <div>
          <Link to={`/view-team/${params?.team_id}/edit/${i.id}`} state={i}>
            <Icon fontSize="small">edit</Icon>
          </Link>
          {i?.team?.is_game_start == false  ?(
          <Link to="#" onClick={() => setDeleteId(i.id)}>
            <Icon fontSize="small">delete</Icon>
          </Link>  ) : <Icon
        style={{ cursor: "Pointer" }}
        fontSize="small"
        onClick={() =>
          toast.error(`You can't delete captain that has game in progress`)
        
        }
      > delete
      </Icon>}
          
        </div>
      ),
    };
  });

  if (captainLoader) {
    return <MDSpinner />;
  }

  return (
    <>
      <MDBox pt={1} pb={3}>
        <MDTypography mb={3} variant="h6">
          Captains
        </MDTypography>
        {delete_id && (
          <DeleteModal
            text="Are you sure you want to delete this Captain"
            open={!!delete_id}
            handleClose={() => setDeleteId("")}
            handleAction={() => removeCaptain()}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox p={2}>
              {captains.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rows,
                  }}
                />
              ) : (
                <NoData />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon
            link={`/view-team/${params?.team_id}/add-captain`}
            color="inherit"
            font="sm"
          />
        </Grid>
      </MDBox>
    </>
  );
}

export default Captain;
