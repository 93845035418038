import * as actionTypes from './actionTypes'
import { toastData } from '../../../utils/toaster'
import { DELETE, GET, POST, PUT } from '../../../utils/apiService'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getUserTeamAdminList } from './users.actions'

export const getTeamList = (callback) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_TEAM_LIST_LOADER,
      payload: true,
    })
    dispatch({
      type: actionTypes.SET_TEAM_LIST,
      payload: [],
    })
    try {
      const response = await GET('/v1/admin/teamList')
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_TEAM_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
      dispatch({
        type: actionTypes.SET_TEAM_LIST_LOADER,
        payload: false,
      })
    } catch (error) {
      toast.error(error)
      dispatch({
        type: actionTypes.SET_TEAM_LIST_LOADER,
        payload: false,
      })
    }
  }
}

export const getCaptains = (team_id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CAPTAINS_LIST,
      payload: [],
    })
    dispatch({ type: actionTypes.SET_CAPTAIN_LIST_LOADER, payload: true })
    try {
      const response = await GET(`/v1/admin/captainList?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_CAPTAINS_LIST,
          payload: response.data.data,
        })
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_CAPTAIN_LIST_LOADER, payload: false })
  }
}

export const getSponsorList = (team_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_SPONSOR_LIST_LOADER, payload: true })
    dispatch({
      type: actionTypes.SET_SPONSOR_LIST,
      payload: [],
    })
    try {
      const response = await GET(`/v1/admin/sponsorsList?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_SPONSOR_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_SPONSOR_LIST_LOADER, payload: false })
  }
}

export const getBonusList = (team_id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_BONUS_LIST,
      payload: [],
    })
    try {
      const response = await GET(`/v1/admin/bonusList?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_BONUS_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const addSponsor = (data) => {
  delete data['sponsor_id']
  return async (dispatch) => {
    try {
      const response = await POST(`/v1/admin/addSponsors`, data)
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const editSponsor = (data) => {
  return async (dispatch) => {
    try {
      let response = await PUT('/v1/admin/editSopnsors', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deleteSponsor = (id) => {
  return async (dispatch, getState) => {
    try {
      let response = await DELETE(
        `v1/admin/deleteSponsors`,
        {},
        { sponsor_id: id, status: 'DELETED' }
      )
      if (response.data.statusCode === 200) {
        let { sponsorList = {} } = getState().teamReducer
        let index = sponsorList.findIndex((i) => i.id === id)
        sponsorList.splice(index, 1)
        dispatch({ type: actionTypes.SET_SPONSOR_LIST, payload: sponsorList })
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const getPrizeList = (team_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_GAME_PRIZE_LIST_LOADER, payload: true })
    dispatch({
      type: actionTypes.SET_PRIZE_LIST,
      payload: [],
    })
    try {
      const response = await GET(`/v1/admin/prizeList?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_PRIZE_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_GAME_PRIZE_LIST_LOADER, payload: false })
  }
}

export const createPrize = (data) => {
  delete data['prize_id']
  return async () => {
    try {
      const response = await POST(`/v1/admin/addPrize`, data)
      if (response.data.statusCode === 200) {
        data.navigate(-1)
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const editPrize = (data) => {
  if (data.start_date == '0000-00-00') {
    delete data.start_date
  }
  if (data.end_date == '0000-00-00') {
    delete data.end_date
  }
  return async () => {
    try {
      let response = await PUT('/v1/admin/editPrize', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deletePrize = (prize_id) => {
  return async (dispatch, getState) => {
    try {
      let response = await DELETE(
        `v1/admin/deletePrize`,
        {},
        { prize_id: prize_id, status: 'DELETED' }
      )
      if (response.data.statusCode === 200) {
        let { prizeList = {} } = getState().teamReducer
        let index = prizeList.findIndex((i) => i.id === prize_id)
        prizeList.splice(index, 1)
        dispatch({ type: actionTypes.SET_PRIZE_LIST, payload: prizeList })
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const createTeam = (data, navigate) => {
  delete data['team_id']
  return async (dispatch, getState) => {
    try {
      const response = await POST(`/v1/admin/createTeam`, data)
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        navigate('/teams')
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deleteTeam = (team_id,user_id) => {
  return async (dispatch, getState) => {
    try {
      let response = await DELETE(
        `v1/admin/deleteTeam`,
        {},
        { team_id: team_id,user_id:user_id, status: 'DELETED' }
      )
      if (response.data.statusCode === 200) {
        let { teamList = {} } = getState().teamReducer
        let index = teamList.findIndex((i) => i.id === team_id)
        teamList.splice(index, 1)
        dispatch({ type: actionTypes.SET_TEAM_LIST, payload: teamList })
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}


export const addNewPlayers = (data) => {

  return async(dispatch) => {
    try {
      let response = await POST('/v1/admin/selectPlayer', data, {})
      if (response.data.statusCode == 200) {
        dispatch({
          type: actionTypes.SET_NEW_PLAYER,
          payload: response.data.data.allUser,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error){
      toast.error(error);
    }
  }
}

export const getNewPlayers = (game_id) => {
  return async(dispatch) => {
    try {
      let response = await GET(`/v1/admin/selectPlayer/?${game_id}`)
    
      // if (response.data.statusCode == 200) {
      //   dispatch({
      //     type: actionTypes.SET_NEW_PLAYER,
      //     payload: response.data.data,
      //   })
      // } else {
      //   toast.error(response.data.message)
      // }
    } catch (error){
      toast.error(error);
    }
  }
}




export const getPlayerDetail = (id, game_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_TEAM_DETAILS_LOADER, payload: true })
    dispatch({
      type: actionTypes.SET_TEAM_DETAILS,
      payload: {},
    })
    try {
      let response = await GET(`/v1/admin/teamDetails/?team_id=${id}/?game_id=${game_id}`)
      // if(game_id){
      //   let response = await GET(`/v1/admin/teamDetails/?team_id=${id}/?game_id=${game_id}`)
      // }else{
      //   let response = await GET(`/v1/admin/teamDetails/?team_id=${id}`)
      // }
    
      if (response.data.statusCode == 200) {
        dispatch({
          type: actionTypes.SET_TEAM_DETAILS,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_TEAM_DETAILS_LOADER, payload: false })
  }
}

export const getTeamDetail = (id, game_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_TEAM_DETAILS_LOADER, payload: true })
    dispatch({
      type: actionTypes.SET_TEAM_DETAILS,
      payload: {},
    })
    try {
      let response = await GET(`/v1/admin/teamDetails/?team_id=${id}`)
      // if(game_id){
      //   let response = await GET(`/v1/admin/teamDetails/?team_id=${id}/?game_id=${game_id}`)
      // }else{
      //   let response = await GET(`/v1/admin/teamDetails/?team_id=${id}`)
      // }
    
      if (response.data.statusCode == 200) {
        dispatch({
          type: actionTypes.SET_TEAM_DETAILS,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_TEAM_DETAILS_LOADER, payload: false })
  }
}

export const editTeam = (data, navigate) => {
  return async (dispatch) => {
    try {
      let response = await PUT('/v1/admin/editTeam', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
       toast.error(error)
    }
  }
}

export const getPrizeType = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_PRIZE_TYPES_DATA,
      payload: [],
    })
    try {
      const response = await GET(`/v1/admin/prizeTypes`)
      if (response.data.statusCode == 200) {
        dispatch({
          type: actionTypes.SET_PRIZE_TYPES_DATA,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const getCustomizeDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_CUSTOMIZE_DETAILS_LOADER, payload: true })
    dispatch({
      type: actionTypes.SET_CUSTOMIZE_DETAILS,
      payload: {},
    })
    try {
      const response = await GET(`/v1/admin/customizeDetails?team_id=${id}`)
      if (response.data.statusCode == 200) {
        dispatch({
          type: actionTypes.SET_CUSTOMIZE_DETAILS,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({
      type: actionTypes.SET_CUSTOMIZE_DETAILS_LOADER,
      payload: false,
    })
  }
}

export const addCustomize = (data) => {
  delete data['customize_id']
  return async (dispatch) => {
    try {
      const response = await POST(`/v1/admin/addCustomize`, data)
      if (response.data.statusCode == 200) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const editCustomize = (data) => {
  return async (dispatch) => {
    try {
      const response = await PUT(`/v1/admin/editCustomize`, data)
      if (response.data.statusCode == 200) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}






export const addCaptain = (data) => {
  delete data['captain_id']
  return async (dispatch) => {
    try {
      let response = await POST('/v1/admin/addCaptains', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const editCaptain = (data) => {
  // delete data["captain_id "]
  return async (dispatch) => {
    try {
      let response = await PUT('/v1/admin/editCaptains', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deleteCaptain = (id) => {
  return async (dispatch, getState) => {
    try {
      let response = await DELETE(
        `v1/admin/deleteCaptains`,
        {},
        { captain_id: id, status: 'DELETED' }
      )
      if (response.data.statusCode === 200) {
        let { captains = {} } = getState().teamReducer
        let index = captains.findIndex((i) => i.id === id)
        captains.splice(index, 1)
        dispatch({ type: actionTypes.SET_CAPTAINS_LIST, payload: captains })
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export default function createTeamAdmin(data, callback) {
  return async (dispatch) => {
    try {
      let response = await POST('/v1/admin/createTeamAdmin', data)
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        callback()
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export function emptyData(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SET_SPONSOR_LIST, payload: [] })
      dispatch({ type: actionTypes.SET_PRIZE_LIST, payload: [] })
      dispatch({ type: actionTypes.SET_CAPTAINS_LIST, payload: [] })
      dispatch({ type: actionTypes.SET_BONUS_PRIZE_LIST, payload: [] })
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deleteTeamAdmins= (id) => {
  return async (dispatch, getState) => {
    let response = await DELETE(
      `v1/admin/deleteTeamAdmin`,
      {},
      { teamAdmin_id: id, status: 'DELETED' },    
    )
    if (response.data.statusCode === 200) {
      dispatch({ type: actionTypes.SET_TEAM_ADMIN_DELETE, payload: response })
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }
}

export const getVisitorLogoList = (team_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_VISITOR_LIST_LOADER ,payload: true })
    dispatch({
      type: actionTypes.GET_VISITOR_LIST,
      payload: [],
    })
    try {
      const response = await GET(`/v2/admin/getVisitorlist?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.GET_VISITOR_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_VISITOR_LIST_LOADER, payload: false })
  }
}

export const addVisitor= (data) => {
  delete data['visitor_id']
  return async (dispatch) => {
    try {
      const response = await POST(`/v2/admin/addVisitor`, data)
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const editVisitor = (data) => {
  return async (dispatch) => {
    try {
      let response = await PUT('/v2/admin/updateVisitor', data, {})
      if (response.data.statusCode === 200) {
        toast.success(response.data.message)
        data.navigate(-1)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}

export const deleteVisitor= (id) => {
  return async (dispatch, getState) => {
    try {
      let response = await PUT(
        `v2/admin/deleteVisitor?visitor_id=${id}        `,
        { visitor_id: id },{}
      )
      if (response.data.statusCode === 200) {
        let { visitorList = {} } = getState().teamReducer
        let index = visitorList.findIndex((i) => i.id === id)
        visitorList.splice(index, 1)
        dispatch({ type: actionTypes.GET_VISITOR_LIST, payload: visitorList })
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
  }
}
export const Visit = (team_id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_VISITOR_LIST_LOADER ,payload: true })
    dispatch({
      type: actionTypes.VISITOR_LIST,
      payload: [],
    })
    try {
      const response = await GET(`/v2/admin/getVisitorlist?team_id=${team_id}`)
      if (response.data.statusCode === 200) {
        dispatch({
          type: actionTypes.VISITOR_LIST,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error)
    }
    dispatch({ type: actionTypes.SET_VISITOR_LIST_LOADER, payload: false })
  }
}