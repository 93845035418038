import * as actionTypes from '../actions/actionTypes';

const initialState = {
    getGalleryData: {}
};


const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_GALLERY_DATA:
            {
                return {
                    ...state,
                    getGalleryData: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}


export default galleryReducer;